<div #cardContainer class="cards-container">
  <div *ngFor="let item of items;let i = index" class="home-card" [ngClass]="{'home-card-long':type == 'description','home-card-high':type == 'image'}" tabindex="1" (click)="itemClicked(i)" (keydown)="onKeydown($event)">
    <div *ngIf="type == 'card'">
      <img src={{item.imageSource}} height="130px" width="130px" />
      <p>{{item.text | translate}}</p>
    </div>
    <div *ngIf="type == 'description'">
      <p>{{item.name | formatTitle}}</p>
      <p class="item-count">{{"details.total-data" | translate}}: {{item.items.length}}</p>
    </div>
    <div *ngIf="type == 'image'" class="image-card">
      <img *ngIf="item.movie_image" src={{item.movie_image}} height="130px" width="130px" />
      <img *ngIf="item.cover" src={{item.cover}} height="130px" width="130px" />
      <p >{{item.name | formatTitle | removeYear}}</p>
    </div>
  </div>
</div>