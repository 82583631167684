<div class="background">
  <div class="licence-container">
    <div class="licence-form">
      <h1>{{ "login.licenceWorks" | translate }}</h1>
      <div class="input-container">
        <input #licenceField type="text" [(ngModel)]="licence" (keydown)="onInputKeydown($event)"/>
        <button #submitButton class="submit-button" (click)="submit()" mat-button (keydown)="onButtonKeydown($event)">
          {{ "login.submit" | translate }}
        </button>
      </div>
      <div class="device-info">
        <span class="device-id">{{ "login.deviceId" | translate }}: {{deviceInfo.mac}}</span>
        <span class="device-name">{{ "login.deviceName" | translate }}: {{deviceInfo.modelName}}</span>
      </div>
      <p class="error">{{ err }}</p>
    </div>
  </div>
</div>