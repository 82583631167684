import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'base64decode' })
export class Base64DecodePipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (typeof value == 'string') {
      return decodeURIComponent(escape(window.atob(value)));
    }
    return '';
  }
}

export abstract class Base64Decoder {
  public static decodeBase64(str: any) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }
}
