<div [class]="selectedTheme">
  <div class="row background" [ngClass]="{ 'channels-background': this.currentType === 0 }">
    <div class="col-md-5">
      <div class="channel-list-header">
        <button #channelsListButtonBack type="button" class="btn btn-secondary buttons-headers" (click)="goBack()"
          (keydown)="onKeydownHeaderButtons($event)">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
          </svg>
        </button>
        <label *ngIf="!searchBoxVisible" class="channel-list-header-title">{{
          currentTitle
          }}</label>
        <input *ngIf="searchBoxVisible" #channelsListSearchInput placeholder="Pretraga" [value]="searchValue"
          (input)="searchValueChanged($event)" (keydown)="onKeydownHeaderSearchInput($event)"
          class="channel-list-header-title" />
        <button #channelsListButtonSearch type="button" class="btn btn-secondary buttons-headers search-button"
          (click)="toggleSearchBox()" (keydown)="onKeydownHeaderButtons($event)">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-search"
            viewBox="0 0 16 16">
            <path
              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </button>
        <button #channelsListButtonSort type="button" class="btn btn-secondary buttons-headers sort-button"
          (click)="toggleSortDialog()" (keydown)="onKeydownHeaderButtons($event)">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-sort-down"
            viewBox="0 0 16 16">
            <path
              d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
          </svg>
        </button>
      </div>
      <div class="channel-list-box">
        <div #channelsListPlaying>
          <div *ngFor="let item of watchItems; index as i" (click)="channelClicked(item)"
            (keydown)="onKeydown($event, item)">
            <div *ngIf="item.id == -1" class="channel-list-playing-card" [tabIndex]="1" (focus)="onFocus(item)">
              <span class="channel-title">
                {{ item.name | translate }}
              </span>
            </div>
            <div *ngIf="item.id > -1" class="channel-list-playing-card" [tabIndex]="1" (focus)="onFocus(item)">
              <span class="index"> {{ i + allItemsPointer * 30 + 1 }}</span>
              <span class="channel-title">{{ item.name | formatTitle }}</span>
              <img *ngIf="selectedStream.id === item.id" class="play-icon" src="assets/images/player_icons/play.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div>
        <div class="row video-position" [class]="screenClass">
          <app-player [url]="selectedStream?.url" s [type]="currentType" (onVideoChanged)="changeChannel($event)"
            style="height: 0px; width: 0px">
          </app-player>
          <img src="assets/images/radio.svg" style="width: 60%; margin: auto;" />
          <div #controlButtons style="width: fit-content; margin: auto;">
            <button mat-button class="control-button" (keydown)="onControlKeydown($event, 0)"><img
                src="assets/images/player_icons/backward.png" /></button>
            <button mat-button class="control-button" (keydown)="onControlKeydown($event, 1)">
              <img *ngIf="playing" src="assets/images/player_icons/pause.png" />
              <img *ngIf="!playing" src="assets/images/player_icons/play.png" />
            </button>
            <button mat-button class="control-button" (keydown)="onControlKeydown($event, 2)"><img
                src="assets/images/player_icons/forward.png" /></button>
            <button mat-button class="control-button" (keydown)="onControlKeydown($event, 3)">
              <svg *ngIf="!muted" xmlns="http://www.w3.org/2000/svg" height="1.5rem" width="1.5rem" viewBox="0 0 576 512" style="filter: invert(1);">
                <g color="white">
                  <path
                    d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z" />
                </g>
              </svg>
              <svg *ngIf="muted" xmlns="http://www.w3.org/2000/svg" height="1.5rem" width="1.5rem" viewBox="0 0 640 512" style="filter: invert(1);">
                <path
                  d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z" />
              </svg>
            </button>
            <button mat-button class="control-button" (keydown)="onControlKeydown($event, 4)"><img style="height: 2rem;"
                src="assets/images/tv_icon.png" /></button>
          </div>
          <div #blackScreenElement [tabIndex]="1" [ngClass]="{ 'black-screen': this.blackScreen }" (keydown)="exitBlackScreen()"></div>
        </div>
      </div>
    </div>
  </div>
</div>