<div [class]="selectedTheme">
  <div class="background">
    <div class="content">
      <div class="card">
        <h2>{{ "settings.user-info" | translate }}</h2>

        <p>
          <span class="left-align">{{ "settings.username" | translate }}:</span>
          <span class="right-align">{{ userInfo.username }}</span>
        </p>
        <p>
          <span class="left-align"
            >{{ "settings.account-status" | translate }}:</span
          >
          <span class="right-align">{{ userInfo.status }}</span>
        </p>
        <p>
          <span class="left-align"
            >{{ "settings.expiry-date" | translate }}:</span
          >
          <span class="right-align">{{
            expiryDate | date : "dd.MM.yyyy"
          }}</span>
        </p>
        <!-- <p>
          <span class="left-align"
            >{{ "settings.trial-account" | translate }}:</span
          >
          <span class="right-align">{{ trialAccount }}</span>
        </p> -->
        <p>
          <span class="left-align"
            >{{ "settings.active-connections" | translate }}:</span
          >
          <span class="right-align">{{ userInfo.active_cons }}</span>
        </p>
        <p>
          <span class="left-align"
            >{{ "settings.setup-date" | translate }}:</span
          >
          <span class="right-align">{{ setupDate | date : "dd.MM.yyyy" }}</span>
        </p>
        <p>
          <span class="left-align"
            >{{ "settings.connections-number" | translate }}:</span
          >
          <span class="right-align">{{
            userInfo.max_connection
          }}</span>
        </p>
        <p>
          <span class="left-align"
            >{{ "settings.app-version" | translate }}:</span
          >
          <span class="right-align">1.0.0</span>
        </p>

        <button #buttonBack mat-button class="button mat-button cdkFocusInitial" (click)="back()"  (keydown)="onKeydownButton($event)">
          {{ "login.back" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
