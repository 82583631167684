<div [class]="theme">
  <div class="row">
    <div class="col-md-3 date-container">
      <button
        #openDatesButton
        mat-button
        class="button date-header-button"
        (click)="onDateHeaderKeydown($event)"
        (keydown)="onDateHeaderKeydown($event)"
      >
        {{ selectedDate.date }}
      </button>
      <div *ngIf="showDates" #shownDates>
        <button
          *ngFor="let date of dates"
          #buttonFern
          mat-button
          class="button"
          (click)="onDateKeydown($event, date)"
          (keydown)="onDateKeydown($event, date)"
        >
          {{ date.date }}
        </button>        
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div #streamList class="col-md-12 right-div card" *ngFor="let stream of selectedDate.streams; index as i"
        (click)="selectEpgItem(stream)" [tabIndex]="1" (keydown)="onKeydown($event, i)">
        <div>
          {{ stream.start | formatDate }}
        </div>
        <div>
          {{ stream.title }}
        </div>
      </div>
    </div>

    <div class="col-md-9">
      <div [ngClass]="{'player-hidden':!isPlayerHidden}" class="show-info">
        <div class="show-title">
          {{ selectedEpgItem.title }}
        </div>
        <div class="show-details">
          {{ selectedEpgItem.description }}
        </div>
      </div>
      <div [ngClass]="{'player-hidden':isPlayerHidden}" class="row video-position">
        <app-player [url]="streamUrl" [type]="3"
          (fullscreenChanged)="closeFullSreen()"
        ></app-player>
      </div>
    </div>
  </div>
</div>