<router-outlet></router-outlet>
  <!-- <video src="http://nightfight.xyz:8080/infinitydevs/infinitydevs1212/7"  #videoPlayer autoplay>
  </video> -->

  <!-- <video
  #videoPlayer
  controls
  autoplay
  type="video/mp4"
  src="http://nightfight.xyz:8080/infinitydevs/infinitydevs1212/7"
></video> -->
<div *ngIf="!isOnline" class="offline-message">
    You are offline
</div>