<div class="conteiner">
  <div class="row">
    <div class="col-lg-8 background">
      <div class="center">
        <img
          src="assets/images/onboarding/language_icon.png"
          class="theme-icon"
        />
        <label class="title">{{ "language.choose-language-desc" | translate }}</label>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="buttons">
        <label class="buttons-title">{{ "language.choose-language" | translate }}</label>
        <button #buttonEn mat-button (click)="confirm('en')" (keydown)="onKeydownButtonEn($event, 'en')" (focus)="languagePreview('en')">
          {{'language.english' | translate}}
        </button>
        <button #buttonHr mat-button (click)="confirm('hr')" (keydown)="onKeydownButtonHr($event, 'hr')" (focus)="languagePreview('hr')">
          {{'language.croatian' | translate}}
        </button>
        <button #buttonDe mat-button (click)="confirm('de')" (keydown)="onKeydownButtonDe($event, 'de')" (focus)="languagePreview('de')">
          {{'language.german' | translate}}
        </button>
      </div>
  </div>
</div>
