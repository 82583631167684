import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Episode, TvShowInfo } from '../../../models/serieinfo.model';
import { StreamOptionType } from '../../../models/category.model';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { PlayerComponent } from '../../player/player.component';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-stb-extreme-tv-show-episodes-list',
  templateUrl: './stb-extreme-tv-show-episodes-list.component.html',
  styleUrls: ['./stb-extreme-tv-show-episodes-list.component.css'],
})
export class StbExtremeTvShowEpisodesListComponent implements OnInit {
  public currentTitle = '';
  public epgInfo = new TvShowInfo();
  public tvShowId: number;
  public selectedTheme = 'stb-extreme';
  public selectedStreamIcon = '';
  public episodes = new Array<any>();
  public season: number;
  public currentType: StreamOptionType = StreamOptionType.TVShows;

  public streamUrl: string;
  public isPlayerHidden = true;
  selectedIndex: number = 0;
  @ViewChildren('channelsListPlaying') channelsListPlaying: QueryList<any>;
  @ViewChild(PlayerComponent) player: PlayerComponent;

  constructor(
    private route: ActivatedRoute,
    private evelinService: EvelinService,
    private platformService: BasePlatformService,
    public dialog: MatDialog,
    private location: Location
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params: Params) => {
      if (params['streamId'])
        this.tvShowId = parseInt(params['streamId']);

      if (params['season'])
        this.season = parseInt(params['season']);
    });

    this.currentTitle = 'Sezone';
    this.epgInfo = await this.evelinService.getTvShowEpisodes(this.tvShowId);
    console.log(this.epgInfo);
    this.episodes = this.epgInfo.seasons[this.season];
    console.log(this.episodes);
    this.selectedStreamIcon = (this.epgInfo as any).cover as string;
    setTimeout(() => this.focusChannel(0), 100);
  }

  onKeydownHeaderButtons(e: any) { }

  focusChannel(index: number): void {
    this.channelsListPlaying
      .toArray()[0]
      .nativeElement.children[index].children[0].focus();
  }

  onKeydown(e: any, channel: any, index: number) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        index--;
        this.focusChannel(index);
        break;
      case 'Down':
        index++;
        this.focusChannel(index);
        break;
      case 'Enter':
        this.isPlayerHidden = false;
        this.itemClicked(index++);
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  itemClicked(index: any) {
    this.selectedIndex = index;
    this.streamUrl = this.episodes[index].url;
    this.player.setFullScreen(this.epgInfo?.info);
  }

  onFocus(season: any) { }

  goBack() {
    this.location.back();
  }

  closeFullSreen() {
    this.isPlayerHidden = true;
    setTimeout(() => this.focusChannel(this.selectedIndex));
  }

  nextEpisode() {
    if (this.selectedIndex === this.episodes.length - 1)
      return;

    this.itemClicked(this.selectedIndex + 1);
  }
}
