<div #info class="info" tabindex="1">
  <div class="greetings">
    <div>
      <span>{{ greetingMessage | translate }}</span>
      <span>{{ currentProfile?.profile_name }}</span>
    </div>
    <img src="{{'assets/images/profile_avatar/ic_avatar_' + currentProfile.avatar + '.png'}}">
  </div>
  <div *ngIf="showInfoText">
    <div *ngIf="notifications.length && !showInfoText">
      {{ "notifications" | translate }}: {{notifications.length}}
    </div>
    <button *ngIf="notifications.length && showInfoText">
      {{ "open-notifications" | translate }}: {{notifications.length}}
    </button>
    <p>
      {{ "settings.connections-number" | translate }}:
      {{ userInfo.max_connection }}
    </p>
    <p>
      {{ "settings.expiry-date" | translate }}:
      {{ expiryDate | date : "dd.MM.yyyy" }}
    </p>
    <p>
      IP: {{ ipInfo.ip }} {{ ipInfo.org }}
    </p>
    <p>
      {{ "settings.list" | translate }}:
      {{ userInfo?.username }}
    </p>
    <p>
      {{ "settings.profile" | translate }}:
      {{ currentProfile?.profile_name }}
    </p>
    <p>{{ "device-id" | translate }}: {{ deviceInfo?.mac }}</p>
    <p>{{ "device-name" | translate }}: {{ deviceInfo?.modelName }}</p>
  </div>
</div>