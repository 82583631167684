export class BasePlatformService {
  keymapper($event: any): string {
    const _code = $event.keyCode;
    const _fallback = $event.key;
    const _finalCode = _code || _fallback;
    const _foundKey = this.keyName(_finalCode, _fallback);
    return _foundKey;
  }

  getDeviceInfo(): any {
    throw new Error('Device has no service defined');
  }

  keyName(keyCode: any, keyFallback: string): string {
    if (this.keyMap[keyCode]) {
      return this.keyMap[keyCode];
    }
    return keyCode;
  };


  public keyMap = {
    "8": "BrowserBack",
    "13": "Enter",
    "19": "Pause",
    "20": "Green",
    "21": "Yellow",
    "22": "Blue",
    "37": "Left",
    "38": "Up",
    "39": "Right",
    "40": "Down",
    "48": "0",
    "49": "1",
    "50": "2",
    "51": "3",
    "52": "4",
    "53": "5",
    "54": "6",
    "55": "7",
    "56": "8",
    "57": "9",
    "76": "Power",
    "108": "Red",
    "197": "Web",
    "403": "Red",
    "404": "Green",
    "405": "Menu",
    "406": "Blue",
    "413": "Stop",
    "412": "Rewind",
    "417": "FastForward",
    "416": "Record",
    "427": "ChannelPlus",
    "428": "ChannelMinus",
    "457": "PopUpMenu",
    "458": "EPG",
    "10009": "BrowserBack",
    "10133": "Menu",
    "10182": "Exit",
    "10232": "Previous",
    "10233": "Next",
    "10252": "PlayPause",
    "10253": "Menu",
    "65376": "Enter",
  };
}