import { Component, } from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { UserInfoV2 } from '../../../models/login.model';
import { TranslateService } from '@ngx-translate/core';
import { HomeBaseComponent } from '../../home-base/home-base.component';
import { StreamOptionType } from '../../../models/category.model';
import { MatDialog } from '@angular/material/dialog';
import { exitApplication } from '../../../platforms/tizen/utils/utils';
import { ClientProfile } from '../../../models/profile.model';
import { IpInfo } from 'src/app/models/ipinfo.model';

@Component({
  selector: 'app-home-ocean-blue',
  templateUrl: './home-ocean-blue.component.html',
  styleUrls: ['./home-ocean-blue.component.css'],
})

export class HomeOceanBlueComponent extends HomeBaseComponent {
  selectedTheme: string = 'ocean-blue';
  currentTime: number = 0;
  ipInfo = new IpInfo();
  userInfo: UserInfoV2;
  expiryDate: Date;
  currentProfile: ClientProfile;

  cards: Card[];
  currentCardIndex: number = 0;

  constructor(
    public router: Router,
    public appInfo: AppInfoService,
    public translate: TranslateService,
    public matDialog: MatDialog
  ) {
    super(router, appInfo, translate, matDialog);
    setInterval(() => this.currentTime = Date.now(), 10000);
  }

  async ngOnInit(): Promise<void> {
    this.userInfo = this.appInfo.getUserInfoV2;
    if(this.userInfo.exp_date != null) {
      this.expiryDate = new Date(
        parseInt(this.userInfo.exp_date as string) * 1000
      );
    } else {
      this.expiryDate = new Date(2100, 1, 1, 1, 1, 1, 1);
    }
    
    this.currentProfile = this.appInfo.getCurrentProfile();
    this.ipInfo = this.appInfo.getIpAddress();
    this.initHomeData();
  }

  initHomeData() {
    this.cards = [
      {
        text: this.translate.instant('tv-channels'),
        imageSource: 'assets/images/tv_icon.png',
        routingUrl: 'e',
        type: StreamOptionType.TVChannels,
        shouldRoute: false,
        isSelected: true
      },
      {
        text: this.translate.instant('movies'),
        imageSource: 'assets/images/filmovi_icon.png',
        routingUrl: 'e',
        type: StreamOptionType.Movies,
        shouldRoute: false,
        isSelected: false
      },
      {
        text: this.translate.instant('tv-shows'),
        imageSource: 'assets/images/serije_icon.png',
        routingUrl: 'e',
        type: StreamOptionType.TVShows,
        shouldRoute: false,
        isSelected: false
      },
      {
        text: this.translate.instant('tv-archive'),
        imageSource: 'assets/images/arhiva_icon.png',
        routingUrl: 's',
        type: StreamOptionType.TVArchive,
        shouldRoute: false,
        isSelected: false
      },
      {
        text: this.translate.instant('radio'),
        imageSource: 'assets/images/radio_icon.png',
        routingUrl: 'e',
        type: StreamOptionType.Radio,
        shouldRoute: false,
        isSelected: false
      },
      {
        text: this.translate.instant('multi-epg'),
        imageSource: 'assets/images/epg_icon.png',
        routingUrl: '/multi-epg',
        type: StreamOptionType.MultiEPG,
        shouldRoute: true,
        isSelected: false
      },
      {
        text: this.translate.instant('settings.settings'),
        imageSource: 'assets/images/podesavanja_icon.png',
        routingUrl: '/settings',
        type: StreamOptionType.Settings,
        shouldRoute: true,
        isSelected: false
      },
      {
        text: this.translate.instant('select-profile'),
        imageSource: 'assets/images/profile.png',
        routingUrl: '/select-profile/false/0',
        type: StreamOptionType.ProfileSelection,
        shouldRoute: true,
        isSelected: false
      },
      {
        text: this.translate.instant('quit'),
        imageSource: 'assets/images/izlaz_icon.png',
        routingUrl: 'e',
        type: StreamOptionType.Exit,
        shouldRoute: true,
        isSelected: false
      }
    ];
  }

  navigate() {
    console.log(this.cards[this.currentCardIndex]);
    if (this.cards[this.currentCardIndex].type == StreamOptionType.TVArchive) {
      this.router.navigate(['stb-tv-archive/0']);
    } else if (this.cards[this.currentCardIndex].type == StreamOptionType.MultiEPG) {
      this.router.navigate(['/multi-epg']);
    } else if (this.cards[this.currentCardIndex].type == StreamOptionType.Settings) {
      this.router.navigate(['/settings']);
    } else if (this.cards[this.currentCardIndex].type == StreamOptionType.Radio) {
      this.router.navigate(['/radio-ocean-blue']);
    } else if (this.cards[this.currentCardIndex].type == StreamOptionType.Exit) {
      exitApplication();
    } else if (this.cards[this.currentCardIndex].type == StreamOptionType.ProfileSelection) {
      this.router.navigate(['select-profile', false, 0]);
    } else if (this.cards[this.currentCardIndex].type == StreamOptionType.TVChannels) {
      this.router.navigate(['tv-channels-ocean-blue']);
    } else if (this.cards[this.currentCardIndex].type == StreamOptionType.Movies) {
      this.router.navigate(['movies-ocean-blue']);
    } else if (this.cards[this.currentCardIndex].type == StreamOptionType.TVShows) {
      this.router.navigate(['tv-shows-ocean-blue']);
    }
  }

  onCardClick(card) {
    this.currentCardIndex = card;
    this.navigate();
  }

}

export class Card {
  text: string;
  imageSource: string;
  routingUrl: string;
  type: StreamOptionType;
  shouldRoute: boolean;
  isSelected: boolean;
}