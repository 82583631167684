<div #categoryList class="category-list">
  <div class="my-overlay" *ngIf="!resourcesLoaded">
    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
  <div [tabIndex]="1" class="list-item" (keydown)="onKeydown($event, i, category)" *ngFor="let category of minorCategories; index as i">
    <mat-checkbox
    color="primary"
      mat-list-icon
      [checked]="selection.isSelected(category)"
      (change)="selectionChanged(category)"
    >
      {{ category.bouquet_name }}
    </mat-checkbox>
  </div>
</div>
