<div [class]="selectedTheme">
  <div class="row background">
    <div class="col-md-5">
      <div class="channel-list-header">
        <button #channelsListButtonBack type="button" class="btn btn-secondary buttons-headers" (click)="goBack()"
          (keydown)="onKeydownHeaderButtons($event)">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
          </svg>
        </button>
        <label class="channel-list-header-title">{{ currentTitle }}</label>
      </div>
      <div class="channel-list-box">
        <div #channelsListPlaying>
          <div *ngFor="let item of episodes; index as i" (click)="itemClicked(i)"
            (keydown)="onKeydown($event, item, i)">
            <div class="channel-list-playing-card" [tabIndex]="1" (focus)="onFocus(item)">
              <span class="index"> {{ i + 1 }}</span>
              <span class="channel-title"> {{ item.name | formatTitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <!-- TV SHOW -->
      <div [ngClass]="{'player-hidden':isPlayerHidden}" class="row video-position">
        <app-player [url]="streamUrl" [type]="currentType"
          (fullscreenChanged)="closeFullSreen()" (onVideoChanged)="nextEpisode()"></app-player>
      </div>
      <app-stb-details class="row" *ngIf="epgInfo.info && isPlayerHidden" [details]="epgInfo.info"></app-stb-details>
    </div>
  </div>
</div>