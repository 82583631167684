import { Observable, of, throwError } from "rxjs";
import { LogEntry } from "../models/log-entry.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";


export abstract class LogPublisher {
  location: string;
  abstract log(record: LogEntry):
    Observable<any>
  abstract clear(): Observable<boolean>;
}

export class LogConsole extends LogPublisher {
  log(entry: LogEntry): Observable<boolean> {
    console.log(entry.buildLogString());
    return of(true);
  }

  clear(): Observable<boolean> {
    console.clear();
    return of(true);
  }
}

export class LogLocalStorage extends LogPublisher {
  constructor() {
    super();
    this.location = "logging";
  }

  log(entry: LogEntry): Observable<boolean> {
    let ret: boolean = false;
    let values: LogEntry[];

    try {
      values = JSON.parse(localStorage.getItem(this.location)) || [];
      values.push(entry);
      localStorage.setItem(this.location, JSON.stringify(values));
      ret = true;
    } catch (ex) {
      console.log(ex);
    }

    return of(ret);
  }

  clear(): Observable<boolean> {
    localStorage.removeItem(this.location);
    return of(true);
  }
}

export class LogWebApi extends LogPublisher {
  constructor(private http: HttpClient) {
    super();
    this.location = "/api/log";
  }

  log(entry: LogEntry): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Accept', 'application/json');

    return this.http.post(this.location, entry, {
      headers: headers
    }).pipe(catchError(this.handleErrors));
  }

  clear(): Observable<boolean> {
    return of(true);
  }

  private handleErrors(error: any): Observable<any> {
    let errors: string[] = [];
    let msg: string = "";

    msg = "Status: " + error.status;
    msg += " - Status Text: " + error.statusText;
    if (error) {
      msg += " - Exception Message: " + error.exceptionMessage;
    }
    errors.push(msg);

    console.error('An error occurred', errors);
    return throwError(errors);
  }
}

export class LogPublisherConfig {
  loggerName: string;
  loggerLocation: string;
  isActive: boolean;
}