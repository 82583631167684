import {
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioButton } from '@angular/material/radio';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';

import { AppInfoService } from 'src/app/services/app-info.service';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sort-dialog',
  templateUrl: './sort-dialog.component.html',
  styleUrls: ['./sort-dialog.component.css'],
})
export class SortDialogComponent {
  @ViewChild('sortDialogButtonOk') sortDialogButtonOk: ElementRef;
  @ViewChild('sortDialogFirstRadioButton')
  sortDialogFirstRadioButton: MatRadioButton;

  constructor(
    private platformService: BasePlatformService,
    private appInfoService: AppInfoService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<SortDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.inputValue = data.sortingOption;
    this.selectedValue = this.inputValue;
  }

  selectedValue: string;
  inputValue = 'default';
  selectedTheme: string;

  ngOnInit() {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
  }

  ngAfterViewInit() {
    this.sortDialogButtonOk.nativeElement.focus();
  }

  changeSelectedValue(value: string) {
    this.selectedValue = value;
  }

  close() {
    this.dialogRef.close(this.selectedValue);
  }

  onKeydown(e: any, lastElement: boolean) {
    let key = this.platformService.keymapper(e);
    switch (key.toString()) {
      case 'Down':
        if (lastElement) {
          this.sortDialogButtonOk.nativeElement.focus();
          e.preventDefault();
        }
        break;
      case 'Right':
        this.sortDialogButtonOk.nativeElement.focus();
        e.preventDefault();
        break;
      case 'Enter':
      case '32':
      case 'BrowserBack':
        this.close();
        break;
      default:
        break;
    }
  }

  onKeydownButton(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.sortDialogFirstRadioButton.focus();
        break;
      case 'Enter':
        this.close();
        break;
      default:
        break;
    }
  }
}
