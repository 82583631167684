import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';

import { AppInfoService } from 'src/app/services/app-info.service';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { Stream } from '../../models/stream.model';
import { Location } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-pin-required-dialog',
  templateUrl: './pin-required-dialog.component.html',
  styleUrls: ['./pin-required-dialog.component.css'],
})
export class PinRequiredDialogComponent implements OnInit {
  @ViewChild('pinInput') inputPin: ElementRef;
  @ViewChild('okBtn') btnOk: MatButton;
  @ViewChild('backBtn') btnBack: MatButton;
  constructor(
    private platformService: BasePlatformService,
    private appInfoService: AppInfoService,
    public dialogRef: MatDialogRef<PinRequiredDialogComponent>
  ) {

  }

  selectedTheme: string;
  public pin: string = "";
  public showError = false;

  ngOnInit() {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
    this.showError = false;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputPin.nativeElement.focus();
    }, 1000); // zbog fokusa na tv home 
  }

  checkPin() {
    let profile = this.appInfoService.getCurrentProfile();
    if (profile.pin == this.pin) {
      this.showError = false;
      this.appInfoService.setCorrectPinTime();
      this.close(true);
    } else {
      this.showError = true;
    }
  }

  close(confirm: boolean): any {
    this.dialogRef.close(confirm);
  }

  onKeydownPin(e: any) {
    let key = this.platformService.keymapper(e);

    if (key == 'Down') {
      this.btnOk.focus();
    }
    switch (key) {
      case 'Enter':
      case 'Select':
      case 'Accept':
        e.preventDefault();
        break;
      case 'Down':
        this.btnOk.focus();
        break;
      case 'BrowserBack':
        this.close(false);
        break;
      default:
        break;
    }
  }

  onKeydownButtonOk(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Right':
        this.btnBack.focus();
        break;
      case 'Enter':
        this.checkPin();
        break;
      case 'Up':
        this.inputPin.nativeElement.focus();
        break;
      case 'BrowserBack':
        this.close(false);
        break;
      default:
        break;
    }
  }

  onKeydownButtonClose(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Left':
        this.btnOk.focus();
        break;
      case 'Enter':
        this.close(false);
        break;
      case 'BrowserBack':
        this.close(false);
        break;
      case 'Up':
        this.inputPin.nativeElement.focus();
        break;
      default:
        break;
    }
  }
}
