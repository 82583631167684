import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CompleteEpgListing, ShortEpg, ShortEpgListing } from '../../../models/epg.model';
import { AppInfoService } from '../../../services/app-info.service';
import { StreamOptionType } from '../../../models/category.model';
import { LiveStream } from '../../../models/stream.model';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-stb-extreme-short-epg',
  templateUrl: './stb-extreme-short-epg.component.html',
  styleUrls: ['./stb-extreme-short-epg.component.css'],
})
export class StbExtremeShortEpgComponent implements OnInit, OnChanges, OnDestroy {
  @Input() streamId: any;
  @Input() shortEpgForChannelList: any;
  @Input() focused: boolean = false;
  @Input() currentCategory: number = 0;
  epg = new Array<CompleteEpgListing>();
  shortEpgItem: any;
  epgListing: any;
  private epgReloadInterval;

  constructor(
    private evelinService: EvelinService,
    private appInfoService: AppInfoService
  ) { }

  ngOnDestroy(): void {
    clearInterval(this.epgReloadInterval);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.streamId) {
      this.streamId = changes.streamId.currentValue;
      this.regenerateComponent();
    }
  }

  ngOnInit(): void {
    this.epgReloadInterval = setInterval(async () => {
      this.regenerateComponent();
    }, 600000);
  }

  async regenerateComponent() {
    this.epg = new Array<CompleteEpgListing>();
    if (this.streamId) {
      let stream = this.appInfoService.getCachedStreamByStreamId(this.streamId, StreamOptionType.TVChannels) as LiveStream;

      if (stream.epg_name != null) {
        this.epg = this.appInfoService.getShortEpgByChannelName(stream.epg_name);
      }

      if (this.shortEpgForChannelList && this.epg && this.epg.length) {
        this.shortEpgItem = [...this.epg].shift();
      } else {
        this.epgListing = this.epg;
        console.log(this.epgListing, this.epg)
      }
    }
  }
}
