<div #controlsContainer class="info-div controls" [ngClass]="{'hidden': hidden}">
    <div class="player-progress-bar progress-container">
        <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
        <div #slider class="slider" (keydown)="sliderMove($event)" [tabIndex]="2">1</div>
    </div>
    <div class="inner-controls">
        <div class="timestamp">{{timestampStart}}</div>
        <div #controls class="buttons-container" (keydown)="onKeydown($event)" (keyup)="clearAccelerator()" [tabindex]="1">
            <button mat-button class="control-button rewind-button">
                <img
                    src="{{selectedButton === 0 ? isJumping ? 'assets/images/player_icons/previous_active.png' : 'assets/images/player_icons/backward_active.png' : 'assets/images/player_icons/backward.png'}}" />
            </button>
            <button mat-button class="control-button">
                <img *ngIf="playerState === 3"
                    src="{{selectedButton === 1 ? 'assets/images/player_icons/play_active.png' : 'assets/images/player_icons/play.png'}}" />
                <img *ngIf="playerState !== 3"
                    src="{{selectedButton === 1 ? 'assets/images/player_icons/pause_active.png' : 'assets/images/player_icons/pause.png'}}" />
            </button>
            <button mat-button class="control-button">
                <img
                    src="{{selectedButton === 2 ? isJumping ? 'assets/images/player_icons/next_active.png' : 'assets/images/player_icons/forward_active.png' : 'assets/images/player_icons/forward.png'}}" />
            </button>
            <button mat-button class="control-button ratio-button"
                [ngClass]="{'ratio-button-active':selectedButton ===3}">{{ smallScreen ? "4:3" : "16:9" }}</button>
        </div>
        <div class="timestamp-end">{{timestampEnd}}</div>
        <div class="description">{{info?.description || info?.plot}}</div>
    </div>
    <!-- <div style="color: white;
        position: fixed;
        top: 0;
        left: 0;">KEY {{temp}}</div> -->
</div>