import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, ErrorHandler, NgZone } from "@angular/core";
import { LogService } from "../services/log.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private logService: LogService
  ) { }

  handleError(error: Error | any) {
    if (error instanceof HttpErrorResponse) {
      return;
    }

    console.log("Handling error in GlobalErrorHandler")

    if (error.message) {
      if (error.stack) {
        this.logService.error(error.message, error.stack);
      } else {
        this.logService.error(error.message);
      }
    } else if (error.rejection) {
      if (error.stack) {
        this.logService.error(error.rejection, error.stack);
      } else {
        this.logService.error(error.rejection);
      }
    } else {
      try {
        this.logService.error(JSON.stringify(error));
      }
      catch {
        this.logService.error("Failed to stringify error - global-error.handler.ts")
      }
    }
  }
}