<div [class]="selectedTheme">
  <div class="background">
    <h1 mat-dialog-title>
      {{ "exit-message" | translate }}
    </h1>
    <div>
      <button
        type="button"
        #buttonExit
        (keydown)="onKeydown($event, true)"
        (click)="close(true)"
      >
        {{ "quit" | translate }}
      </button>
      <button
        type="button"
        #buttonBack
        (keydown)="onKeydown($event, false)"
        (click)="close(false)"
      >
        {{ "settings.back" | translate }}
      </button>
    </div>
  </div>
</div>
