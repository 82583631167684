import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';

import { AppInfoService } from 'src/app/services/app-info.service';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-player-settings',
  templateUrl: './player-settings.component.html',
  styleUrls: ['./player-settings.component.css']
})
export class PlayerSettingsComponent implements OnInit {
  @ViewChild('buttonSave') buttonSave: MatButton;
  @ViewChild('options') options: ElementRef;

  constructor(
    public appInfoService: AppInfoService,
    private router: Router,
    private platformService: BasePlatformService
  ) { }

  settings: any = {
    bufferingTime: 0,
    episodeAutoplay: false,
    episodeAutoplayDelay: 0
  };

  currentStep = 0;
  selectedTheme: string;
  
  ngOnInit() {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );

    let settings = localStorage.getItem("fern-player-settings");
    if (settings)
      this.settings = JSON.parse(settings);
  }

  ngAfterViewInit() {
    setTimeout(() => this.focusOption(0), 100);
  }

  confirm() {
    localStorage.setItem("fern-player-settings", JSON.stringify(this.settings));
    this.router.navigate(['/settings']);
  }

  focusOption(step) {
    if (this.currentStep === 0 && step < 0)
      return;
    else if (this.currentStep === 2 && step > 0)
      this.buttonSave.focus();
    else if (this.currentStep === 3 && step > 0)
      return;

    this.currentStep += step;

    this.options.nativeElement.children[this.currentStep].focus();
  }

  changeValue(value) {
    switch (this.currentStep) {
      case 0:
        if (
          (value > 0 || this.settings.bufferingTime > 0) &&
          (value < 0 || this.settings.bufferingTime < 60)
        )
          this.settings.bufferingTime += (value * 5);
        break
      case 1:
        this.settings.episodeAutoplay = !this.settings.episodeAutoplay;
        break
      case 2:
        if (
          (value > 0 || this.settings.episodeAutoplayDelay > 0) &&
          (value < 0 || this.settings.episodeAutoplayDelay < 60)
        )
          this.settings.episodeAutoplayDelay += (value * 5);
        break
      default:
        break;
    }
  }

  back() {
    this.router.navigate(['/settings']);
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.focusOption(-1);
        break;
      case 'Down':
        this.focusOption(1);
        break;
      case 'Enter':
        if (this.currentStep === 3)
          this.confirm();
        break;
      case 'Left':
        this.changeValue(-1);
        break;
      case 'Right':
        this.changeValue(1);
        break;
      case 'BrowserBack':
        this.back();
        break;
      default:
        break;
    }
  }
}
