import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CompleteEpgListing } from '../../models/epg.model';
import { AppInfoService } from '../../services/app-info.service';

@Component({
  selector: 'app-epg-info',
  templateUrl: './epg-info.component.html',
  styleUrls: ['./epg-info.component.css']
})
export class EpgInfoComponent implements OnInit {
  public epgId: string;
  public epgItem = new CompleteEpgListing();

  constructor(private route: ActivatedRoute, private appInfoService: AppInfoService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.epgId = params['epgId'];

      let tempEpgItem = this.appInfoService.getCachedEpgInfo();

      if(tempEpgItem.id == parseInt(this.epgId)) {
        this.epgItem = tempEpgItem;
      }
    });
  }

}
