import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as Constants from '../helpers/constants.helper';
import { CategoryStream, StreamOptionType } from '../models/category.model';
import { Login, UserInfoV2 } from '../models/login.model';
import { CacheService } from './cache.service';
import { Theme } from '../models/theme.model';
import { CompleteEpgListing, ShortEpg } from '../models/epg.model';
import { HttpClient } from '@angular/common/http';
import { XmlProgramme } from '../models/xml-tv.model';
import { ClientProfile } from '../models/profile.model';
import { LiveStream, MovieStream, RadioStream, Stream, TVShowStream } from '../models/stream.model';
import { SystemValues } from '../models/system.model';
import { IpInfo } from '../models/ipinfo.model';

@Injectable()
export class AppInfoService {
  constructor(
    private translate: TranslateService,
    private cache: CacheService,
    private http: HttpClient
  ) {
  }

  public set setCacheTime(value: Date) {
    localStorage.setItem(Constants.cacheTime, value.toJSON());
  }

  shouldCache(): boolean {
    // let res = localStorage.getItem(Constants.cacheTime);
    // if (res && res.length) {
    //   let time = new Date(res);
    //   const today = new Date();
    //   if (today.toDateString() == time.toDateString()) {
    //     return false;
    //   }
    //   return true;
    // }
    // return true;

    if (this.cache.liveStreams && this.cache.liveStreams.length > 0) {
      return false;
    } return true;
  }

  itemValue = new Subject();

  public set setLanguage(value: string) {
    this.itemValue.next(value);
    localStorage.setItem(Constants.localStorageLanguage, value);
    this.translate.use(value);
  }

  get getLanguage() {
    let lang = localStorage.getItem(Constants.localStorageLanguage);
    if (lang) {
      this.translate.use(lang);
    }
    return localStorage.getItem(Constants.localStorageLanguage);
  }

  public get title() {
    return 'Fern TV';
  }

  get getUserInfoV2(): UserInfoV2 {
    return JSON.parse(
      localStorage.getItem(Constants.fullAuthDataV2) as string
    ) as UserInfoV2;
  }

  public getHiddenCategories(streamType: StreamOptionType): CategoryStream[] {
    let categories: string | null = null;
    switch (streamType) {
      case StreamOptionType.TVChannels:
        categories = localStorage.getItem(
          Constants.localStorageHiddenTvChannels
        );
        break;
      case StreamOptionType.Movies:
        categories = localStorage.getItem(Constants.localStorageHiddenMovies);
        break;
      case StreamOptionType.TVShows:
        categories = localStorage.getItem(Constants.localStorageHiddenTvShows);
        break;
    }

    if (categories) {
      return JSON.parse(categories) as CategoryStream[];
    }

    return new Array<CategoryStream>();
  }

  public setHiddenCategories(streamType: StreamOptionType, value: string) {
    switch (streamType) {
      case StreamOptionType.TVChannels:
        localStorage.setItem(Constants.localStorageHiddenTvChannels, value);
        break;
      case StreamOptionType.Movies:
        localStorage.setItem(Constants.localStorageHiddenMovies, value);
        break;
      case StreamOptionType.TVShows:
        localStorage.setItem(Constants.localStorageHiddenTvShows, value);
        break;
    }
  }

  public cacheTvChannels(channels: LiveStream[]) {
    this.cache.cacheTvChannels(channels);
  }

  public cacheFavourites(channels: LiveStream[]) {
    this.cache.cacheFavourites(channels);
  }

  public cacheTvShows(tvShows: TVShowStream[]) {
    this.cache.cacheTvShows(tvShows);
  }

  public cacheMovies(movies: MovieStream[]) {
    this.cache.cacheMovies(movies);
  }

  public cacheRadio(radio: RadioStream[]) {
    this.cache.cacheRadio(radio);
  }

  public getShortEpg(): Map<string, CompleteEpgListing[]> {
    return this.cache.getShortEpg();
  }

  public cacheShortEpg(epg: Map<string, CompleteEpgListing[]>) {
    this.cache.cacheShortEpg(epg);
  }

  public getShortEpgByChannelName(channelName: string): CompleteEpgListing[] {
    return this.cache.getShortEpgByChannelName(channelName);
  }

  getCachedStreamByStreamId(
    streamId: number,
    streamType: StreamOptionType
  ): LiveStream | Stream {
    if (streamType == StreamOptionType.TVChannels) {
      return this.cache.getCachedTvChannelStreamById(streamId);
    }

    if (streamType == StreamOptionType.TVShows) {
      return this.cache.getCachedTvShowStreamById(streamId);
    }

    if (streamType == StreamOptionType.Movies) {
      return this.cache.getCachedMovieStreamById(streamId);
    }

    return new Stream();
  }

  public getCachedTvChannelsByCategory(categoryId: number): Stream[] {
    return this.cache.getCachedTvChannelsByCategory(categoryId);
  }

  public getCachedTvShowsByCategory(categoryId: number): Stream[] {
    return this.cache.getCachedTvShowsByCategory(categoryId);
  }

  public getCachedMoviesByCategory(categoryId: number): Stream[] {
    return this.cache.getCachedMoviesByCategory(categoryId);
  }

  public getCachedRadioByCategory(categoryId: number): RadioStream[] {
    return this.cache.getCachedRadioByCategory(categoryId);
  }

  public cacheCategories(streamType: StreamOptionType, values: CategoryStream[]) {
    switch (streamType) {
      case StreamOptionType.TVChannels:
        // let categoryAll = this.generateCategoryAll();
        // values.unshift(categoryAll);
        this.cache.cacheCategories(values, Constants.tvChannelsCategories);
        break;
      case StreamOptionType.TVShows:
        this.cache.cacheCategories(values, Constants.tvShowsCategories);
        break;
      case StreamOptionType.Movies:
        this.cache.cacheCategories(values, Constants.moviesCategories);
        break;
      case StreamOptionType.Radio:
        this.cache.cacheCategories(values, Constants.radioCategories);
        break;
    }
  }

  public appendFavouritesCategory(value: CategoryStream) {
    let categoriesInCache = this.cache.getCategoriesFromCacheByCategoryType(StreamOptionType.TVChannels);
    if (categoriesInCache.find(x => x.id == value.id)) {
      return;
    }
    categoriesInCache.unshift(value);
    this.cache.cacheCategories(categoriesInCache, Constants.tvChannelsCategories);
  }

  private generateCategoryAll(): CategoryStream {
    let lang = this.getLanguage;
    let name = "";
    switch (lang) {
      case "hr":
        name = "Sve";
        break;
      case "en":
        name = "All";
        break;
      case "de":
        name = "Alles";
        break;
    }
    let res = new CategoryStream();
    res.category_name = name;
    res.id = 0;

    return res;
  }

  public getCategoriesFromCache(streamType: StreamOptionType): CategoryStream[] {
    return this.cache.getCategoriesFromCacheByCategoryType(streamType);
  }

  public getSelectedTheme(): Theme | null {
    let res = localStorage.getItem(Constants.localStorageTheme);
    if (res) {
      return parseInt(res) as Theme;
    }

    return null;
  }

  public setSelectedTheme(value: Theme) {
    localStorage.setItem(Constants.localStorageTheme, value.toString());
  }

  public getCachedEpgInfo(): CompleteEpgListing {
    let epgInfoTemp = localStorage.getItem(Constants.localStorageEpgInfo);
    if (epgInfoTemp) {
      return JSON.parse(epgInfoTemp) as CompleteEpgListing;
    }

    return new CompleteEpgListing();
  }

  public setCachedEpgInfo(epgInfo: CompleteEpgListing) {
    localStorage.setItem(
      Constants.localStorageEpgInfo,
      JSON.stringify(epgInfo)
    );
  }

  public getCachedShortEpg(streamId: number): ShortEpg | boolean {
    let tempShortEpg = localStorage.getItem(
      Constants.localStorageShortEpgInfo + streamId
    );
    if (tempShortEpg) {
      console.log('Fetching cached short EPG', streamId)
      return JSON.parse(tempShortEpg) as ShortEpg;
    } else {
      return false;
    }
  }

  public setCachedShortEpg(streamId: number, epg: ShortEpg) {
    localStorage.setItem(
      Constants.localStorageShortEpgInfo + streamId,
      JSON.stringify(epg)
    );
  }

  public invalidateCachedShortEpg() {
    const keys = Object.keys(localStorage).filter((key) =>
      key.includes(Constants.localStorageShortEpgInfo)
    );
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  public removeCachedEpgInfo() {
    localStorage.removeItem(Constants.localStorageEpgInfo);
  }

  public getPlayingComponentState(): any {
    let category = localStorage.getItem(Constants.localStorageContinueCategory);
    let streamId = localStorage.getItem(Constants.localStorageContinueStreamId);

    if (category && streamId) {
      localStorage.removeItem(Constants.localStorageContinueCategory);
      localStorage.removeItem(Constants.localStorageContinueStreamId);

      return {
        category: category,
        streamId: parseInt(streamId),
      };
    }

    return false;
  }

  public setPlayingComponentState(category: string, streamId: number) {
    localStorage.setItem(Constants.localStorageContinueCategory, category);
    localStorage.setItem(
      Constants.localStorageContinueStreamId,
      streamId.toString()
    );
  }
  public set setDns(value: string) {
    localStorage.setItem(Constants.localStorageDns, value);
  }

  get getDns() {
    let dns = localStorage.getItem(Constants.localStorageDns);

    if (dns == null) {
      dns = "1";
    }
    return dns;
  }

  get getDns1Value() {
    let dns = localStorage.getItem(Constants.localStorageDns1);

    return dns;
  }

  get getDns2Value() {
    let dns = localStorage.getItem(Constants.localStorageDns2);

    return dns;
  }

  public get getRootApiUrl() {
    let dns = this.getDns;
    if (dns == "1") {
      let dnsUrl = localStorage.getItem(Constants.localStorageDns1);
      localStorage.setItem(Constants.localStorageApiUrl, dnsUrl as string);
      return dnsUrl as string;
    }

    let dnsUrl = localStorage.getItem(Constants.localStorageDns2);
    localStorage.setItem(Constants.localStorageApiUrl, dnsUrl as string);
    return dnsUrl as string;
  }

  public tokenExists(): boolean {
    let token = localStorage.getItem(Constants.localStorageToken);
    if (token && token != "") {
      return true;
    }

    return false;
  }

  public getToken(): string | null {
    let token = localStorage.getItem(Constants.localStorageToken);
    if (token && token != "") {
      return token;
    }

    return null;
  }

  public setToken(token: string) {
    localStorage.setItem(Constants.localStorageToken, token);
  }

  public getCurrentProfile(): ClientProfile {
    let profileString = localStorage.getItem(Constants.localStorageClientProfile);
    let profile = null;
    if (profileString) {
      profile = JSON.parse(profileString) as ClientProfile;
    }

    return profile;
  }

  public setCurrentProfile(profile: ClientProfile) {
    localStorage.setItem(Constants.localStorageClientProfile, JSON.stringify(profile));
  }

  public getPlayerSettings() {
    let settings = localStorage.getItem(Constants.localStoragePlayerSettings);
    if (settings)
      return JSON.parse(settings);
    else
      return null;
  }

  public setSystemValues(value: SystemValues) {
    localStorage.setItem(Constants.localStorageSystemValues, JSON.stringify(value));
  }

  public getSystemValues(): SystemValues {
    let res = localStorage.getItem(Constants.localStorageSystemValues);

    if (res && res != "") {
      return JSON.parse(res) as SystemValues;
    }

    return null;
  }

  public setCorrectPinTime() {
    localStorage.setItem(Constants.localStorageCorrectPinTime, Date.now().toString());
  }

  public isLastCorrectPinValid(): boolean {
    let pinTime = localStorage.getItem(Constants.localStorageCorrectPinTime);

    if (pinTime) {
      let pinTimestamp = parseInt(pinTime);

      if (Date.now() > (pinTimestamp + 3600000)) {
        return false;
      }

      return true;
    }

    return false;
  }

  public setIpAddress(ip: IpInfo) {
    localStorage.setItem(Constants.localStorageIpAddress, JSON.stringify(ip));
  }

  public getIpAddress(): IpInfo {
    let res = new IpInfo();

    let resString = localStorage.getItem(Constants.localStorageIpAddress);

    if (!resString) {
      res.ip = "0.0.0.0";
      res.org = "Nema podataka"
    } else {
      res = JSON.parse(resString) as IpInfo;
    }

    return res;
  }
}
