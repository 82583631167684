import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AppInfoService } from "./app-info.service";
import * as Constants from '../helpers/constants.helper';
import { Login, LoginV2, PasswordlessLoginReq, TokenResponseV2 } from "../models/login.model";
import { BasePlatformService } from "../platforms/iplatform.interface";
import { TizenDeviceInfo } from "../models/device.model";
import { LicenceActivationReq } from "../models/licence.model";
import { EvelinService } from "./evelin.service";

@Injectable()
export class AuthService {
  constructor(private apiService: ApiService, private appInfoService: AppInfoService, private evelinService: EvelinService, private platformService: BasePlatformService) { }

  public get getDeviceInfo(): TizenDeviceInfo {
    return JSON.parse(localStorage.getItem(Constants.localStorageDeviceInfo)) as TizenDeviceInfo;
  }

  public set setDeviceInfo(value: TizenDeviceInfo) {
    localStorage.setItem(Constants.localStorageDeviceInfo, JSON.stringify(value));
  }

  private deviceInfo: TizenDeviceInfo = null;
  public async generateDeviceInfo() {
    try {
      let localStorageDeviceInfo = localStorage.getItem(Constants.localStorageDeviceInfo);
      console.log(localStorageDeviceInfo)
      if (localStorageDeviceInfo && localStorageDeviceInfo != null) {
        this.deviceInfo = JSON.parse(localStorageDeviceInfo) as TizenDeviceInfo;
      } else {
        this.deviceInfo = await this.platformService.getDeviceInfo();
      }
      localStorage.setItem(Constants.localStorageDeviceInfo, JSON.stringify(this.deviceInfo));
    } catch (e) {
      let mac = "XX:XX:XX:XX:XX:XX".replace(/X/g, function () {
        return "0123456789ABCDEF".charAt(Math.floor(Math.random() * 16))
      });
      let modelName = "TV-" + Math.floor(100000 + Math.random() * 900000);
      this.deviceInfo = { mac: mac, modelName: modelName };
      localStorage.setItem(Constants.localStorageDeviceInfo, JSON.stringify(this.deviceInfo));
    }
  }

  public async isLicenceValid(): Promise<boolean> {
    try {
      console.log(this.getDeviceInfo.mac)
      await this.apiService.get(this.appInfoService.getRootApiUrl + "licence/" + this.getDeviceInfo.mac);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async activateLicence(licence: string): Promise<boolean> {
    let payload = new LicenceActivationReq(licence, this.getDeviceInfo.modelName, this.getDeviceInfo.mac);

    try {
      await this.apiService.post(this.appInfoService.getRootApiUrl + "licence", payload);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async passwordlessLogin(appId: string): Promise<any> {
    await this.generateDeviceInfo();
    let payload = new PasswordlessLoginReq(appId, this.deviceInfo.mac);

    try {
      let v2res = await this.apiService.post(this.appInfoService.getRootApiUrl + "authorize", payload);
      if (v2res && v2res.token) {
        return this.continueLoginFlow(v2res);
      }
    } catch (e) {
      return this.handleError(e);
    }
  }

  public async login(username: string, password: string): Promise<any> {
    await this.generateDeviceInfo();
    let payload = new LoginV2(username, password, this.deviceInfo.mac);

    try {
      let v2res = await this.apiService.post(this.appInfoService.getRootApiUrl + "login", payload) as TokenResponseV2;

      if (v2res && v2res.token) {
        return this.continueLoginFlow(v2res);
      }

    } catch (e) {
      return this.handleError(e);
    }
  }

  private async continueLoginFlow(v2res: TokenResponseV2): Promise<boolean> {
    this.appInfoService.setToken(v2res.token);

    this.appInfoService.setSystemValues(await this.evelinService.getSystemValues());
    localStorage.setItem(Constants.fullAuthDataV2, JSON.stringify(await this.evelinService.getClientData()));

    let clientData = this.appInfoService.getUserInfoV2;

    if(clientData.username && clientData.password) {
      return true;
    }

    return false;
  }

  private handleError(e: any): any {
    if (e.error && e.error.error && e.error.error == "Not enough credit to activate this device") {
      return 403;
    } else if (e.status == 401 && e.error.message == "No created or authorized access request found") {
      return 401;
    }
    return false;
  }

  public async logout() {
    localStorage.removeItem(Constants.fullAuthDataV2);
  }

  public exitApplication () {   //TODO: move to platformService
    localStorage.removeItem("fern-last-home");
    window.open("about:blank", "_self");
    window.close();
  }

  public async isTokenValid(): Promise<boolean> {
    try {
      let res = await this.apiService.get(this.appInfoService.getRootApiUrl + "external/auth") as any;

      if (res.message == "Authenticated") {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }

  }
}