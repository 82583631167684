<div class="background" class="{{ theme }}">
  <div class="row">
    <div class="col-md-1">
      <button
        #settingsButtonBack
        type="button"
        class="btn btn-secondary buttons-headers"
        (click)="goBack()"
        (keydown)="onHeaderKeydown($event)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="currentColor"
          class="bi bi-arrow-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
          />
        </svg>
      </button>
    </div>

    <div class="col-md-10">
      <h1>{{ "settings.settings" | translate }}</h1>
    </div>
    <div class="col-md-1">
      <img
        src="assets/images/podesavanja_icon.png"
        class="setting-header-icon"
      />
    </div>
  </div>
  <div #settingList class="settings-list-conteiner">
    <button
      class="settings-list-card"
      (click)="goToChangeLanguage()"
      (keydown)="onKeydown($event)"
    >
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span
        class="settings-list-title"
        >{{ "settings.change-language" | translate }}</span
      >
    </button>
    <!-- <button class="settings-list-card" (click)="goToParentalControls()" (keydown)="onKeydown($event)">
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span class="settings-list-title">{{
        "settings.parental-controls" | translate
        }}</span>
    </button>
    <button class="settings-list-card" (click)="goToCategoryHiding()" (keydown)="onKeydown($event)">
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span class="settings-list-title">{{
        "settings.category-hiding" | translate
        }}</span>
    </button> -->
    <!-- <button class="settings-list-card" (click)="goToChangePin()" (keydown)="onKeydown($event)">
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span class="settings-list-title">{{
        "settings.pin-settings" | translate
        }}</span>
    </button> -->
    <!-- <button
      class="settings-list-card"
      (click)="goToPlayerSettings()"
      (keydown)="onKeydown($event)"
    >
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span
        class="settings-list-title"
        >{{ "settings.player-settings" | translate }}</span
      >
    </button> -->
    <button
      class="settings-list-card"
      (click)="goToUserInfo()"
      (keydown)="onKeydown($event)"
    >
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span
        class="settings-list-title"
        >{{ "settings.user-info" | translate }}</span
      >
    </button>
    <!-- <button class="settings-list-card" (click)="goToSkinSelect()" (keydown)="onKeydown($event)" disabled="true">
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span class="settings-list-title">{{
        "settings.choose-skin" | translate
        }}</span>
    </button> -->
    <!-- <button
      class="settings-list-card"
      (click)="goToDnsChange()"
      (keydown)="onKeydown($event)"
    >
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span
        class="settings-list-title"
        >{{ "settings.change-dns" | translate }}</span
      >
    </button> -->
    <button
      class="settings-list-card"
      (click)="goToSpeedTest()"
      (keydown)="onKeydown($event)"
    >
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span
        class="settings-list-title"
        >Speed Test</span
      >
    </button>
    <button
      class="settings-list-card"
      (click)="goToChangeUser()"
      (keydown)="onKeydown($event)"
    >
      <img src="assets/images/settings/gear.png" class="setting-icon" /><span
        class="settings-list-title"
        >{{ "settings.change-user" | translate }}</span
      >
    </button>
  </div>
</div>
