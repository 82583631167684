import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';

import { AppInfoService } from 'src/app/services/app-info.service';
import { TranslateService } from '@ngx-translate/core';
import { BasePlatformService } from '../../platforms/iplatform.interface';

@Component({
  selector: 'app-exit-dialog',
  templateUrl: './exit-dialog.component.html',
  styleUrls: ['./exit-dialog.component.css'],
})
export class ExitDialogComponent {
  @ViewChild('buttonExit') buttonExit: ElementRef;
  @ViewChild('buttonBack') buttonBack: ElementRef;
  selectedTheme: string;

  constructor(
    private platformService: BasePlatformService,
    private appInfoService: AppInfoService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ExitDialogComponent>
  ) {}

  ngOnInit() {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
  }

  ngAfterViewInit() {
    this.buttonBack.nativeElement.focus();
  }

  onKeydown(e: any, status: boolean) {
    let key = this.platformService.keymapper(e);
    switch (key.toString()) {
      case 'Left':
        this.buttonExit.nativeElement.focus();
        break;
      case 'Right':
        this.buttonBack.nativeElement.focus();
        break;
      case 'Enter':
      case '32':
        this.close(status);
        break;
      case 'BrowserBack':
        this.close(false);
        break;
      default:
        break;
    }
  }

  close(status: boolean) {
    this.dialogRef.close(status);
  }
}
