import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatTitle' })
export class FormatTitlePipe implements PipeTransform {
    transform(title: string): string {
        if (!title) {
            return "";
        }

        let spliter = " |";
        if (title.includes(" HD"))
            spliter = " HD";
        else if (title.includes(" FHD"))
            spliter = " FHD";
        else if (title.includes(" HEVC"))
            spliter = " HEVC";

        const array = title.split(spliter);

        if (array.length === 1)
            return title;
        else if (array.length === 2)
            return array.join("");

        array.pop();
        return array.join(" |");
    }
}
