import {
  Component, Input
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stb-details',
  templateUrl: './stb-details.component.html',
  styleUrls: ['./stb-details.component.css'],
})
export class StbExtremeDetailsComponent {
  @Input() details: any;

  constructor(public translate: TranslateService) { }
}