import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { of } from 'rxjs';
import { groupBy, mergeMap, reduce, map, toArray } from 'rxjs/operators';
import { CompleteEpg, CompleteEpgListing } from '../../../models/epg.model';
import { AppInfoService } from '../../../services/app-info.service';
import { Location } from '@angular/common';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { PlayerComponent } from '../../player/player.component';
import { StreamOptionType } from '../../../models/category.model';
import { LiveStream } from '../../../models/stream.model';
import { MatButton } from '@angular/material/button';
import { ThemeHelper } from '../../../helpers/theme.helper';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-stb-extreme-tv-arhive-details',
  templateUrl: './stb-extreme-tv-arhive-details.component.html',
  styleUrls: ['./stb-extreme-tv-arhive-details.component.css']
})
export class StbExtremeTvArhiveDetailsComponent implements OnInit {
  public streamUrl: string;
  public streamId: number;
  public epg = new Array<CompleteEpgListing>();
  dates: any = [];
  selectedEpgItem = new CompleteEpgListing();
  selectedDate: any = [];
  currentStep = 0;
  isPlayerHidden = true;
  playStep = -1;
  currentChannel: LiveStream;
  showDates = false;
  theme: string;

  @ViewChildren('streamList') streamList: QueryList<any>;
  @ViewChild(PlayerComponent) player: PlayerComponent;
  @ViewChild('openDatesButton') openDatesButton: MatButton;
  @ViewChild('shownDates') shownDates: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private appInfoService: AppInfoService,
    private evelinService: EvelinService,
    private location: Location,
    private platformService: BasePlatformService
  ) {
    this.theme = ThemeHelper.transformThemeToString(this.appInfoService.getSelectedTheme()) + " background";
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params: Params) => {
      this.streamId = params['streamId'];
    });

    this.currentChannel = this.appInfoService.getCachedStreamByStreamId(this.streamId, StreamOptionType.TVChannels) as LiveStream;
    console.log(this.currentChannel);

    await this.generateCompleteEpg();
  }

  private async generateCompleteEpg() {
    // todo fix me 
    this.epg = await this.evelinService.getEpgByStreamId(this.streamId);
    console.log(this.epg);
    let nDaysAgo = 3;
    let nDaysAgoDate = new Date();
    //nDaysAgoDate.setHours(0, 0, 0, 0);
    nDaysAgoDate.setDate(nDaysAgoDate.getDate() - nDaysAgo);
    this.epg = this.epg?.filter(x => new Date(x.start as string) > nDaysAgoDate && x.has_archive);

    of(...this.epg).pipe(
      groupBy((p: any) => p.start.split(' ')[0]),
      mergeMap(group$ =>
        group$.pipe(reduce((acc, cur) => [...acc, cur], [`${group$.key}`]))
      ),
      map(arr => ({ date: arr[0], streams: arr.slice(1) })),
      toArray()
    ).subscribe(p => {
      this.dates = p;
      this.selectedDate = this.dates.filter((x: any) => x.date == new Date().toISOString().split('T')[0])[0];
    });
    console.log(this.selectedDate);

    setTimeout(() => {
      this.selectItem(0);
    }, 100);
  }

  selectEpgItem(epgItem: CompleteEpgListing) {
    this.selectedEpgItem = epgItem;
    console.log(this.selectedEpgItem)
  }

  watchStream() {
    if (this.selectedEpgItem.has_archive == 1) {
      this.playStep = this.currentStep;

      const { start_timestamp, end_timestamp: stop_timestamp } = this.selectedEpgItem;
      const start = start_timestamp ? start_timestamp : 0;
      const end = stop_timestamp ? stop_timestamp : 0;

      const duration = (end - start) / 60;
      let dateTemp = new Date(start * 1000);
      let date = dateTemp.toISOString().split('T')[0];
      const searchRegExp = /:/g;
      const replaceWith = '-';
      let time = dateTemp.toISOString().split('T')[1].substring(0, dateTemp.toISOString().split('T')[1].indexOf(".")).replace(searchRegExp, replaceWith);

      const startTime = date + ":" + time;

      console.log(duration, startTime);

      this.streamUrl = this.currentChannel.archive;
      console.log(this.streamUrl);
      setTimeout(() => {
        this.isPlayerHidden = false;
        this.player.play(duration, startTime);
        this.player.setFullScreen();
      }, 100);
    }
  }

  stepToNewItem(step: number): void {
    if (this.currentStep === 0 && step < 0) {
      this.openDatesButton.focus();
      return;
    } else if (this.currentStep === this.selectedDate.streams.length - 1 && step === 1)
      return;

    this.currentStep += step;
    this.selectItem(this.currentStep);
  }

  selectItem(index: number): void {
    this.selectedEpgItem = this.selectedDate.streams[index];
    this.streamList.toArray()[index].nativeElement.focus();
  }

  onKeydown(e: any, index: number) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    switch (key) {
      case 'Up':
        this.stepToNewItem(-1);
        break;
      case 'Down':
        this.stepToNewItem(1);
        break;
      case 'Enter':
        //if (this.playStep === index)
        //  this.player.setFullScreen();
        //else
        this.watchStream();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onDateHeaderKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        this.stepToNewItem(1);
        break;
      case 'Enter':
        console.log(this.dates);
        this.showDates = true;
        setTimeout(() => this.shownDates.nativeElement.children[0].focus(), 50);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  selectedDateStep = 0;
  onDateKeydown(e: any, date: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    switch (key) {
      case 'Up':
        if (this.selectedDateStep > 0)
          this.selectedDateStep--;
        this.shownDates.nativeElement.children[this.selectedDateStep].focus()
        break;
      case 'Down':
        if (this.selectedDateStep < 4)
          this.selectedDateStep++;
        this.shownDates.nativeElement.children[this.selectedDateStep].focus()
        break;
      case 'Enter':
        //if (this.playStep === index)
        //  this.player.setFullScreen();
        //else
        console.log(date);
        this.showDates = false;
        this.selectedDate = this.dates.find((x: any) => x.date == date.date);
        setTimeout(() => this.selectItem(0), 50);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  goBack() {
    this.location.back();
  }

  closeFullSreen() {
    setTimeout(() => this.selectItem(this.currentStep), 50);
  }

}
