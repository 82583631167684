import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppInfoService } from '../../services/app-info.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { BasePlatformService } from '../../platforms/iplatform.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExitDialogComponent } from '../exit-dialog/exit-dialog.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css'],
})
export class LanguageSelectComponent {
  @ViewChild('buttonEn', { read: ElementRef, static: true })
  private buttonEn: ElementRef;
  @ViewChild('buttonHr', { read: ElementRef, static: true })
  private buttonHr: ElementRef;
  @ViewChild('buttonDe', { read: ElementRef, static: true })
  private buttonDe: ElementRef;

  public selectedLanguage: string = 'en';

  constructor(
    private appInfo: AppInfoService,
    private translate: TranslateService,
    private router: Router,
    private platformService: BasePlatformService,
    public dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngAfterViewInit() {
    this.setFocus(this.buttonEn);
  }

  setFocus(element: any) {
    const ele = element.nativeElement;
    if (ele) {
      ele.focus();
    }
  }

  confirm(selectedLanguage: string) {
    // this.translate.use(selectedLanguage);
    this.selectedLanguage = selectedLanguage;
    this.appInfo.setLanguage = selectedLanguage;
    this.router.navigate(['/landing']);
  }

  languagePreview(language: string) {
    this.translate.use(language);
  }

  onKeydownButtonEn(e: any, selectedLanguage: string) {
    e.preventDefault()
    let key = this.platformService.keymapper(e);

    switch (key) {
      case 'Down':
        this.setFocus(this.buttonHr);
        break;
      case 'Enter':
        this.confirm(selectedLanguage);
        break;
      case 'BrowserBack':
        this.openExitDialog();
        break;
      default:
        break;
    }
  }
  onKeydownButtonHr(e: any, selectedLanguage: string) {
    e.preventDefault()
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        this.setFocus(this.buttonDe);
        break;
      case 'Up':
        this.setFocus(this.buttonEn);
        break;
      case 'Enter':
        this.confirm(selectedLanguage);
        break;
      case 'BrowserBack':
        this.openExitDialog();
        break;
      default:
        break;
    }
  }
  onKeydownButtonDe(e: any, selectedLanguage: string) {
    e.preventDefault()
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.setFocus(this.buttonHr);
        break;
      case 'Enter':
        this.confirm(selectedLanguage);
        break;
      case 'BrowserBack':
        this.openExitDialog();
        break;
      default:
        break;
    }
  }

  async openExitDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '80rem';
    dialogConfig.height = '10rem';

    const dialogRef = this.dialog.open(ExitDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((shouldExit) => {
      if (shouldExit) this.authService.exitApplication();
    });
  }
}
