export class ClientProfileRequest {
    active: boolean;
    pin: string;
    profile_name: string;
    type: string;
    avatar: number;
}

export class ClientProfile {
    id: number;
    active: boolean;
    pin: string;
    profile_name: string;
    type: string;
    avatar: number;
    master: boolean;
}