import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientProfile } from 'src/app/models/profile.model';
import { AppInfoService } from 'src/app/services/app-info.service';
import { Location } from '@angular/common';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { MatButton } from '@angular/material/button';
import * as Constants from '../../helpers/constants.helper';
import { ThemeHelper } from '../../helpers/theme.helper';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-select-profile',
  templateUrl: './select-profile.component.html',
  styleUrls: ['./select-profile.component.css']
})
export class SelectProfileComponent implements OnInit {
  @ViewChild('cardsContainer') profileCards: ElementRef;
  @ViewChild('confirmBtn') confirmBtn: MatButton;
  @ViewChild('backBtn') backBtn: MatButton;
  @ViewChild('editBtn') editBtn: MatButton;
  @ViewChild('deleteBtn') deleteBtn: MatButton;

  profiles = new Array<ClientProfile>();
  profile = new ClientProfile();
  backButtonDisabled = true;
  loadingVisible = true;
  currentProfileIndex = 0;
  newProfileId = 0;
  showLoader = true;
  autoSelect = 0;
  autoInterval: any;
  theme: string;

  constructor(
    private evelinService: EvelinService,
    private router: Router,
    private appInfo: AppInfoService,
    private location: Location,
    private route: ActivatedRoute,
    private platformService: BasePlatformService) {
    this.theme = ThemeHelper.transformThemeToString(this.appInfo.getSelectedTheme());
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params: Params) => {
      this.backButtonDisabled = params['backButtonDisabled'];
      this.newProfileId = parseInt(params['newProfileId']);
    });
    this.generateProfilesView();
  }

  ngOnDestroy() {
    clearInterval(this.autoInterval);
  }

  async generateProfilesView() {
    let profiles = await this.evelinService.getClientProfiles();
    this.profiles = profiles.filter(profile => profile.active).sort((a: ClientProfile, b: ClientProfile) => b.profile_name === "Kids" ? -1 : 0);

    if (!this.profiles.filter(x => x.type == "normal" || x.type == "adult").length) {
      this.router.navigate(['/create-profile']);
    }

    this.profiles.push({
      id: -1,
      active: true,
      pin: "",
      profile_name: "Novi Profil",
      type: "New",
      avatar: 9,
      master: false
    });

    this.loadingVisible = false;

    setTimeout(() => {
      if (this.newProfileId)
        this.currentProfileIndex = this.profiles.findIndex(p => p.id === this.newProfileId);

      this.currentProfileIndex = this.currentProfileIndex || 0;
      this.selectCard(this.currentProfileIndex);

      this.showLoader = false;
    }, 200);

    this.autoInterval = setInterval(() => {
      this.autoSelect++;
      if (this.autoSelect > 20) {
        clearInterval(this.autoInterval);
        this.onSubmit();
      }
    }, 1000);
  }

  selectCard(index: number) {
    if (index < this.profiles.length - 1)
      this.profile = this.profiles[index];

    this.profileCards.nativeElement.children[0].children[index].focus();
    this.autoSelect = 0;
  }

  goBack() {
    clearInterval(this.autoInterval);

    // SASA CHECK AND FIX, this constant is no longer present and is not required for loggedIn check
    // if (this.backButtonDisabled)
    //   localStorage.removeItem(Constants.localStoragePassword);

    this.location.back();
  }

  onSubmit() {
    this.appInfo.setCurrentProfile(this.profile);
    this.router.navigate(["/caching"]);
  }

  async onDelete() {
    this.showLoader = true;
    const { id } = this.profiles[this.currentProfileIndex];
    await this.evelinService.deleteClientProfile(id);
    this.currentProfileIndex = 0;
    clearInterval(this.autoInterval);
    this.generateProfilesView();
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Down':
        this.confirmBtn.focus();
        break;
      case 'BrowserBack':
        this.router.navigate(['/login']);
        break;
      case 'Left':
        this.moveFocus(-1);
        break;
      case 'Right':
        this.moveFocus(1);
        break;
      case 'Enter':
        if (this.currentProfileIndex === this.profiles.length - 1) {
          clearInterval(this.autoInterval);
          this.router.navigate(['/create-profile']);
        }
        else
          this.confirmBtn.focus();
        break;
      default:
        break;
    }
  }

  onConfirmKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Up':
        this.selectCard(this.currentProfileIndex);
        break;
      case 'Left':
        this.backBtn.focus();
        break;
      case 'Enter':
        this.onSubmit();
        break;
      default:
        break;
    }
  }

  onDeleteKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Up':
        this.selectCard(this.currentProfileIndex);
        break;
      case 'Right':
        this.editBtn.focus();
        break;
      case 'Enter':
        this.onDelete();
        break;
      default:
        break;
    }
  }

  onBackKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Up':
        this.selectCard(this.currentProfileIndex);
        break;
      case 'Left':
        this.editBtn.focus();
        break;
      case 'Right':
        this.confirmBtn.focus();
        break;
      case 'Enter':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onEditKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Up':
        this.selectCard(this.currentProfileIndex);
        break;
      case 'Left':
        if (!this.profile.master && this.profile.type !== "Kids")
          this.deleteBtn.focus();
        break;
      case 'Right':
        this.backBtn.focus();
        break;
      case 'Enter':
        const { id } = this.profiles[this.currentProfileIndex];
        this.router.navigate(['/edit-profile', id]);
        break;
      default:
        break;
    }
  }


  moveFocus = (step: number) => {
    if (
      (this.currentProfileIndex === this.profiles.length - 1 && step > 0) ||
      (this.currentProfileIndex === 0 && step < 0)
    )
      return;

    if (step > 0) {
      this.currentProfileIndex++;
    } else if (step < 0) {
      this.currentProfileIndex--;
    } else if (step == 0) {
      this.currentProfileIndex = 0;
    }

    this.selectCard(this.currentProfileIndex);
  }

}
