<div [class]="selectedTheme">
  <div class="background">

    <h2>{{ "settings.catgeory-hiding.title2" | translate }}</h2>
    <p>{{ "settings.catgeory-hiding.choose-category" | translate }}</p>

    <div class="row">
      <button #channelButton mat-button class="button picker" (keydown)="onPickerKeydown($event, 0)"
        (click)="confirm()">
        {{ "tv-channels" | translate }}
      </button>
      <button #moviesButton mat-button class="button picker" (keydown)="onPickerKeydown($event, 1)" (click)="confirm()">
        {{ "movies" | translate }}
      </button>
      <button #showsButton mat-button class="button picker" (keydown)="onPickerKeydown($event, 2)" (click)="confirm()">
        {{ "tv-shows" | translate }}
      </button>
    </div>

    <p>{{ "settings.catgeory-hiding.locked-categories" | translate }}</p>

    <div class="row">
      <div class="col-lg-8">
        <app-pc-minor-category-list #tvChannelCategoriesList [minorCategories]="tvChannelCategories"
          [hidden]="!showTvChannelsList" [lockedMinorCategories]="lockedTvChannelCategories"
          (selectedMinorCategoriesChange)="tvChannelsLockedCategoriesChanged($event)"
          (leaveComponent)="focusConfirm()"></app-pc-minor-category-list>
        <app-pc-minor-category-list #tvShowCategoriesList [minorCategories]="tvShowCategories" [hidden]="!showTvShowsList"
          [lockedMinorCategories]="lockedTvShowCategories"
          (selectedMinorCategoriesChange)="tvShowsLockedCategoriesChanged($event)"
          (leaveComponent)="focusConfirm()"></app-pc-minor-category-list>
        <app-pc-minor-category-list #movieCategoriesList [minorCategories]="movieCategories" [hidden]="!showMoviesList"
          [lockedMinorCategories]="lockedMovieCategories"
          (selectedMinorCategoriesChange)="moviesLockedCategoriesChanged($event)"
          (leaveComponent)="focusConfirm()"></app-pc-minor-category-list>
      </div>

      <div class="col-lg-4">
        <button #confirmButton mat-button class="button" (keydown)="onKeydown($event)" (click)="confirm()">
          {{ "settings.confirm" | translate }}
        </button>

        <button #backButton mat-button class="button" (keydown)="onKeydown($event)" (click)="goBack()">
          {{ "settings.back" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>