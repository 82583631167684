<div [class]="theme">
    <div class="background">
        <div class="my-overlay" *ngIf="loadingVisible">
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
        <div #cardsContainer class="selection-container">
            <div class="cards-container row">
                <div *ngFor="let profile of profiles;let i = index" class="home-button col-md-3" tabindex="{{10 + i}}"
                    (click)="selectCard(i)" (keydown)="onKeydown($event)">
                    <div class="card" [ngClass]="{'cardSelected': i === currentProfileIndex}">
                        <mat-progress-spinner *ngIf="i === currentProfileIndex && (i !== profiles.length - 1)" class="auto-spinner" diameter="150"
                            mode="determinate" value="{{autoSelect * 5}}" color="accent"></mat-progress-spinner>
                        <img src="{{'assets/images/profile_avatar/ic_avatar_' + profile.avatar + '.png'}}"
                            height="130px" width="130px" />
                        <p>{{profile?.profile_name}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons-container">
            <button #deleteBtn mat-button type="button" class="button" (click)="onDelete()"
                (keydown)="onDeleteKeydown($event)" mat-raised-button [ngClass]="{'disabled':(profile?.master || profile.type === 'kids')}">
                {{ "settings.delete" | translate }}
            </button>
            <button #editBtn mat-button type="button" class="button" (click)="goBack()"
                (keydown)="onEditKeydown($event)" mat-raised-button>
                {{ "settings.edit" | translate }}
            </button>
            <button #backBtn mat-button type="button" class="button" (click)="goBack()"
                (keydown)="onBackKeydown($event)" mat-raised-button>
                {{ "settings.back" | translate }}
            </button>
            <button #confirmBtn mat-button type="button" class="button" (click)="onSubmit()"
                (keydown)="onConfirmKeydown($event)" mat-raised-button>
                {{ "login.submit" | translate }}
            </button>
        </div>
    </div>
</div>
<div class="full" *ngIf="showLoader">
    <div class="center lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>