<div [class]="selectedTheme">
  <div class="background">
    <div class="content">
      <h2 class="title">
        {{ "settings.parental-controls-settings" | translate }}
      </h2>

      <mat-label>{{ "settings.current-pin" | translate }}</mat-label>
      <input
        #currentPin
        (keydown)="onKeydownCurrentPin($event)"
        class="input matInput"
        placeholder="0000"
      />

      <mat-label>{{ "settings.new-pin" | translate }}</mat-label>
      <input
        #newPin
        (keydown)="onKeydownNewPin($event)"
        class="input matInput"
        placeholder="0000"
      />

      <button
        #buttonSave
        mat-button
        (click)="save()"
        (keydown)="onKeydownButton($event)"
        class="button mat-button mat-dialog-close cdkFocusInitial"
      >
        {{ "settings.save" | translate }}
      </button>
    </div>
  </div>
</div>
