import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { OceanBlueHeaderComponent } from '../ocean-blue-header/ocean-blue-header.component';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-ocean-blue-list',
  templateUrl: './ocean-blue-list.component.html',
  styleUrls: ['./ocean-blue-list.component.css'],
})

export class OceanBlueListComponent {

  @ViewChild('cardContainer') cardContainer: ElementRef;
  @ViewChild(OceanBlueHeaderComponent) header: OceanBlueHeaderComponent;

  @Input() title: string = "";
  @Input() items: any[];
  @Input() shortList: boolean;
  @Output() onItemClick = new EventEmitter<boolean>();

  currentCardIndex: number = 0;
  allItemsPointer: number = 0;

  constructor(
    public router: Router,
    public appInfoService: AppInfoService,
    public evelinService: EvelinService,
    public translate: TranslateService,
    private platformService: BasePlatformService,
    private _location: Location,
    public matDialog: MatDialog
  ) { }

  focusCard(index) {
    this.cardContainer.nativeElement.children[index].focus();
  }

  itemClicked(item: any) {
    this.onItemClick.emit(item)
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Up':
        if (this.currentCardIndex > 0) {
          this.currentCardIndex--;
          this.focusCard(this.currentCardIndex);
        } else {
          this.header.focus(0);
        }
        break;
      case 'Down':
        if (this.currentCardIndex < this.items.length - 1)
          this.currentCardIndex++;
        else
          this.currentCardIndex = 0;
        this.focusCard(this.currentCardIndex);
        break;
      case 'Enter':
        this.itemClicked(this.currentCardIndex);
        break;
      case 'BrowserBack':
        this.itemClicked(-1);
        break;
      case 'Red':
      case 'ColorF0Red':
        this.setFavorite(this.items[this.currentCardIndex]);
        break;
      default:
        break;
    }

  }

  onHeaderClick(e: any) {
    console.log(e);
    if (e === -1)
      this.goBack();
    else if (e === 0) {
      this.currentCardIndex = 0;
      this.focusCard(this.currentCardIndex);
    } else if (e === 1) {
      this.currentCardIndex = 0;
      this.focusCard(this.currentCardIndex);
    }
  }

  goBack() {
    this.itemClicked(-1);
  }

  idForShortEpgInChannelList(id: any) {
    if (!id) return;
    return parseInt(id);
  }

  setFavorite(item) {
    item.favourite = !item.favourite;
    let favsCategory = this.evelinService.generateFavuoritesCategory();
    this.appInfoService.appendFavouritesCategory(favsCategory);
    this.evelinService.toggleFavourite(item.id);
  }

  setFocus(index: number | null = null) {
    if (index !== null)
      this.currentCardIndex = index;
    this.focusCard(this.currentCardIndex);
  }

}