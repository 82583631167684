export class Stream {
  id: number;
  name: string;
  category_id: number;
  icon: string;
  url: string;
}

export class LiveStream extends Stream {
  added: number;
  archive: string;
  favourite: boolean;
  epg_name: string;
}

export class TVShowStream extends Stream {
  cover: string;
  backdrop_path: string;
  youtube_trailer: string;
  released: Date;
  added: number;
  continue_watching: any;
  cast: string;
  plot: string;
  rating: number;
}

export class MovieStream extends Stream {
  added: number;
  backdrop_path: string;
  cover_big: string;
  epg_name: string;
  length_milliseconds: number;
  released: Date;
  movie_image: string;
  archive: string;
  favourite: boolean;
  cast: string;
  director: string;
  description: string;
  genre: string;
  rating: number;  
  continue_watching: any;
}

export class RadioStream extends LiveStream {
  archive: string;
  favourite: boolean;
  epg_name: string;
}

export class StreamFavoriteToggleReq {
  stream_id: number;
}