import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeStbExtremeComponent } from './components/stb-extreme/home-stb-extreme/home-stb-extreme.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { LoginComponent } from './components/login/login.component';
import { StbExtremeItemsListComponent } from './components/stb-extreme/stb-extreme-items-list/stb-extreme-items-list.component';
import { LandingComponent } from './components/landing/landing.component';
import { ThemeSelectionComponent } from './components/theme-selection/theme-selection.component';
import { HomeFernComponent } from './components/fern/home-fern/home-fern.component';
import { CachingComponent } from './components/caching/caching.component';
import { StbExtremeStreamInfoComponent } from './components/stb-extreme/stb-extreme-stream-info/stb-extreme-stream-info.component';
import { StbExtremeTvArchiveChannelListComponent } from './components/stb-extreme/stb-extreme-tv-archive-channel-list/stb-extreme-tv-archive-channel-list.component';
import { StbExtremeTvArhiveDetailsComponent } from './components/stb-extreme/stb-extreme-tv-archive-details/stb-extreme-tv-arhive-details.component';
import { MultiEpgComponent } from './components/multi-epg/multi-epg.component';
import { EpgInfoComponent } from './components/epg-info/epg-info.component';
import { AuthGuardService } from './services/auth-guard.service';
import { StbExtremeTvShowSeasonsListComponent } from './components/stb-extreme/stb-extreme-tv-show-seasons-list/stb-extreme-tv-show-seasons-list.component';
import { StbExtremeTvShowEpisodesListComponent } from './components/stb-extreme/stb-extreme-tv-show-episodes-list/stb-extreme-tv-show-episodes-list.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ChangeLanguageComponent } from './components/settings/change-language/change-language.component';
import { ParentalControlsComponent } from './components/settings/parental-controls/parental-controls.component';
import { CategoryHidingComponent } from './components/settings/category-hiding/category-hiding.component';
import { ChangePinComponent } from './components/settings/change-pin/change-pin.component';
import { UserInfoComponent } from './components/settings/user-info/user-info.component';
import { ChangeDnsComponent } from './components/settings/change-dns/change-dns.component';
import { SpeedTestComponent } from './components/settings/speed-test/speed-test.component';
import { PlayerSettingsComponent } from './components/settings/player-settings/player-settings.component';
import { CreateProfileComponent } from './components/create-profile/create-profile.component';
import { SelectProfileComponent } from './components/select-profile/select-profile.component';
import { HomeOceanBlueComponent } from './components/ocean-blue/home-ocean-blue/home-ocean-blue.component';
import { LicenceComponent } from './components/licence/licence.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { RadioComponent } from './components/radio/radio.component';
import { StbExtremeMovieViewComponent } from './components/stb-extreme/stb-extreme-movie-view/stb-extreme-movie-view.component';
import { TVChannelsOceanBlueComponent } from './components/ocean-blue/tv-channels-ocean-blue/tv-channels-ocean-blue.component';
import { MoviesOceanBlueComponent } from './components/ocean-blue/movies-ocean-blue/movies-ocean-blue.component';
import { TvShowsOceanBlueComponent } from './components/ocean-blue/tv-shows-ocean-blue/tv-shows-ocean-blue.component';
import { RadioOceanBlueComponent } from './components/ocean-blue/radio-ocean-blue/radio-ocean-blue.component';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingComponent },
  {
    path: 'home-stb',
    component: HomeStbExtremeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'home-fern',
    component: HomeFernComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'language-select',
    component: LanguageSelectComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'change-user',
    component: LoginComponent
  },
  {
    path: 'stb-extreme-items-list/:type/:category',
    component: StbExtremeItemsListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stb-extreme-items-list/:type/:category/:streamId/:continue',
    component: StbExtremeItemsListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stb-extreme-tv-show-seasons-list/:streamId',
    component: StbExtremeTvShowSeasonsListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stb-extreme-tv-show-episodes-list/:season/:streamId',
    component: StbExtremeTvShowEpisodesListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'caching',
    component: CachingComponent
  },
  {
    path: 'theme-selection',
    component: ThemeSelectionComponent
  },
  {
    path: 'stb-extreme-stream-info/:categorytype/:streamid',
    component: StbExtremeStreamInfoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stb-extreme-tv-archive/:category',
    component: StbExtremeTvArchiveChannelListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stb-extreme-tv-archive-details/:streamId',
    component: StbExtremeTvArhiveDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'multi-epg',
    component: MultiEpgComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'multi-epg/:category/:channelId',
    component: MultiEpgComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'epg-info/:epgId',
    component: EpgInfoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'radio/:category',
    component: RadioComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'change-language',
    component: ChangeLanguageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'parental-controls',
    component: ParentalControlsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'category-hiding/:backButtonDisabled2',
    component: CategoryHidingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'category-hiding/:backButtonDisabled2/:newProfileId',
    component: CategoryHidingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'change-pin',
    component: ChangePinComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'user-info',
    component: UserInfoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'change-dns',
    component: ChangeDnsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'speed-test',
    component: SpeedTestComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'player-settings',
    component: PlayerSettingsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'create-profile',
    component: CreateProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'select-profile/:backButtonDisabled/:newProfileId',
    component: SelectProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'edit-profile/:profileId',
    component: EditProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'licence',
    component: LicenceComponent
  },
  {
    path: 'stb-extreme-movie-view/:movieId',
    component: StbExtremeMovieViewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'home-ocean-blue',
    component: HomeOceanBlueComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'tv-channels-ocean-blue',
    component: TVChannelsOceanBlueComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'movies-ocean-blue',
    component: MoviesOceanBlueComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'tv-shows-ocean-blue',
    component: TvShowsOceanBlueComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'radio-ocean-blue',
    component: RadioOceanBlueComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
