export class Login {
  user_info?: UserInfo;
  server_info?: ServerInfo;
}

export class UserInfo {
  username?: string;
  password?: string;
  message?: string;
  auth?: number;
  status?: string; 
  exp_date?: string; 
  is_trial?: string; 
  active_cons?: string; 
  created_at?: string; 
  max_connections?: number;
  allowed_output_formats?: Array<string>;
}

export class UserInfoV2 {
  username?: string;
  password?: string;
  message?: string;
  exp_date: string | null; 
  is_trial: number; 
  active_cons: number; 
  created_at: string;
  max_connection: number;
  allowed_output_formats: Array<string>;
  profiles: Array<any>;
  status: string;
}

export class ServerInfo {
  url?: string;
  port?: string; // Needs converting to number
  https_port?: string; // Needs converting to number
  server_protocol?: string;
  rtmp_port?: string; // Needs converting to number
  timezone?: string;
  timestamp_now?: number; // Unix timestamp
  time_now?: Date;
}

export class LoginV2 {
  username: string;
  password: string;
  device_id: string;

  constructor()
  constructor(username: string, password: string, device_id: string)
  constructor(...myarray: any[]) {
    if (myarray.length == 3) {
      this.username = myarray[0];
      this.password = myarray[1];
      this.device_id = myarray[2];
    }
  }
}

export class TokenResponseV2 {
  token: string;
}

export class PasswordlessLoginReq {
  app_id: string;
  device_id: string;

  constructor(app_id: string, device_id: string) {
    this.app_id = app_id;
    this.device_id = device_id;
  }
}