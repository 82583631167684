import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { AppInfoService } from '../../../services/app-info.service';
import { Location } from '@angular/common';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { MovieInfo } from '../../../models/filminfo.model';
import { MatButton } from '@angular/material/button';
import { ThemeHelper } from '../../../helpers/theme.helper';
import { Theme } from '../../../models/theme.model';
import { PlayerComponent } from '../../player/player.component';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-stb-extreme-movie-view',
  templateUrl: './stb-extreme-movie-view.component.html',
  styleUrls: ['./stb-extreme-movie-view.component.css'],
})
export class StbExtremeMovieViewComponent implements OnInit {

  @ViewChild('trailerButton') trailerButton: MatButton;
  @ViewChild('playButton') playButton: MatButton;
  @ViewChild('backButton') backButton: MatButton;
  @ViewChild(PlayerComponent) player: PlayerComponent;
  @ViewChild('description') description: ElementRef;

  selectedTheme: string;
  url: string;

  public movie: any = {};
  public details: any = {};
  public movieId: number;

  constructor(
    private route: ActivatedRoute,
    private evelinService: EvelinService,
    private appInfoService: AppInfoService,
    private platformService: BasePlatformService,
    public dialog: MatDialog,
    private location: Location
  ) {    
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(selectedTheme as Theme);
   }

  async ngOnInit(): Promise<void> {

    this.route.params.subscribe(async (params: Params) => {
      if (params['movieId'])
        this.movieId = parseInt(params['movieId']);

      this.movie = await this.evelinService.getMovieInfoById(this.movieId);
      this.details = this.movie.movie_properties;
      console.log(this.movie);
      console.log(this.details);

      this.playButton.focus();
    });
  }

  onKeydownPlay(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    // console.log(key);
    switch (key) {
      case 'Enter':
        this.url = this.movie.url;
        this.player.setFullScreen();
        break;
      case 'Right':
        this.backButton.focus();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Up':
        this.moveDescription(-1);
        break;
      case 'Down':
        this.moveDescription(1);
        break;
      default:
        break;
    }
  }

  onKeydownBack(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    // console.log(key);
    switch (key) {
      case 'Enter':
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Left':
        this.playButton.focus();
        break;
      case 'Up':
        this.moveDescription(-1);
        break;
      case 'Down':
        this.moveDescription(1);
        break;
      default:
        break;
    }
  }

  moveDescription(step) {
    this.description.nativeElement.scrollBy({
      top: 100 * step,
      behavior: "smooth",
    });
  }

  closeFullSreen() {
    console.log('close');
    setTimeout(() => {
      this.playButton.focus();
    }, 50);
  }

  goBack() {
    this.location.back();
  }
}