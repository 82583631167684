import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelper } from './date-time.helper';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
    transform(dateString: string): string {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
      
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
}
