<div [class]="selectedTheme">
  <div class="background">
    <h1 mat-dialog-title>
      <!-- <mat-icon class="icon-sort">sort</mat-icon> -->
      <span class="mat-dialog-title">Unesite PIN</span>
    </h1>
    <div class="actions">
      <div class="buttons">
        <input
          #pinInput
          type="password"
          name="pin"
          id="pin"
          class="form-group-input"
          (keydown)="onKeydownPin($event)"
          [(ngModel)]="pin"
          placeholder="PIN"
        />
        <p *ngIf="showError" style="color: red;">Neispravan PIN</p>
      </div>
    </div>
    <div class="actions">
      <div class="buttons">
        <button
          #okBtn
          mat-button
          class="button mat-button mat-dialog-close cdkFocusInitial"
          (click)="checkPin()"
          (keydown)="onKeydownButtonOk($event)"
        >
          OK
        </button>

        <button
          #backBtn
          mat-button
          class="button mat-button mat-dialog-close cdkFocusInitial"
          (click)="close(false)"
          (keydown)="onKeydownButtonClose($event)"
        >
          Nazad
        </button>
      </div>
    </div>
  </div>
</div>
