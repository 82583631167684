<div #page>
  <div class="background">
    <div class="conteiner">
      <div class="row">
        <div class="center">
          <img
            src="assets/images/onboarding/fernTV-logo-RGB-White.png"
            class="logo-icon"
          />
          <label class="title"
            >{{ "landing.title" | translate }}: {{ appVersion }}</label
          >
          <label class="title-desc">{{
            "landing.title-desc" | translate
          }}</label>
          <!--<mat-spinner></mat-spinner>-->
          <div class="progress-bar-full progress-bar-thick">
            <mat-progress-bar
              mode="determinate"
              value="{{ progress }}"
            ></mat-progress-bar>
            <div class="progress-text">{{ this.progress }}%</div>
          </div>

          <div class="buttons">
            <button
              #buttonReload
              mat-button
              class="button"
              (keydown)="onKeydownButtonReload($event)"
              (click)="onClickButtonReload()"
            >
              {{ "landing.buttons.reload" | translate }}
            </button>
            <button
              #buttonProfile
              mat-button
              class="button"
              (keydown)="onKeydownButtonProfile($event)"
              (click)="onClickButtonProfile()"
            >
              {{ "select-profile" | translate }}
            </button>
            <button
              #buttonExit
              mat-button
              class="button"
              (keydown)="onKeydownButtonExit($event)"
              (click)="openExitDialog()"
            >
              {{ "landing.buttons.exit" | translate }}
            </button>
          </div>

          <div class="authoring-note-container">
            <div class="authoring-note">
              Please note that "FernTV" is an application to establish a
              connection between you and your IPTV provider. We do not deliver,
              broadcast or transmit TV channels, TV portals or playlists. FernTV
              does not provide for any unauthorized use of third-party material
              protected by copyright and distances itself from it in all forms.
            </div>
            <div class="authoring-note">
              Bitte beachten Sie, dass "FernTV" eine Anwendung ist, um eine
              Verbindung zwischen Ihnen und Ihrem IPTV-Anbieter herzustellen.
              Wir liefern, senden, oder iThertragen keine TV-Kanale, TV Portale
              oder Wiedergabelisten. FernTV sieht keine unbefugte Verwendung von
              urheberrechtlich geschiitztem Materialien Dritter vor und
              distanziert in alter Form davon.
            </div>
            <div class="authoring-note">
              Imajte na umu da je "FernTV" aplikacija za uspostavljanje veze
              između vas i vašeg IPTV-a davatelja. Ne isporučujemo, ne emitiramo
              i ne prenosimo TV kanale, TV portale ili popise za reprodukciju.
              FernTV ne predviđa neovlaštenu uporabu materijala treće strane
              zaštićene autorskim pravima i udaljeljava se od tog čina u svim
              oblicima.
            </div>
          </div>
        </div>
        <div class="synch">
          <label class="synch-title">{{
            "landing.synch.title" | translate
          }}</label>
          <label class="synch-status">{{
            "landing.synch.status" | translate
          }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
