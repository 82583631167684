import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInfoService } from '../../services/app-info.service';
import { TranslateService } from '@ngx-translate/core';
import { StreamOptionType } from '../../models/category.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ContinuePlayingDialogComponent } from '../continue-playing-dialog/continue-playing-dialog.component';
import { Stream } from '../../models/stream.model';

@Component({
  selector: 'app-home-base',
  templateUrl: './home-base.component.html',
  styleUrls: ['./home-base.component.css'],
})
export abstract class HomeBaseComponent implements OnInit {
  private continuePlayingState: any;

  constructor(
    public router: Router,
    public appInfo: AppInfoService,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {
    this.continuePlayingState = this.appInfo.getPlayingComponentState();
    if (this.continuePlayingState !== false) {
      let continueCategory = this.continuePlayingState.category;
      let continueStreamId = this.continuePlayingState.streamId;
      let channel = this.appInfo.getCachedStreamByStreamId(
        continueStreamId,
        StreamOptionType.TVChannels
      );
      let continueType = StreamOptionType.TVChannels;

      this.toggleContinuePlayingDialog(
        continueType,
        continueCategory,
        continueStreamId,
        channel
      );
    }
  }

  toggleContinuePlayingDialog(
    continueType: StreamOptionType,
    continueCategory: any,
    continueStreamId: any,
    channel: Stream
  ) {
    console.log("Showing dialog...")
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '900px';
    dialogConfig.height = '400px';
    dialogConfig.data = { channel: channel };
    const dialogRef = this.dialog.open(
      ContinuePlayingDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((value) => {
      if (value == true) {
        this.router.navigate([
          '/stb-extreme-items-list',
          continueType,
          continueCategory,
          continueStreamId,
          'true',
        ]);
      }
    });
  }

  ngOnInit(): void {}
}
