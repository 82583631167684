import { Pipe, PipeTransform } from '@angular/core';
import { AppInfoService } from '../services/app-info.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'boolstring' })
export class BooleanToStringPipe implements PipeTransform {
    constructor(public appInfoService: AppInfoService) { }

    transform(input: boolean): string {
        let lang = this.appInfoService.getLanguage;

        if (!input) {
            switch (lang) {
                case "hr":
                    return "Ne";
                    break;
                case "en":
                    return "No";
                    break;
                case "de":
                    return "Nein";
                    break;
            }
        }

        switch (lang) {
            case "hr":
                return "Da";
                break;
            case "en":
                return "Yes";
                break;
            case "de":
                return "Ja";
                break;
        }
    }
}
