import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppInfoService } from '../../../services/app-info.service';
import { StreamOptionType } from '../../../models/category.model';
import { MovieInfo } from '../../../models/filminfo.model';
import { TvShowInfo } from '../../../models/serieinfo.model';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-stb-extreme-stream-info',
  templateUrl: './stb-extreme-stream-info.component.html',
  styleUrls: ['./stb-extreme-stream-info.component.css']
})
export class StbExtremeStreamInfoComponent implements OnInit {
  streamId: number;
  streamType: StreamOptionType;
  selectedStreamInfo: TvShowInfo | MovieInfo

  constructor(private route: ActivatedRoute, private evelinService: EvelinService, private appInfoService: AppInfoService) {
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params: Params) => {
      this.streamId = params['streamid'];
      this.streamType = params['categorytype'];
    });

    if (this.streamType == StreamOptionType.Movies) {
      this.selectedStreamInfo = await this.evelinService.getMovieInfoById(this.streamId);
    }

    if (this.streamType == StreamOptionType.TVShows) {
      this.selectedStreamInfo = await this.evelinService.getTvShowInfoById(this.streamId);
    }
  }

  getYear() {
    if (this.streamType == StreamOptionType.Movies) {
      let releaseDate = new Date((this.selectedStreamInfo as MovieInfo).info?.releasedate as any);
      return releaseDate.getFullYear();
    }

    if (this.streamType == StreamOptionType.TVShows) {
      let releaseDate = new Date((this.selectedStreamInfo as TvShowInfo).info?.releaseDate as any);
      return releaseDate.getFullYear();
    }

    return "----"
  }

  getStreamInfoImage() {
    if (this.streamType == StreamOptionType.Movies) {
      return (this.selectedStreamInfo as MovieInfo).info?.cover_big;
    }

    if (this.streamType == StreamOptionType.TVShows) {
      return (this.selectedStreamInfo as TvShowInfo).info?.cover;
    }

    return "";
  }
}
