<div [class]="selectedTheme">
    <div class="background">
        <div class="content">
            <mat-radio-group aria-label="Select an option" class="radio-group">
                <span class="title">{{ "settings.change-dns" | translate }}</span>
                <mat-radio-button #firstRadioButtonElement value="1" [checked]="selectedDns == '1'"
                    [disabled]="!dns1Available" (change)="radioChange('1')" (keydown)="onKeydown($event, false)">HQ dns
                    </mat-radio-button>
                <mat-radio-button value="2" [checked]="selectedDns == '2'" (change)="radioChange('2')"
                    [disabled]="!dns2Available" (keydown)="onKeydown($event, false)">LQ dns</mat-radio-button>
            </mat-radio-group>
            <button #buttonSave mat-button class="button picker" (click)="confirm()" (keydown)="onKeydownButton($event)"
                class="button mat-button mat-dialog-close cdkFocusInitial">
                {{ "settings.save" | translate }}
            </button>
        </div>

    </div>
</div>