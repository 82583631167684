<div [class]="selectedTheme">
  <div class="background">
    <h1 mat-dialog-title>
      <!-- <mat-icon class="icon-sort">sort</mat-icon> -->
      <span class="mat-dialog-title"
        >Nastavi gledati {{ inputValue.name }}?</span
      >
    </h1>
    <div class="actions">
      <div class="buttons">
        <button
          #continuePlayDialogButtonOk
          class="button mat-button mat-dialog-close cdkFocusInitial"
          (click)="close(true)"
          (keydown)="onKeydownButtonOk($event)"
        >
          Da
        </button>

        <button
          #continuePlayDialogButtonClose
          class="button mat-button mat-dialog-close cdkFocusInitial"
          (click)="close(false)"
          (keydown)="onKeydownButtonClose($event)"
        >
          Ne
        </button>
      </div>
    </div>
  </div>
</div>
