import { Guid } from "./guid";

export class LogEntry {
  id = Guid.MakeNew().ToString();
  entryDate: Date = new Date();
  message: string = "";
  level: LogLevel = LogLevel.Debug;
  extraInfo: string = "";

  buildLogString(): string {
    let ret: string = "";

    ret = new Date() + " - ";

    ret += "Type: " + LogLevel[this.level];
    ret += " - Message: " + this.message;
    if (this.extraInfo.length) {
      ret += " - Extra Info: " + this.extraInfo;
    }

    return ret;
  }
}

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}