<div class="conteiner" [class]="selectedTheme">
  <div class="row">
    <div class="col-lg-8 background">
      <div class="center">
        <img
          src="assets/images/onboarding/theme_icon.png"
          class="theme-icon"
        />
        <label class="title">{{ "theme.choose-theme-desc" | translate }}</label>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="buttons">
        <label class="buttons-title">{{ "theme.choose-theme" | translate }}</label>
        <button
        #buttonStbExtreme
        mat-button
        class="button"
        (click)="confirm('stb-extreme')"
        (keydown)="onKeydownButtonStbExtreme($event, 'stb-extreme')"
        (focus)="themePreview('stb-extreme')"
      >
        STB Extreme
      </button>
      <button
        #buttonOceanBlue
        mat-button
        class="button"
        (click)="confirm('ocean-blue')"
        (keydown)="onKeydownButtonOceanBlue($event, 'ocean-blue')"
        (focus)="themePreview('ocean-blue')"
      >
        Ocean Blue
      </button>
      <!--<button
        #buttonFern
        mat-button
        class="button"
        (click)="confirm('fern')"
        (keydown)="onKeydownButtonFern($event, 'fern')"
        (focus)="themePreview('fern')"
      >
        Fern
      </button>-->
      </div>
  </div>
</div>
