import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Login, UserInfoV2 } from '../../../models/login.model';
import { AppInfoService } from '../../../services/app-info.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';
 
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
})
export class UserInfoComponent implements OnInit {
  @ViewChild('buttonBack') buttonBack: MatButton;
  constructor(
    private router: Router,
    private appInfo: AppInfoService,
    private translate: TranslateService,
    public appInfoService: AppInfoService,
    private platformService: BasePlatformService
  ) {}

  public userInfo: UserInfoV2;
  expiryDate: Date;
  setupDate: Date;
  trialAccount: string;

  selectedTheme: string;
  ngOnInit(): void {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );

    this.userInfo = this.appInfo.getUserInfoV2;
    if(this.userInfo.exp_date != null) {
      this.expiryDate = new Date(
        parseInt(this.userInfo.exp_date as string) * 1000
      );
    } else {
      this.expiryDate = new Date(2100, 1, 1, 1, 1, 1, 1);
    }
    this.setupDate = new Date(
      parseInt(this.userInfo.created_at as string) * 1000
    );
    // this.trialAccount = this.getYesNoTranslation(
    //   this.userInfo.is_trial as string
    // );
    setTimeout(() => this.buttonBack.focus());
  }
  ngAfterViewInit() {
    this.buttonBack.focus();
  }

  back() {
    this.router.navigate(['/settings']);
  }

  getYesNoTranslation(input: string) {
    if (input == '0') {
      return this.translate.instant('no');
    } else return this.translate.instant('yes');
  }

  onKeydownButton(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Enter':
      case 'BrowserBack':
        console.log('back');
        this.back();
        break;
      default:
        break;
    }
  }
}
