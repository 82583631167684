import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';

import { AppInfoService } from 'src/app/services/app-info.service';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.css'],
})
export class ChangePinComponent implements OnInit {
  @ViewChild('buttonSave') buttonSave: MatButton;
  @ViewChild('currentPin') currentPin: ElementRef;
  @ViewChild('newPin') newPin: ElementRef;

  constructor(
    private router: Router,
    public appInfoService: AppInfoService,
    private platformService: BasePlatformService
  ) { }
  selectedTheme: string;
  ngOnInit(): void {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
  }
  ngAfterViewInit() {
    this.currentPin.nativeElement.focus();
  }

  save() {
    this.router.navigate(['/settings']);
  }

  back() {
    this.router.navigate(['/settings']);
  }
  onKeydownCurrentPin(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        this.newPin.nativeElement.focus();
        break;
      case 'BrowserBack':
        this.back();
        break;

      default:
        break;
    }
  }
  onKeydownNewPin(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.currentPin.nativeElement.focus();
        break;
      case 'Down':
        this.buttonSave.focus();
        break;
      case 'BrowserBack':
        this.back();
        break;

      default:
        break;
    }
  }

  onKeydownButton(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.newPin.nativeElement.focus();
        break;
      case 'Enter':
        this.save();
        break;
      case 'BrowserBack':
        this.back();
        break;

      default:
        break;
    }
  }
}
