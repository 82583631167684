import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Channel } from '../../../models/channel.model';
import { AppInfoService } from '../../../services/app-info.service';
import { Location } from '@angular/common';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { Stream } from '../../../models/stream.model';
import { ThemeHelper } from '../../../helpers/theme.helper';

@Component({
  selector: 'app-stb-extreme-channel-list',
  templateUrl: './stb-extreme-tv-archive-channel-list.component.html',
  styleUrls: ['./stb-extreme-tv-archive-channel-list.component.css']
})
export class StbExtremeTvArchiveChannelListComponent implements OnInit {

  @ViewChildren('channelList') channelList: QueryList<any>;

  currentStep: number = 0;
  currentCategory: string;
  channels: Stream[];
  theme: string;

  constructor(
    private route: ActivatedRoute,
    private appInfoService: AppInfoService,
    private router: Router,
    private location: Location,
    private platformService: BasePlatformService
  ) {
    this.theme = ThemeHelper.transformThemeToString(this.appInfoService.getSelectedTheme()) + " background";
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.currentCategory = params['category'];
    });

    this.regenerateComponent();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.selectItem(0);
    }, 500);
  }

  regenerateComponent() {
    this.channels = this.appInfoService.getCachedTvChannelsByCategory(parseInt(this.currentCategory));
  }


  onChannelClicked(channel: Stream) {
    this.router.navigate(["/stb-extreme-tv-archive-details", channel.id]);
  }

  onKeydown(e: any, channel: Stream, index: number) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    switch (key) {
      case 'Up':
        this.stepToNewItem(-1);
        break;
      case 'Down':
        this.stepToNewItem(1);
        break;
      case 'Enter':
        this.onChannelClicked(channel);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  goBack() {
    this.location.back();
  }


  onKeydownHeaderButtons(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        //this.categoryElements.toArray()[0].nativeElement.children[0].focus();
        break;
      case 'Enter':
        this.goBack();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  stepToNewItem(step: number): void {
    if (this.currentStep === 0 && step < 0)
      return;
    else if (this.currentStep === this.channels.length - 1 && step === 1)
      return;

    this.currentStep += step;
    this.selectItem(this.currentStep);
  }

  selectItem(index: number): void {
    this.channelList.toArray()[index].nativeElement.children[0].focus();
  }

  isTitleItem(item: Stream): boolean {
    return item.name?.includes('▲▼▲▼▲▼') ? true : false;
  }

  formatTitle(item: Stream): string {
    if (!item.name)
      return "";

    return item.name.split("▲").join("").split("▼").join("")
  }

}
