import { Injectable } from '@angular/core';
import { LogConsole, LogLocalStorage, LogPublisher, LogPublisherConfig, LogWebApi } from '../helpers/log-publisher';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const PUBLISHERS_FILE = "assets/log-publishers.json";

@Injectable()
export class LogPublishersService {
  constructor(private http: HttpClient) {
    this.buildPublishers();
  }

  publishers: LogPublisher[] = [];

  buildPublishers(): void {
    let logPub: LogPublisher;

    logPub = new LogWebApi(this.http);
    logPub.location = "http://176.9.242.169/FernLogging/Log";

    this.publishers.push(logPub);
  }
}