<div #videoContainer [ngClass]="{'full-screen':isFullScreen}" id="videoContainer" [tabindex]="1" (keydown)="onKeydown($event)">
    <div #ratioContainer class="ratio-container">
        <video #videoPlayer id="my-video" class="video-js" preload="auto">
        </video>
        <div *ngIf="progressDuration && isFullScreen" class="buffering">
            <app-timed-progress [duration]="progressDuration"></app-timed-progress>
        </div>
    </div>
    <div *ngIf="type === 0 && isFullScreen && info" class="info-div" #infoDiv [tabindex]="1">
        <div class="info-item" *ngFor="let item of info">
            <div>
                {{item.time | date:'HH:mm':'UTC +2'}}
            </div>
            <div>
                {{item.title}}
            </div>
        </div>
        <div class="info-icon" *ngIf="icon">
            <img src="{{ icon }}" class="channel-icon" loading="lazy" />
        </div>
    </div>
    <app-player-controls *ngIf="type !== 0 && type !== 3 && playerState !== 0 && isFullScreen" [playerState]="playerState"
        [duration]="videoplayer.nativeElement.duration" [currentTime]="videoplayer.nativeElement.currentTime"
        (onEvent)="onControlChange($event)" (setTime)="setTime($event)"></app-player-controls>
</div>