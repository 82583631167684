<div class="row" *ngIf="selectedStreamInfo">
  <div class="col-md-9">
    <p>{{ selectedStreamInfo.info?.name }}</p>
    <p>{{ getYear() }}</p>
    <p>{{ selectedStreamInfo.info?.genre }}</p>
    <p>{{ selectedStreamInfo.info?.episode_run_time }}</p>
    <br />
    <p>{{ selectedStreamInfo.info?.director }}</p>
    <p>{{ selectedStreamInfo.info?.cast }}</p>
    <br />
    <p>{{ selectedStreamInfo.info?.plot }}</p>
  </div>
  <div class="col-md-3">
    <img [src]="getStreamInfoImage()" class="streamIconImg"/>
  </div>
</div>
