import { Component, OnInit } from '@angular/core';
import { AppInfoService } from '../../services/app-info.service';
import { Router } from '@angular/router';
import { Theme } from '../../models/theme.model';
import * as Constants from '../../helpers/constants.helper';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  constructor(
    private appInfoService: AppInfoService,
    private router: Router,
    private authService: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    this.appInfoService.setSelectedTheme(Theme.StbExtreme);

    this.appInfoService.invalidateCachedShortEpg();
    await this.authService.generateDeviceInfo();
    let licenceValid = await this.authService.isLicenceValid();

    fetch("http://ipinfo.io/?token=389aa3e780fd82")
      .then(response => response.json())
      .then(data => this.appInfoService.setIpAddress(data));

    if (!this.appInfoService.getLanguage) {
      this.router.navigate(['/language-select']);
    // } else if (this.appInfoService.getSelectedTheme() == null) {
    //   this.router.navigate(['theme-selection']);
    } else if (!licenceValid) {
      this.router.navigate(['/licence']);
    } else if (await this.isLoggedInCheck() == false) {
      this.router.navigate(['/login']);
    } else if (!this.isUserProfileSelected()) {
      this.router.navigate(['select-profile', true, 0]);
    }
    // else if (this.appInfoService.shouldCache() || this.xtreamService.xmlProgrammes.size < 1) {
    else if (this.appInfoService.shouldCache()) {
      this.router.navigate(['/caching']);
    } else {
      let selectedTheme = this.appInfoService.getSelectedTheme();
      switch (selectedTheme) {
        case Theme.StbExtreme:
          this.router.navigate(['/home-stb']);
          break;
        case Theme.OceanBlue:
          this.router.navigate(['/home-ocean-blue']);
          break;
        case Theme.Fern:
          this.router.navigate(['/home-fern']);
          break;
      }
    }
  }

  private isUserProfileSelected(): boolean {
    let profile = this.appInfoService.getCurrentProfile();

    if (profile != null) {
      return true;
    }

    return false;
  }

  async isLoggedInCheck() {
    let tokenExists = this.appInfoService.tokenExists();
    let isTokenValid = false;
    if (tokenExists) {
      isTokenValid = await this.authService.isTokenValid();
    }


    if (tokenExists && isTokenValid) {
      return true;
    }

    return false;
  }
}
