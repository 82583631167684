export const localStorageLanguage = 'fern-language';
export const localStorageDns = 'fern-dns';
export const fullAuthDataV2 = 'fern-auth-data-v2';
export const localStorageToken = 'fern-token';
export const localStorageClientProfile = 'fern-client-profile';
export const localStorageCorrectPinTime = 'fern-pin-time';

export const localStorageHiddenTvChannels = 'fern-hidden-tv-channels';
export const localStorageHiddenTvShows = 'fern-hidden-tv-shows';
export const localStorageHiddenMovies = 'fern-hidden-movies';

export const localStorageDns1 = 'fern-dns1';
export const localStorageDns2 = 'fern-dns2';
export const localStorageDnsFallback = 'fern-dns-fallback';
export const localStorageApiUrl = 'fern-api-url';

export const localStorageSystemValues = 'fern-system-values';

export const tvChannelsCategories = 'fern-tv-channels-categories';
export const tvShowsCategories = 'fern-tv-shows-categories';
export const moviesCategories = 'fern-movies-categories'
export const radioCategories = 'fern-radio-categories'

export const tvChannels = 'fern-tv-channels';
export const tvShows = 'fern-tv-shows';
export const movies = 'fern-movies';
export const radio = 'fern-radio';

export const cacheTime = 'fern-cache-time';
export const localStorageTheme = 'fern-theme';

export const localStorageEpgInfo = 'fern-epg-info';
export const localStorageShortEpgInfo = 'fern-short-epg-';

export const localStorageContinueCategory = 'fern-continue-category';
export const localStorageContinueStreamId = 'fern-continue-streamId';

export const localStoragePlayerSettings = 'fern-player-settings';

export const localStorageDeviceInfo = 'fern-device-info';
export const localStorageIpAddress = 'fern-ip-address';
