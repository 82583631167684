import { Theme } from "../models/theme.model";

export class ThemeHelper {
  static transformToThemeType(theme: string): Theme {
    switch (theme) {
      case "stb-extreme":
        return Theme.StbExtreme;
      case "ocean-blue":
        return Theme.OceanBlue;
      case "fern":
        return Theme.Fern;
      default:
        return Theme.StbExtreme;
    }
  }

  static transformThemeToString(theme: Theme): string {
    switch (theme) {
      case Theme.Fern:
        return "fern";
      case Theme.OceanBlue:
        return "ocean-blue";
      case Theme.StbExtreme:
        return "stb-extreme";
      default:
        return "stb-extreme";
    }
  }
}
