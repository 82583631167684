import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppInfoService } from '../../services/app-info.service';
import { Theme } from '../../models/theme.model';
import { ThemeHelper } from '../../helpers/theme.helper';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { Location } from '@angular/common';
import * as Constants from '../../helpers/constants.helper';

@Component({
  selector: 'app-theme-selection',
  templateUrl: './theme-selection.component.html',
  styleUrls: ['./theme-selection.component.css'],
})
export class ThemeSelectionComponent implements AfterViewInit {
  @ViewChild('buttonStbExtreme', { read: ElementRef, static: true })
  private buttonStbExtreme: ElementRef;
  @ViewChild('buttonOceanBlue', { read: ElementRef, static: true })
  private buttonOceanBlue: ElementRef;
  @ViewChild('buttonFern', { read: ElementRef, static: true })
  private buttonFern: ElementRef;

  public selectedTheme: string = 'stb-extreme';

  constructor(
    private appInfo: AppInfoService,
    private translate: TranslateService,
    private router: Router,
    private platformService: BasePlatformService,
    private location: Location
  ) { }

  ngAfterViewInit() {
    this.setFocus(this.buttonStbExtreme);
  }

  setFocus(element: any) {
    console.log(element);
    const ele = element.nativeElement;
    if (ele) {
      setTimeout(() => {
        ele.focus();
      }, 0);
    }
  }

  confirm(theme: string) {
    this.appInfo.setSelectedTheme(ThemeHelper.transformToThemeType(theme));
    this.router.navigate(['/landing']);
  }

  themePreview(theme: string) {
    this.selectedTheme = theme;
    console.log(this.selectedTheme);
  }

  onKeydownButtonStbExtreme(e: any, theme: string) {
    e.preventDefault()
    let key = this.platformService.keymapper(e);

    switch (key) {
      case 'Down':
        this.setFocus(this.buttonOceanBlue);
        break;
      case 'Enter':
        this.confirm(theme);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownButtonOceanBlue(e: any, theme: string) {
    e.preventDefault()
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        this.setFocus(this.buttonFern);
        break;
      case 'Up':
        this.setFocus(this.buttonStbExtreme);
        break;
      case 'Enter':
        this.confirm(theme);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownButtonFern(e: any, theme: string) {
    e.preventDefault()
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.setFocus(this.buttonOceanBlue);
        break;
      case 'Enter':
        this.confirm(theme);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  goBack() {
    let selectedTheme = this.appInfo.getSelectedTheme();
    console.log(selectedTheme);
    if (selectedTheme === null)
      localStorage.removeItem(Constants.localStorageLanguage);
    this.location.back();
  }
}
