import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { PlayerButtons, PlayerControlEvents, PlayerState } from '../player.models';
import { MatSlider } from '@angular/material/slider';

@Component({
  selector: 'app-player-controls',
  templateUrl: './player-controls.component.html',
  styleUrls: ['./player-controls.component.css'],
})
export class PlayerControlsComponent implements OnInit {
  @ViewChild('controlsContainer') infoDiv: ElementRef;
  @ViewChild('controls') controls: ElementRef;
  @ViewChild('slider') slider: ElementRef;

  @Input() playerState: PlayerState;
  @Input() duration: number;
  @Input() currentTime: number;

  @Output() onEvent = new EventEmitter<PlayerControlEvents>();
  @Output() setTime = new EventEmitter<number>();

  isVisible = false;
  isJumping: boolean = false;

  jumpingInterval: any = null;
  durationInterval: any = null;

  progressFactor = 0.1;
  progress: number = 0;
  accelerator: number = 0;
  timestampStart = "";
  timestampEnd = "";
  timeoutObject: any;

  selectedButton = 1;
  smallScreen = false;
  settings: any;
  autoplaying = false;
  buffering = 0;
  info: any;

  constructor(private platformService: BasePlatformService) { }

  ngOnInit(): void {
    this.durationInterval = setInterval(() => this.calculateDuration(), 1000);
    setTimeout(() => this.controls.nativeElement.focus(), 100);
    this.setHideTimeout();
  }

  ngOnDestroy(): void {
    clearInterval(this.durationInterval);
    clearInterval(this.jumpingInterval);
    this.setHideTimeout();
  }

  temp = "";
  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    this.temp += key + " ";
    this.setHideTimeout();

    switch (key.toString()) {
      case 'Up':
        this.selectProgress();
        break;
      case 'Down':
        this.changeButton(PlayerButtons.PlayPause);
        break;
      case 'BrowserBack':
        this.emitEvent(PlayerControlEvents.Close);
        break;
      case 'Left':
        this.stopJumping();
        if (this.showInfo)
          this.changeButton(-1);
        break;
      case 'Rewind':
      case 'MediaRewind':
        this.jumpTo(-1);
        break;
      case 'FastForward':
      case 'MediaFastForward':
        this.jumpTo(1);
        break;
      case 'Right':
        this.stopJumping();
        if (this.showInfo)
          this.changeButton(1);
        break;
      case 'PlayPause':
        if (this.playerState === PlayerState.Paused)
          this.emitEvent(PlayerControlEvents.Play);
        else if (this.playerState === PlayerState.Playing)
          this.emitEvent(PlayerControlEvents.Pause);
        break;
      case 'Enter':
        this.onClick();
        break;
      case 'Play':
      case 'MediaPlay':
        this.emitEvent(PlayerControlEvents.Play);
        break;
      case 'Pause':
      case 'MediaPause':
        this.emitEvent(PlayerControlEvents.Pause);
        break;
      default:
        break;
    }

    if (key.toString() !== "BrowserBack")
      this.showInfo();
  }

  sliderMove(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Down':
        this.controls.nativeElement.focus();
        this.changeButton(PlayerButtons.PlayPause);
        break;
      case 'Left':
        console.log(this.slider.nativeElement.offsetParent.clientWidth);
        if (this.slider.nativeElement.offsetLeft > 0)
        this.slider.nativeElement.style.left = this.slider.nativeElement.offsetLeft - (this.slider.nativeElement.offsetParent.clientWidth / 200) + "px";
        break;
      case 'Right':
        if (this.slider.nativeElement.offsetLeft < this.slider.nativeElement.offsetParent.clientWidth)
        this.slider.nativeElement.style.left= this.slider.nativeElement.offsetLeft + (this.slider.nativeElement.offsetParent.clientWidth / 200) + "px";
        break;
      case 'Enter':
        console.log(this.currentTime);
        console.log(this.duration);
        
        this.emitTime(this.slider.nativeElement.offsetLeft / this.slider.nativeElement.offsetParent.clientWidth * this.duration);
        this.controls.nativeElement.focus();
        this.changeButton(PlayerButtons.PlayPause);
        break;
      default:
        break;
    }
  }

  selectProgress() {
    this.slider.nativeElement.focus();
  }

  changeButton(step: number) {
    if (this.selectedButton === PlayerButtons.Rewind && step < 0)
      return
    else if (this.selectedButton === PlayerButtons.Ratio && step > 0)
      return;

    this.selectedButton += step;
  }

  onClick() {
    switch (this.selectedButton) {
      case PlayerButtons.Rewind:
        this.isJumping ? this.stopJumping() : this.triggerJumping(-1);
        break;
      case PlayerButtons.Forward:
        this.isJumping ? this.stopJumping() : this.triggerJumping(1);
        break;
      case PlayerButtons.Ratio:
        this.emitEvent(PlayerControlEvents.ChangeRatio)
        break;
      case PlayerButtons.PlayPause:
        if (this.playerState === PlayerState.Paused)
          this.emitEvent(PlayerControlEvents.Play);
        else
          this.emitEvent(PlayerControlEvents.Pause);
        break;
    }
  }

  showInfo() {
    if (!this.showInfo)
      this.selectedButton = PlayerButtons.PlayPause;

    this.isVisible = true;
    if (this.timeoutObject)
      clearTimeout(this.timeoutObject);
    //this.timeoutObject = setTimeout(() => this.isVisible = false, 5000);      
  }

  triggerJumping(step: number) {
    this.jumpingInterval = setInterval(() => this.jumpTo(step), 200);
    this.isJumping = true;
  }

  stopJumping() {
    clearInterval(this.jumpingInterval);
    this.isJumping = false;
  }

  jumpTo(factor: number) {
    if (this.accelerator < 100)
      this.accelerator++;

    let jump = (Math.round(this.accelerator / 20) + 1) * 15 * factor;
    this.emitTime(this.currentTime + jump);
    this.calculateDuration();
  }

  numberToStringFormat(number: number) {
    const num = Math.floor(number);
    return (num < 10) ? "0" + num : num;
  }

  calculateDuration() {
    this.progress = Math.round(this.currentTime * 100 / this.duration);
    this.timestampStart = this.numberToStringFormat(this.currentTime / 60) + ":" + this.numberToStringFormat(this.currentTime % 60);
    this.timestampEnd = this.numberToStringFormat(this.duration / 60) + ":" + this.numberToStringFormat(this.duration % 60);
  }

  emitEvent(event: PlayerControlEvents) {
    this.onEvent.emit(event);
  }

  emitTime(time: number) {
    this.setTime.emit(time);
  }

  clearAccelerator() {
    this.accelerator = 0;
  }
  
  hideTimer = null;
  hidden = false;
  setHideTimeout() {
    this.hidden = false;
    clearTimeout(this.hideTimer);
    this.hideTimer = setTimeout(() => {
      this.hidden = true;
    }, 10000);
  }
}
