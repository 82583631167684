import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeBaseComponent } from '../../home-base/home-base.component';

import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { BasePlatformService } from '../../../platforms/iplatform.interface';

@Component({
  selector: 'app-ocean-blue-grid',
  templateUrl: './ocean-blue-grid.component.html',
  styleUrls: ['./ocean-blue-grid.component.css'],
})

export class OceanBlueGridComponent extends HomeBaseComponent {

  @ViewChild('cardContainer') cardContainer: ElementRef;

  @Input() type: string = "";
  @Input() items: any[];
  @Output() onItemClick = new EventEmitter<boolean>();

  jumpStep = 5;
  currentCardIndex: number = 0;

  constructor(
    public router: Router,
    public appInfo: AppInfoService,
    public translate: TranslateService,
    private platformService: BasePlatformService,
    private _location: Location,
    public matDialog: MatDialog
  ) {
    super(router, appInfo, translate, matDialog);
  }

  ngOnInit() {
    if (this.type == 'description')
      this.jumpStep = 3;
    setTimeout(() => this.focusCard(0), 50);
  }

  ngOnChanges() {
    console.log(this.type);
    this.jumpStep = this.type == 'description' ? 3 : 5;
  }

  focusCard(index) {
    console.log(index);
    console.log(this.cardContainer.nativeElement);
    this.cardContainer.nativeElement.children[index].focus();

    this.removeMarquee();
    let text = this.cardContainer.nativeElement.children[index].children[0].children[1];
    console.log(text.clientWidth);
    console.log(text.parentElement.clientWidth);
    if (text && text.clientWidth > text.parentElement.clientWidth)
      text.classList.add('marquee');
  }

  removeMarquee() {
    let marqueeElements = document.getElementsByClassName('marquee');
    console.log(marqueeElements);
    Array.from(marqueeElements).forEach(element => element.classList.remove('marquee'));
  }

  itemClicked(item: any) {
    this.onItemClick.emit(item)
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Up':
        if (this.currentCardIndex >= this.jumpStep) {
          this.currentCardIndex -= this.jumpStep;
          this.focusCard(this.currentCardIndex);
        } else {
          this.itemClicked(-2);
        }
        break;
      case 'Down':
        if (this.currentCardIndex < this.items.length - this.jumpStep)
          this.currentCardIndex += this.jumpStep;
        this.focusCard(this.currentCardIndex);
        break;
      case 'Left':
        if (this.currentCardIndex > 0)
          this.currentCardIndex--;
        this.focusCard(this.currentCardIndex);
        break;
      case 'Right':
        if (this.currentCardIndex < this.items.length - 1)
          this.currentCardIndex++;
        this.focusCard(this.currentCardIndex);
        break;
      case 'Enter':
        this.itemClicked(this.currentCardIndex);
        break;
      case 'BrowserBack':
        this.itemClicked(-1);
        break;
      default:
        break;
    }

  }

}