import { Injectable } from '@angular/core';
import * as tizenUtils from "./utils/utils";
import tizenKeymap from "./utils/keymap";

@Injectable()
export class TizenPlatformService {
  constructor() {
    if (!!(window as any).chrome)
      return;
    tizenUtils.registerKeys();
  }

  keymapper($event: any): string {
    const _code = $event.keyCode;
    const _fallback = $event.key;
    const _finalCode = _code || _fallback;
    const _foundKey = tizenKeymap(_finalCode, _fallback);
    return _foundKey;
  }

  getDeviceInfo() {
    return tizenUtils.getDeviceInfo();
  }

}
