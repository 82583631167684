import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';
import { MatRadioButton } from '@angular/material/radio';
 
import { BasePlatformService } from '../../../platforms/iplatform.interface';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.css'],
})
export class ChangeLanguageComponent {
  @ViewChild('buttonSave') buttonSave: ElementRef;
  @ViewChild('firstRadioButtonElement')
  firstRadioButtonElement: MatRadioButton;
  @ViewChild('secondRadioButtonElement')
  secondRadioButtonElement: MatRadioButton;
  @ViewChild('thirdRadioButtonElement')
  thirdRadioButtonElement: MatRadioButton;

  constructor(
    public appInfoService: AppInfoService,
    private router: Router,
    private platformService: BasePlatformService
  ) { }

  public selectedLanguage = this.appInfoService.getLanguage;
  selectedTheme: string;
  ngOnInit() {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
  }
  ngAfterViewInit() {
    switch (this.selectedLanguage) {
      case ("en"):
        this.firstRadioButtonElement.focus();
        break;
      case ("hr"):
        this.firstRadioButtonElement.focus();
        break;
      case ("de"):
        this.firstRadioButtonElement.focus();
        break;
    }
  }

  confirm() {
    this.appInfoService.setLanguage = this.selectedLanguage as string;
    this.router.navigate(['/settings']);
  }

  radioChange(language: string) {
    this.selectedLanguage = language;
  }

  back() {
    this.router.navigate(['/settings']);
  }

  onKeydown(e: any, lastElement: boolean) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        if (lastElement) {
          this.buttonSave.nativeElement.focus();
          e.preventDefault();
        }
        break;
      case 'Enter':
        this.buttonSave.nativeElement.focus();
        e.preventDefault();
        break;
      case 'Right':
        this.buttonSave.nativeElement.focus();
        e.preventDefault();
        break;
      case 'BrowserBack':
        this.back();
        break;

      default:
        break;
    }
  }
  onKeydownButton(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.firstRadioButtonElement.focus();
        break;
      case 'Enter':
        this.confirm();
        break;
      case 'BrowserBack':
        this.back();
        break;

      default:
        break;
    }
  }
}
