<div [class]="theme">
  <div class="row eighty-percent background">
    <div class="row epg-main-panel">
      <div class="row">
        <button #epgButtonBack type="button" class="btn btn-secondary buttons-headers col-md-2" (click)="goBack()"
          (keydown)="onKeydownHeaderButtons($event)">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
          </svg>
        </button>
      </div>
      <div class="col-md-2 scrollable">
        <div #categories *ngFor="let category of categories; index as i" (click)="selectedCategoryChanged(category)"
          (keydown)="onCategoryKeydown($event, i)">
          <div class="channel-list-playing-card" tabindex="{{100+i}}" [ngClass]="{'current':selectedCategory == category.id}">
            <span class="channel-title">{{ category.category_name }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-2 scrollable">
        <div #channels *ngFor="let channel of channels; index as i" (click)="selectedChannelChanged(channel)"
          (keydown)="onChannelKeydown($event, i)">
          <div class="channel-list-playing-card" tabindex="{{300+i}}" [ngClass]="{'current':selectedChannel == channel.id}">
            <img src="{{ channel.icon }}" class="channel-icon" />
            <span class="channel-title">{{ channel.name }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-2 scrollable">
        <div #dates *ngFor="let date of dates; index as i" (click)="selectedDateChanged(date)"
          (keydown)="onDateKeydown($event, i)">
          <div class="channel-list-playing-card" tabindex="{{500+i}}" [ngClass]="{'current':selectedDate == date.date}">
            <span class="channel-title">{{ date.date | date : "dd.MM.yyyy" }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-6 scrollable">
        <div #epgItems *ngFor="let epgItem of selectedEpgItems; index as i" (click)="selectedEpgItemClicked(epgItem)"
          (keydown)="onItemKeydown($event, i)">
          <div class="channel-list-playing-card" tabindex="{{700+i}}">
            <span class="full-title">{{ generateEpgInfo(epgItem) }}</span>
            <img class="archive-icon" *ngIf="epgItem.has_archive" src="assets/images/arhiva_icon.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="row epg-info-panel">
      <div *ngIf="noDatesMessage">
        {{ "no-epg-date" | translate }}
      </div>
      <div *ngIf="selectedEpgInfo.title">
        <h2>{{ selectedEpgInfo.title }}</h2>
        <div>
          {{ selectedEpgInfo.start | date : "HH:mm":'UTC +2' }} ({{
          calculateDuration()
          }}
          min)
        </div>
        <p>{{ selectedEpgInfo.description }}</p>
      </div>
    </div>
  </div>