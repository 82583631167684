import { Injectable } from '@angular/core';
import * as Constants from '../helpers/constants.helper';
import { ICacheService } from './cache-service.interface';
import { LiveStream, MovieStream, TVShowStream, RadioStream, Stream } from '../models/stream.model';
import { CategoryStream, StreamOptionType } from '../models/category.model';
import { CompleteEpgListing } from '../models/epg.model';


@Injectable()
export class CacheService implements ICacheService {

  liveStreams: LiveStream[];
  movieStreams: MovieStream[];
  tvShowStreams: TVShowStream[];
  radioStreams: RadioStream[];
  shortEpg = new Map<any, CompleteEpgListing[]>();

  constructor() { }

  public cacheTvChannels(liveStreams: LiveStream[]) {
    this.liveStreams = liveStreams;
    //localStorage.setItem(Constants.tvChannels, JSON.stringify(liveStreams.slice(0, 100)));
  }

  public cacheFavourites(favourites: LiveStream[]) {
    this.liveStreams.push(...favourites);
    //localStorage.setItem(Constants.tvChannels, JSON.stringify(liveStreams.slice(0, 100)));
  }

  public cacheTvShows(tvShowStreams: TVShowStream[]) {
    this.tvShowStreams = tvShowStreams;
    //localStorage.setItem(Constants.tvShows, JSON.stringify(tvShowStreams.slice(0, 100)));
  }

  public cacheMovies(movieStreams: MovieStream[]) {
    this.movieStreams = movieStreams;
    //sessionStorage.setItem(Constants.movies, JSON.stringify(movieStreams.slice(0, 100)));
  }

  public cacheRadio(radioStreams: RadioStream[]) {
    this.radioStreams = radioStreams;
    //sessionStorage.setItem(Constants.radio, JSON.stringify(radioStreams.slice(0, 100)));
  }

  public cacheShortEpg(epg: Map<string, CompleteEpgListing[]>) {
    this.shortEpg = epg;
  }

  public getShortEpg(): Map<string, CompleteEpgListing[]> {
    return this.shortEpg;
  }

  public getShortEpgByChannelName(channelName: string): CompleteEpgListing[] {
    if (this.shortEpg && this.shortEpg.size > 0) {
      let res = this.shortEpg.get(channelName);

      if (res && res.length) {
        return res;
      }
    }

    return new Array<CompleteEpgListing>();
  }

  public getCachedTvChannelStreamById(streamId: number): LiveStream {
    return this.liveStreams.find(x => x.id == streamId);

    // let res = localStorage.getItem(Constants.tvChannels);
    // if (res) {
    //   let parsedRes = JSON.parse(res) as LiveStream[];
    //   let stream = parsedRes.find(x => x.id == streamId);
    //   return stream;
    // }

    // return new LiveStream();
  }

  public getCachedMovieStreamById(streamId: number): Stream {
    return this.movieStreams.find(x => x.id == streamId);

    // let res = localStorage.getItem(Constants.movies);
    // if (res) {
    //   let parsedRes = JSON.parse(res) as Stream[];
    //   let stream = parsedRes.filter(x => x.id == streamId)[0];
    //   return stream;
    // }

    // return new Stream();
  }

  public getCachedTvShowStreamById(streamId: number): Stream {
    return this.tvShowStreams.find(x => x.id == streamId);

    // let res = localStorage.getItem(Constants.tvShows);
    // if (res) {
    //   let parsedRes = JSON.parse(res) as Stream[];
    //   let stream = parsedRes.filter(x => x.id == streamId)[0];
    //   return stream;
    // }

    // return new Stream();
  }

  public getCachedTvChannelsByCategory(categoryId: number): Stream[] {
    if (categoryId != 0 && categoryId != -1) {
      return this.liveStreams.filter(x => x.category_id == categoryId)
    }

    if (categoryId == 0) {
      return this.liveStreams;
    }

    if (categoryId == -1) {
      console.log(this.liveStreams);

      return this.liveStreams.filter(x => x.favourite == true);
    }

    // let res = localStorage.getItem(Constants.tvChannels);
    // if (res && res.length) {
    //   let value = JSON.parse(res) as Stream[];
    //   if (categoryId == 0) {
    //     return value;
    //   }
    //   return value.filter(x => x.category_id == categoryId);
    // }

    // return new Array<Stream>();
  }

  public getCachedTvShowsByCategory(categoryId: number): Stream[] {
    return this.tvShowStreams.filter(x => x.category_id == categoryId);

    // let res = localStorage.getItem(Constants.tvShows);
    // if (res && res.length) {
    //   let value = JSON.parse(res) as Stream[];
    //   return value.filter(x => x.category_id == categoryId);
    // }

    // return new Array<Stream>();
  }

  public getCachedMoviesByCategory(categoryId: number): MovieStream[] {
    return this.movieStreams.filter(x => x.category_id == categoryId);

    // let res = sessionStorage.getItem(Constants.movies);
    // if (res && res.length) {
    //   let value = JSON.parse(res) as MovieStream[];
    //   return value.filter(x => x.category_id == categoryId);
    // }

    // return new Array<MovieStream>();
  }

  public getCachedRadioByCategory(categoryId: number): RadioStream[] {
    return this.radioStreams.filter(x => x.category_id == categoryId);

    // let res = sessionStorage.getItem(Constants.radio);
    // if (res && res.length) {
    //   let value = JSON.parse(res) as RadioStream[];
    //   return value.filter(x => x.category_id == categoryId);
    // }

    // return new Array<RadioStream>();
  }

  public cacheCategories(values: any[], localStorageName: string) {
    localStorage.setItem(localStorageName, JSON.stringify(values));
  }

  public getCategoriesFromCacheByCategoryType(categoryType: StreamOptionType): CategoryStream[] {
    let localStorageName = "";
    switch (categoryType) {
      case StreamOptionType.TVChannels:
        localStorageName = Constants.tvChannelsCategories;
        break;
      case StreamOptionType.TVShows:
        localStorageName = Constants.tvShowsCategories;
        break;
      case StreamOptionType.Movies:
        localStorageName = Constants.moviesCategories;
        break;
      case StreamOptionType.TVArchive:
        localStorageName = Constants.tvChannelsCategories;
        break;
      case StreamOptionType.Radio:
        localStorageName = Constants.radioCategories;
    }
    let local = localStorage.getItem(localStorageName);
    if (local && local.length) {
      return JSON.parse(local) as CategoryStream[];
    }

    return new Array<CategoryStream>();
  }
}
