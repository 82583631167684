import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';
 
import { AppInfoService } from 'src/app/services/app-info.service';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { Stream } from '../../models/stream.model';

@Component({
  selector: 'app-continue-playing-dialog',
  templateUrl: './continue-playing-dialog.component.html',
  styleUrls: ['./continue-playing-dialog.component.css'],
})
export class ContinuePlayingDialogComponent implements OnInit {
  @ViewChild('continuePlayDialogButtonOk')
  continuePlayDialogButtonOk: ElementRef;
  @ViewChild('continuePlayDialogButtonClose')
  continuePlayDialogButtonClose: ElementRef;
  constructor(
    private platformService: BasePlatformService,
    private appInfoService: AppInfoService,
    public dialogRef: MatDialogRef<ContinuePlayingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.inputValue = data.channel;
  }

  inputValue: Stream;
  selectedTheme: string;

  ngOnInit() {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.continuePlayDialogButtonOk.nativeElement.focus();
    }, 1000); // zbog fokusa na tv home 
  }

  close(confirm: boolean): any {
    this.dialogRef.close(confirm);
  }

  onKeydownButtonOk(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Right':
        this.continuePlayDialogButtonClose.nativeElement.focus();
        break;
      case 'Enter':
        this.close(true);
        break;

      default:
        break;
    }
  }
  onKeydownButtonClose(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Left':
        this.continuePlayDialogButtonOk.nativeElement.focus();
        break;
      case 'Enter':
        this.close(false);
        break;

      default:
        break;
    }
  }
}
