import { LiveStream, MovieStream, RadioStream, TVShowStream } from "./stream.model";

export class CategoryStream {
  id: number;
  category_name: string;
  locked: boolean;
  cat_order: number;
  streams: LiveStream[] | TVShowStream[] | MovieStream[] | RadioStream[];
}

export enum StreamOptionType {
  TVChannels,
  Movies,
  TVShows,
  TVArchive,
  Radio,
  MultiEPG,
  Settings,
  Exit,
  ProfileSelection,
  None
}