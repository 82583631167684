import { version } from 'src/app/models/version';

export class LicenceActivationReq {
  licence: string;
  device_name: string;
  device_id: string;
  version_name: string;
  version_code: string;

  constructor(licence: string, device_name: string, device_id: string) {
    let versionArray = version.split('-');
    this.licence = licence;
    this.device_name = device_name;
    this.device_id = device_id;
    if(versionArray.length == 2) {
      this.version_name = versionArray[0];
      this.version_code = versionArray[1];
    } else {
      this.version_name = version;
      this.version_code = "";
    }
  }
}