export class ProfileBouquet {
    id: number;
    bouquet_name: string;
    bouquet_channels: number[];
    bouquet_series: number[];
    bouquet_order: number;
    type: string;
    pivot: BouquetPivot;
}

export class ClientBouquet {
    id: number;
    bouquet_name: string;
    bouquet_order: number;
    type: string;
}

export class BouquetVisiblePayload {
    bouquet_id: number;
}

export class BouquetLockPayload {
    bouquet_id: number;
    locked: boolean;
}

export class BouquetPivot {
    bouquet_id: number;
    locked: number;
    profile_id: number;
}