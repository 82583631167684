import {
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { UserInfoV2 } from '../../../models/login.model';
import { HomeCard } from '../../../models/home.model';
import { TranslateService } from '@ngx-translate/core';
import { HomeBaseComponent } from '../../home-base/home-base.component';
import {
  CategoryStream,
  StreamOptionType,
} from '../../../models/category.model';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { exitApplication } from '../../../platforms/tizen/utils/utils';
import { PinRequiredDialogComponent } from '../../pin-required-dialog/pin-required-dialog.component';
import { HomeUserInfoComponent } from './user-info/home-user-info.component';
import { version } from 'src/app/models/version';
import { ExitDialogComponent } from '../../exit-dialog/exit-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { EvelinService } from '../../../services/evelin.service';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-home-stb-extreme',
  templateUrl: './home-stb-extreme.component.html',
  styleUrls: ['./home-stb-extreme.component.css'],
})
export class HomeStbExtremeComponent extends HomeBaseComponent {
  selectedTheme: string = 'stb-extreme';
  @ViewChildren('homeButtons') homeButtons: QueryList<any>;
  @ViewChild('homeCommonent') homeCommonent: ElementRef;
  @ViewChild('cardsContainer') cardsContainer: ElementRef;
  @ViewChild('categoryContainer') categoryContainer: ElementRef;
  @ViewChild('scrollAnimator') scrollAnimator: ElementRef;
  @ViewChild(HomeUserInfoComponent) info: HomeUserInfoComponent;

  public showLanguageSelection: boolean = false;
  public showLogin: boolean = false;
  showCategoryList = true;
  currentCategories: CategoryStream[] = [];

  public currentTime = Date.now();
  expiryDate: Date;
  homeButtonsStep: number = 0;
  selectedCategoryStep: number = 0;
  selectedCategory: CategoryStream;

  public selectedType = StreamOptionType.TVChannels;

  public buttonsArray: HomeCard[] = new Array<HomeCard>();
  listFocused = true;

  public appVersion = version;

  constructor(
    public router: Router,
    public appInfo: AppInfoService,
    public translate: TranslateService,
    private platformService: BasePlatformService,
    private _location: Location,
    public matDialog: MatDialog,
    private appInfoService: AppInfoService,
    private authService: AuthService,    
    private evelinService: EvelinService
  ) {
    super(router, appInfo, translate, matDialog);
  }

  async ngOnInit(): Promise<void> {    
    //this.getAnnouncements();
    setTimeout(() => {
      this.initHomeData();
    }, 500);
  }

  async getAnnouncements() {
    let annoucements = await this.evelinService.getClientAnnouncements();
    const unreadAnnoucements = annoucements.filter(a => !a.read);
    if (unreadAnnoucements.length) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;
      dialogConfig.width = '60%';
      dialogConfig.height = '40%';
      dialogConfig.data = unreadAnnoucements[0];
      const dialogRef = this.dialog.open(NotificationComponent, dialogConfig);
      dialogRef
        .afterClosed()
        .subscribe(() => {
          this.evelinService.markClientAnnouncementAsRead(unreadAnnoucements[0].id);
          this.focusContainer();
        });
    }
  }

  async openNotifications() {
    let notifications = await this.evelinService.getClientNotifications();
    const unreadNotifications = notifications.filter(a => !a.read);
    if (notifications.length) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;
      dialogConfig.width = '60%';
      dialogConfig.height = '40%';
      dialogConfig.data = unreadNotifications[0];
      const dialogRef = this.dialog.open(NotificationComponent, dialogConfig);
      dialogRef
        .afterClosed()
        .subscribe(() => {
          this.evelinService.markClientNotificationAsRead(unreadNotifications[0].id);
          this.focusContainer();
          this.info.setDisplayState(true);
        });
    }
  }

  getHomeButtons() { 
    let array = [{
      text: this.translate.instant('tv-channels'),
      imageSource: 'assets/images/tv_icon.png',
      routingUrl: 'e',
      type: StreamOptionType.TVChannels,
      shouldRoute: false,
      isSelected: true,
    }];
    // {
    //   text: this.translate.instant('tv-archive'),
    //   imageSource: 'assets/images/arhiva_icon.png',
    //   routingUrl: 's',
    //   type: StreamOptionType.TVArchive,
    //   shouldRoute: false,
    //   isSelected: false
    // },
    // {
    //   text: this.translate.instant('multi-epg'),
    //   imageSource: 'assets/images/epg_icon.png',
    //   routingUrl: '/multi-epg',
    //   type: StreamOptionType.MultiEPG,
    //   shouldRoute: true,
    //   isSelected: false,
    // },

  if (this.appInfoService.getCategoriesFromCache(StreamOptionType.Movies).length)
    array.push({
      text: this.translate.instant('movies'),
      imageSource: 'assets/images/filmovi_icon.png',
      routingUrl: 'e',
      type: StreamOptionType.Movies,
      shouldRoute: false,
      isSelected: false
    });

  if (this.appInfoService.getCategoriesFromCache(StreamOptionType.TVShows).length)
    array.push({
      text: this.translate.instant('tv-shows'),
      imageSource: 'assets/images/serije_icon.png',
      routingUrl: 'e',
      type: StreamOptionType.TVShows,
      shouldRoute: false,
      isSelected: false
    });

  if (this.appInfoService.getCategoriesFromCache(StreamOptionType.Radio).length)
    array.push({
      text: this.translate.instant('radio'),
      imageSource: 'assets/images/radio_icon.png',
      routingUrl: 'e',
      type: StreamOptionType.Radio,
      shouldRoute: false,
      isSelected: false
    });

    array = array.concat([
      {
        text: this.translate.instant('settings.settings'),
        imageSource: 'assets/images/podesavanja_icon.png',
        routingUrl: '/settings',
        type: StreamOptionType.Settings,
        shouldRoute: true,
        isSelected: false,
      },
      {
        text: this.translate.instant('select-profile'),
        imageSource: 'assets/images/profile.png',
        routingUrl: '/select-profile/false/0',
        type: StreamOptionType.ProfileSelection,
        shouldRoute: true,
        isSelected: false,
      },
      {
        text: this.translate.instant('quit'),
        imageSource: 'assets/images/izlaz_icon.png',
        routingUrl: 'e',
        type: StreamOptionType.Exit,
        shouldRoute: true,
        isSelected: false,
      }
    ]);

    return array;
}


  initHomeData() {
    this.buttonsArray = this.getHomeButtons();    
    this.focusContainer();

    let temp = localStorage.getItem('fern-last-home');
    if (temp) {
      this.homeButtonsStep = JSON.parse(temp).card;
      this.cardSelected(this.buttonsArray[this.homeButtonsStep]);
      this.selectedCategoryStep = JSON.parse(temp).step - 1;
      this.changeCategory(1);
    } else this.cardSelected(this.buttonsArray[0]);
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Right':
        this.changeFocusHomeButtons(1);
        break;
      case 'Left':
        this.changeFocusHomeButtons(-1);
        break;
      case 'Up':
        this.changeCategory(-1);
        break;
      case 'Down':
        this.changeCategory(1);
        break;
      case 'Enter':        
        if (this.info.showInfo)
          this.openNotifications();
        else
          this.navigate();
        break;
      case 'Exit':
      case 'BrowserBack':
        this.openExitDialog();
        break;
      default:
        break;
    }
  }

  changeFocusHomeButtons(step: number) {
    if (this.buttonsArray.length - 1 == this.homeButtonsStep && step > 0) {
      return;
    } else if (this.homeButtonsStep == 0 && step < 0) {
      this.info.setDisplayState(true);
      this.buttonsArray[0].isSelected = false;
      return;
    } else if (this.info.showInfo) {
      this.info.setDisplayState(false);
      this.focusContainer();
      this.cardSelected(this.buttonsArray[0]);
      return;
    }

    if (step > 0) {
      this.homeButtonsStep++;
    } else if (step < 0) {
      this.homeButtonsStep--;
    } else if (step == 0) {
      this.homeButtonsStep = 0;
    }

    this.cardSelected(this.buttonsArray[this.homeButtonsStep]);
  }

  changeCategory(step: number) {
    let cardElement =
      this.scrollAnimator?.nativeElement.children[this.selectedCategoryStep]
        .children[0];
    if (cardElement) cardElement.classList.remove('marquee');

    if (this.selectedCategoryStep === 0 && step < 0) {
      this.selectedCategoryStep = this.currentCategories.length;
    } else if (
      this.selectedCategoryStep === this.currentCategories.length - 1 &&
      step > 0
    ) {
      this.selectedCategoryStep = -1;
    }
    if (!step) this.selectedCategoryStep = step;

    this.selectedCategoryStep += step;
    this.selectedCategory = this.currentCategories[this.selectedCategoryStep];

    // this.categoryContainer?.nativeElement.scroll({
    //   top: (this.selectedCategoryStep - 5) * 80,
    //   behavior: "smooth",
    // });
    if (this.scrollAnimator && this.currentCategories.length > 6) {
      let jump = 0;
      if (this.selectedCategoryStep === this.currentCategories.length - 1)
        jump = (this.selectedCategoryStep - 6) * -80;
      else if (this.selectedCategoryStep > 4)
        jump = (this.selectedCategoryStep - 5) * -80;

      this.scrollAnimator.nativeElement.style['transform'] =
        'translateY(' + jump + 'px)';
    }

    cardElement =
      this.scrollAnimator?.nativeElement.children[this.selectedCategoryStep]
        .children[0];
    if (cardElement && cardElement.scrollWidth > cardElement.clientWidth)
      cardElement.classList.add('marquee');
  }

  goToSettings() {
    this.router.navigate(['/settings']);
  }

  goBack() {
    this._location.back();
  }

  cardSelected(button: HomeCard) {
    let currentButton = this.buttonsArray.find((b) => b.isSelected);
    if (currentButton) currentButton.isSelected = false;

    this.currentCategories = button.shouldRoute ? [] : this.appInfoService.getCategoriesFromCache(button.type);
    button.isSelected = true;
    this.changeCategory(0);
    const data = `translateX(${40 - this.homeButtonsStep * 22}%)`;
    this.cardsContainer.nativeElement.style.transform = data;
    // this.cardsContainer.nativeElement.style.transform = "translateX(400px)";
  }

  focusContainer() {
    this.homeCommonent.nativeElement.focus();
  }

  navigate() {
    localStorage.setItem(
      'fern-last-home',
      JSON.stringify({
        card: this.homeButtonsStep,
        step: this.selectedCategoryStep,
      })
    );

    if (
      this.buttonsArray[this.homeButtonsStep].type == StreamOptionType.TVArchive
    ) {
      this.router.navigate([
        'stb-extreme-tv-archive/',
        this.selectedCategory.id,
      ]);
    } else if (
      this.buttonsArray[this.homeButtonsStep].type == StreamOptionType.MultiEPG
    ) {
      this.router.navigate(['/multi-epg']);
    } else if (
      this.buttonsArray[this.homeButtonsStep].type == StreamOptionType.MultiEPG
    ) {
      this.router.navigate(['/multi-epg']);
    } else if (
      this.buttonsArray[this.homeButtonsStep].type == StreamOptionType.Settings
    ) {
      this.router.navigate(['/settings']);
    } else if (
      this.buttonsArray[this.homeButtonsStep].type == StreamOptionType.Radio
    ) {
      this.router.navigate(['/radio', this.selectedCategory.id]);
    } else if (
      this.buttonsArray[this.homeButtonsStep].type == StreamOptionType.Exit
    ) {
      //exitApplication();
      this.openExitDialog();
    } else if (
      this.buttonsArray[this.homeButtonsStep].type ==
      StreamOptionType.ProfileSelection
    ) {
      this.router.navigate(['select-profile', false, 0]);
    } else {
      this.navigateToChannelsList();
    }
  }

  async openExitDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '80rem';
    dialogConfig.height = '10rem';

    const dialogRef = this.dialog.open(ExitDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((shouldExit) => {
      if (shouldExit) this.authService.exitApplication();
    });
  }

  private navigateToChannelsList() {
    if (this.selectedCategory.locked) {
      if (!this.appInfoService.isLastCorrectPinValid()) {
        this.generatePinRequiredDialog();
      } else {
        this.router.navigate([
          'stb-extreme-items-list',
          this.buttonsArray[this.homeButtonsStep].type,
          this.selectedCategory.id,
        ]);
      }
    } else {
      this.router.navigate([
        'stb-extreme-items-list',
        this.buttonsArray[this.homeButtonsStep].type,
        this.selectedCategory.id,
      ]);
    }
  }

  selectCardClick(step: number) {
    this.homeButtonsStep = step;
    this.navigate();
  }
  selectCategoryClick(step: number) {
    this.changeCategory(step);
    this.navigateToChannelsList();
  }

  private generatePinRequiredDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '50rem';
    dialogConfig.height = '20rem';
    const dialogRef = this.dialog.open(
      PinRequiredDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((value) => {
      if (value == true) {
        this.router.navigate([
          '/stb-extreme-items-list',
          this.buttonsArray[this.homeButtonsStep].type,
          this.selectedCategory.id,
        ]);
      }
    });
  }
}

export class Card {
  text: string;
  imageSource: string;
  routingUrl: string;
  type: StreamOptionType;
  shouldRoute: boolean;
}
