import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import { Location } from '@angular/common';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { AppInfoService } from '../../services/app-info.service';
import { Theme } from '../../models/theme.model';
import { ThemeHelper } from '../../helpers/theme.helper';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css', './settings-header/settings-header.component.css'],
})
export class SettingsComponent implements OnInit {
  @ViewChildren('settingList') settingList: QueryList<any>;
  @ViewChild('settingsButtonBack') settingsButtonBack: ElementRef;
  numberButtonsStep: number = 0;
  listFocused = false;
  theme: string;

  constructor(
    private router: Router,
    private platformService: BasePlatformService,
    private location: Location,
    private appInfoService: AppInfoService
  ) {     
    this.theme = ThemeHelper.transformThemeToString(this.appInfoService.getSelectedTheme());
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.numberButtonsStep = parseInt(localStorage.getItem("last-setting-clicked") || "0") - 1;
      console.log(this.numberButtonsStep - 1);
      this.changeFocusHomeChannelButtons(0);
    }, 100);
  }

  changeFocusHomeChannelButtons(step: number) {
    console.log(this.settingList.toArray());
    if (this.numberButtonsStep == this.settingList.toArray()[0].nativeElement.children.length - 1 && step > 0)
      //set range for movement
      return;
    if (this.numberButtonsStep == 0 && step < 0) {
      this.settingsButtonBack.nativeElement.focus(); // focuse on header component
      return;
    }

    if (step > 0) {
      this.numberButtonsStep++;
    } else if (step < 0) {
      this.numberButtonsStep--;
    } else if (step == 0) {
      this.numberButtonsStep = 0;
    }

    this.settingList
      .toArray()[0]
      .nativeElement.children[this.numberButtonsStep].focus();
  }

  optionClicked() {
    this.settingList
      .toArray()[0]
      .nativeElement.children[this.numberButtonsStep].click();
  }

  listFocusLost(listFocused: boolean) {
    if (listFocused) {
      this.changeFocusHomeChannelButtons(0);
    }
    this.listFocused = false;
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.changeFocusHomeChannelButtons(-1);
        break;
      case 'Down':
        this.changeFocusHomeChannelButtons(1);
        break;
      case 'Enter':
        this.optionClicked();
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  onHeaderKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        this.changeFocusHomeChannelButtons(0);
        break;
      case 'Enter':
        this.goBack();
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  goBack() {
    //this.location.back();
    this.theme === "stb-extreme" ? this.router.navigate(['/home-stb']) : this.router.navigate(['/home-ocean-blue']);
  }

  goToChangeLanguage() {
    localStorage.setItem("last-setting-clicked", "0");
    this.router.navigate(['/change-language']);
  }
  goToParentalControls() {
    localStorage.setItem("last-setting-clicked", "1");
    this.router.navigate(['/parental-controls']);
  }
  goToCategoryHiding() {
    localStorage.setItem("last-setting-clicked", "2");
    this.router.navigate(['/category-hiding', false]);
  }
  // goToChangePin() {
  //   localStorage.setItem("last-setting-clicked", "3");
  //   this.router.navigate(['/change-pin']);
  // }
  goToPlayerSettings() {
    localStorage.setItem("last-setting-clicked", "3");
    this.router.navigate(['/player-settings']);
  }
  goToUserInfo() {
    localStorage.setItem("last-setting-clicked", "4");
    this.router.navigate(['/user-info']);
  }
  goToSkinSelect() {
    localStorage.setItem("last-setting-clicked", "5");
    this.router.navigate(['theme-selection']);
  }
  goToDnsChange() {
    localStorage.setItem("last-setting-clicked", "6");
    this.router.navigate(['/change-dns']);
  }
  goToSpeedTest() {
    localStorage.setItem("last-setting-clicked", "7");
    this.router.navigate(['/speed-test']);
  }
  goToChangeUser() {
    localStorage.setItem("last-setting-clicked", "8");
    this.router.navigate(['/change-user']);
  }
}
