<div class="conteiner">
  <div class="row">
    <div class="col-lg-6 background">
      <div class="center">
        <img src="assets/images/onboarding/fernTV-logo-RGB-White.png" class="theme-icon" />
        <label *ngIf="!passwordlessLogin" class="title">{{ "login.provider" | translate }}</label>
        <label *ngIf="passwordlessLogin" class="title">{{ "login.provider2" | translate }}</label>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="buttons">
        <label class="buttons-title">{{
          "login.setup-account" | translate
          }}</label>
        <div class="register-form" id="register-form">
          <div *ngIf="!passwordlessLogin" class="form-group">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
              class="bi bi-person icon-position" viewBox="0 0 16 16">
              <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
            </svg>
            <label for="email"><i class="zmdi zmdi-email"></i></label>
            <input #loginUsername type="email" name="email" id="email" class="form-group-input"
              (keydown)="onKeydownUsername($event)" [(ngModel)]="username" [placeholder]="usernameTxt" />
          </div>
          <div *ngIf="!passwordlessLogin" class="form-group">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
              class="bi bi-lock icon-position" viewBox="0 0 16 16">
              <path
                d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
            </svg>
            <label for="pass"><i class="zmdi zmdi-lock"></i></label>
            <input #loginPassword [type]="hide ? 'password' : 'text'" name="pass" id="pass" class="form-group-input"
              (keydown)="onKeydownPassword($event)" [(ngModel)]="password" [placeholder]="passwordTxt" />
            <button #buttonPassword type="button" class="btn btn-secondary button-password" (click)="hide = !hide"
              (keydown)="onKeydownPasswordButton($event)">
              <svg *ngIf="hide" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                class="bi bi-eye icon-pass-position" viewBox="0 0 16 16">
                <path
                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
              </svg>
              <svg *ngIf="!hide" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                class="bi bi-eye-slash icon-pass-position" viewBox="0 0 16 16">
                <path
                  d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                <path
                  d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                <path
                  d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
              </svg>
            </button>
          </div>

          <!-- PASSWORDLESS LOGIN -->
          <div *ngIf="passwordlessLogin" class="form-group">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
              class="bi bi-lock icon-position" viewBox="0 0 16 16">
              <path
                d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
            </svg>
            <label for="email"><i class="zmdi zmdi-email"></i></label>
            <input #passwordlessPinInput type="number" name="email" id="email" class="form-group-input"
              [(ngModel)]="pin" readonly="true" />
          </div>

          <div class="form-group form-button">
            <button #loginSubmit *ngIf="!passwordlessLogin" class="submit-button" (keydown)="onKeydownSubmit($event)"
              (click)="onSubmit()" mat-raised-button color="primary">
              {{ "login.submit" | translate }}
            </button>
            <br />
            <button #passwordlessLoginBtn class="submit-button" (keydown)="onKeydownPasswordlessLogin($event)"
              (click)="onPasswordlessLogin()" mat-raised-button color="primary">
              {{ "login.passwordlessLogin" | translate }}
            </button>
            <br />
            <button #backBtn *ngIf="backButtonVisible" class="submit-button" (keydown)="onKeydownBack($event)"
              (click)="goBack()" mat-raised-button color="primary">
              {{ "login.back" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div> <label>{{ appVersion }}</label></div>
    </div>
  </div>
</div>