<div class="background">
  <div class="row" #homeCommonent tabindex="1" (keydown)="onKeydown($event)" (focusLost)="focusContainer()">
    <home-user-info></home-user-info>
    <div class="appVersion">
      <p>{{ appVersion }}</p>
    </div>
    <div class="time">
      <p>{{ currentTime | date : "HH:mm" }}</p>
    </div>

    <div #cardsContainer class="selection-container">
      <div class="cards-container row">
        <div *ngFor="let button of buttonsArray;let i = index" class="home-button col-md-3" tabindex="{{10 + i}}"
          (click)="cardSelected(button)">

          <div #categoryContainer *ngIf="button.isSelected && currentCategories.length" class="category-container">
            <div #scrollAnimator class="scrollAnimator">
              <div *ngFor="let category of currentCategories;let i = index" class="category" (click)="selectCategoryClick(i)"
                [ngClass]="{'category-selected':category.id === selectedCategory?.id}">
                <div class="channel-list-card" tabIndex="{{100+i}}">
                  <span class="channel-list-title"> {{ category.category_name }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card" [ngClass]="{'cardSelected': button.isSelected}" (click)="selectCardClick(i)">
            <img src={{button.imageSource}} height="130px" width="130px" />
            <p>{{button.text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>