import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { BasePlatformService } from '../../../../platforms/iplatform.interface';
import { Router } from '@angular/router';
import { ClientBouquet } from '../../../../models/bouquet.model';

@Component({
  selector: 'app-minor-category-list',
  templateUrl: './minor-category-list.component.html',
  styleUrls: ['./minor-category-list.component.css']
})
export class MinorCategoryListComponent implements OnInit {

  constructor(private router: Router, private platformService: BasePlatformService) { }

  @ViewChild("categoryList") categoryList: ElementRef;

  @Input() minorCategories: ClientBouquet[];
  @Input() hiddenMinorCategories: ClientBouquet[];
  resourcesLoaded = false;
  @Output() selectedMinorCategoriesChange = new EventEmitter<ClientBouquet[]>();
  @Output() leaveComponent = new EventEmitter<void>();

  selection = new SelectionModel<ClientBouquet>(true, []);
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';

  ngOnInit(): void {
    setTimeout(() => {
      this.hiddenMinorCategories.forEach(hidden => {
        this.selection.select(this.minorCategories.filter(x => x.id == hidden.id && x.bouquet_name == hidden.bouquet_name)[0]);
      });

      this.resourcesLoaded = true;
    }, 3000);
  }

  selectionChanged(e: any) {
    console.log(e);
    this.selection.toggle(e);
    this.selectedMinorCategoriesChange.emit(this.selection.selected);
  }

  setFocus() {
    console.log(this.categoryList.nativeElement)
    this.categoryList.nativeElement.children[0].focus();
  }


  goBack() {
    this.router.navigate(['/settings']);
  }

  onKeydown(e: any, index: number, category: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(index);
    switch (key.toString()) {
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Right':
        this.leaveComponent.emit();
        break;
      case 'Up':
        index--;
        this.categoryList.nativeElement.children[index].focus();
        break;
      case 'Down':
        index++;
        this.categoryList.nativeElement.children[index].focus();
        break;
      case 'Enter':
        this.selectionChanged(category);
        break;
      default:
        break;
    }
  }

}
