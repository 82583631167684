import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AppInfoService } from "./app-info.service";
import { MovieInfo } from "../models/filminfo.model";
import { TvShowInfo } from "../models/serieinfo.model";
import { ProfileBouquet, ClientBouquet, BouquetVisiblePayload, BouquetLockPayload } from "../models/bouquet.model";
import { CategoryStream } from "../models/category.model";
import { ClientNotification, NotificationBase } from "../models/client-notification.model";
import { UserInfoV2 } from "../models/login.model";
import { ClientProfile, ClientProfileRequest } from "../models/profile.model";
import { SystemValues } from "../models/system.model";
import * as Constants from '../helpers/constants.helper';
import { CompleteEpgListing } from "../models/epg.model";
import { LiveStream, MovieStream, RadioStream, StreamFavoriteToggleReq, TVShowStream } from "../models/stream.model";
import { PaginatedResult, StreamRootResponse } from "../models/streamroot.model";

@Injectable()
export class EvelinService {
    constructor(private apiService: ApiService, private appInfoService: AppInfoService) {
    }

    // #region PROFILES

    public async getClientProfiles(): Promise<ClientProfile[]> {
        let profiles = await this.apiService.get(this.appInfoService.getRootApiUrl + "client/profiles") as ClientProfile[];

        return profiles;
    }

    public async createClientProfile(profileName: string, profilePin: string, avatar: number, type: string): Promise<ClientProfile | any> {
        let payload = new ClientProfileRequest();
        payload.pin = profilePin;
        payload.avatar = avatar;
        payload.type = type;
        payload.profile_name = profileName;
        let response = await this.apiService.post(this.appInfoService.getRootApiUrl + "profile", payload);

        let result = new ClientProfile();
        result.active = true;
        result.id = response.id;
        result.pin = response.pin;
        result.profile_name = response.profile_name;
        result.type = "normal";

        return result;
    }

    public async editClientProfile(profileId: number, payload: ClientProfileRequest): Promise<any> {
        let response = await this.apiService.patch(this.appInfoService.getRootApiUrl + "profile/" + profileId, payload);
        return response;
    }

    public async deleteClientProfile(profileId: number): Promise<any> {
        let response = await this.apiService.deleteAsPromise(this.appInfoService.getRootApiUrl + "profile/" + profileId, null);
        return response;
    }

    // #endregion

    // #region GENERAL LIVE/MOVIES/TVSHOWS/FAVORITES DATA
    public async getAllCategoriesForLive(): Promise<CategoryStream[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/live") as CategoryStream[];
    }

    public async getAllCategoriesForRadio(): Promise<CategoryStream[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/radio") as CategoryStream[];
    }

    public async getAllCategoriesForMovies(): Promise<CategoryStream[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/movie") as CategoryStream[];
    }

    public async getAllCategoriesForTvShows(): Promise<CategoryStream[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/series") as CategoryStream[];
    }

    public async getAllTvChannelsFromCategory(categoryId: number, page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<LiveStream>> {
        let url = this.generateUrlForStreamFetch("live", categoryId, page, per_page, sort_field, sort_order, search);

        return await this.apiService.get(url) as PaginatedResult<LiveStream>;
    }

    public async getAllRadiosFromCategory(categoryId: number, page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<LiveStream>> {
        let url = this.generateUrlForStreamFetch("radio", categoryId, page, per_page, sort_field, sort_order, search);
        return await this.apiService.get(url) as PaginatedResult<LiveStream>;
    }

    public async getAllMoviesFromCategory(categoryId: number): Promise<PaginatedResult<MovieStream>> {
        let url = this.generateUrlForStreamFetch("movie", categoryId, 1, 5000, null, null, null);
        return await this.apiService.get(url) as PaginatedResult<MovieStream>;
    }

    public async getPaginatedMoviesFromCategory(categoryId: number, page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<MovieStream>> {
        let url = this.generateUrlForStreamFetch("movie", categoryId, page, per_page, sort_field, sort_order, search);
        return await this.apiService.get(url) as PaginatedResult<MovieStream>;
    }
    public async getAllTvShowsFromCategory(categoryId: number): Promise<PaginatedResult<TVShowStream>> {
        let url = this.generateUrlForStreamFetch("series", categoryId, 1, 5000, null, null, null);
        return await this.apiService.get(url) as PaginatedResult<TVShowStream>;
    }

    public async getPaginatedTvShowsFromCategory(categoryId: number, page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<TVShowStream>> {
        let url = this.generateUrlForStreamFetch("series", categoryId, page, per_page, sort_field, sort_order, search);
        return await this.apiService.get(url) as PaginatedResult<TVShowStream>;
    }

    public async getPaginatedRadiosFromCategory(categoryId: number, page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<RadioStream>> {
        let url = this.generateUrlForStreamFetch("radio", categoryId, page, per_page, sort_field, sort_order, search);
        return await this.apiService.get(url) as PaginatedResult<RadioStream>;
    }

    public async getFavoriteTvChannels(page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<LiveStream>> {
        let url = this.generateUrlForFavoritesFetch("live", page, per_page, sort_field, sort_order, search);
        return await this.apiService.get(url) as PaginatedResult<LiveStream>;
    }

    public async getFavoriteTvShows(page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<TVShowStream>> {
        let url = this.generateUrlForFavoritesFetch("series", page, per_page, sort_field, sort_order, search);
        return await this.apiService.get(url) as PaginatedResult<TVShowStream>;
    }

    public async getFavoriteMovies(page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<MovieStream>> {
        let url = this.generateUrlForFavoritesFetch("movie", page, per_page, sort_field, sort_order, search);
        return await this.apiService.get(url) as PaginatedResult<MovieStream>;
    }

    public async getFavoriteRadio(page = -1, per_page = 40, sort_field = null, sort_order = null, search = null): Promise<PaginatedResult<LiveStream>> {
        let url = this.generateUrlForFavoritesFetch("radio", page, per_page, sort_field, sort_order, search);
        return await this.apiService.get(url) as PaginatedResult<LiveStream>;
    }
    public async getMovieInfoById(id: number): Promise<MovieInfo> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/get-movies/" + id);
    }

    public async getTvShowInfoById(id: number): Promise<TvShowInfo> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/get-series/" + id);
    }

    public async getTvShowEpisodes(id: number): Promise<any> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/get-series/" + id) as TvShowInfo;
    }

    public async getLiveStreamsRoot(): Promise<LiveStream[]> {
        let profileId = this.appInfoService.getCurrentProfile().id;
        let categories = (await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + profileId + "/streams/type/live?6287422evelin=true"))[0].categories;
        let result = new Array<LiveStream>();
        for (let i = 0; i < categories.length; i++) {
            let streams = categories[i].streams;
            result.push(...streams);
        }
        return result;
    }

    public async getTvShowsRoot(): Promise<StreamRootResponse> {
        let profileId = this.appInfoService.getCurrentProfile().id;
        let array = await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + profileId + "/streams/type/series?6287422evelin=true");

        return array[0] || { type: "series", categories: [] };
    }

    public async getMoviesRoot(): Promise<StreamRootResponse> {
        let profileId = this.appInfoService.getCurrentProfile().id;
        let array = await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + profileId + "/streams/type/movie?6287422evelin=true");

        return array[0] || { type: "movie", categories: [] };
    }

    public async getRadiosRoot(): Promise<LiveStream[]> {
        let profileId = this.appInfoService.getCurrentProfile().id;
        let categories = (await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + profileId + "/streams/type/radio_streams?6287422evelin=true"))[0].categories;
        let result = new Array<LiveStream>();

        for (let i = 0; i < categories.length; i++) {
            let streams = categories[i].streams;
            result.push(...streams);
        }
        return result;
    }

    public async getFavouritesRoot(): Promise<StreamRootResponse> {
        let profileId = this.appInfoService.getCurrentProfile().id;
        let array = await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + profileId + "/streams/type/favourites?6287422evelin=true");

        return array[0];
    }

    public convertStreamRootResponseToCategories(val: StreamRootResponse) {
        let categories = val.categories.map(category => {

            let result = new CategoryStream();

            result.id = category.id;
            result.category_name = category.category;
            result.locked = category.locked;

            return result;
        });

        return categories;
    }

    public generateFavuoritesCategory() {
        let language = localStorage.getItem(Constants.localStorageLanguage);
        let favName = "";
        switch (language) {
            case "hr":
                favName = "Favoriti";
                break;
            case "en":
                favName = "Favourites";
                break;
            case "de":
                favName = "Lieblingskanäle";
                break;
        }

        let favs = new CategoryStream();
        favs.id = -1;
        favs.category_name = favName;
        favs.locked = false;

        return favs;
    }

    private generateUrlForStreamFetch(type: string, categoryId: number, page = -1, per_page = 40, sort_field = null, sort_order = null, search = null) {
        let url = this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/" + type + "/" + categoryId + "?per_page=" + per_page;
        if (page != -1) {
            url += "&page=" + page;
        }
        if (sort_field != null) {
            url += "&sort_field=" + sort_field;
        }
        if (sort_order != null) {
            url += "&sort_order=" + sort_order;
        }
        if (search != null) {
            url += "&search=" + search;
        }
        return url;
    }
    private generateUrlForFavoritesFetch(type: string, page = -1, per_page = 40, sort_field = null, sort_order = null, search = null) {
        let url = this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/" + type + "/favourites?per_page=" + per_page;
        if (page != -1) {
            url += "&page=" + page;
        }
        if (sort_field != null) {
            url += "&sort_field=" + sort_field;
        }
        if (sort_order != null) {
            url += "&sort_order=" + sort_order;
        }
        if (search != null) {
            url += "&search=" + search;
        }
        return url;
    }

    public async toggleFavourite(payload: number): Promise<any> {
        let payloadFinal = new StreamFavoriteToggleReq();
        payloadFinal.stream_id = payload;
        return await this.apiService.post(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/streams/favourite", payloadFinal)
    }

    // #endregion

    // #region BOUQUETS

    public async getProfilesBouquets(profileId: number): Promise<ProfileBouquet[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "profile/" + profileId + "/bouquets") as ProfileBouquet[];
    }

    public async getClientBouquets(): Promise<ClientBouquet[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "client/bouquets") as ClientBouquet[];
    }

    public async toggleBouquetVisibility(payload: BouquetVisiblePayload): Promise<any> {
        return await this.apiService.post(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/bouquets/toggle", payload);
    }

    public async toggleBouquetLock(payload: BouquetLockPayload): Promise<any> {
        return await this.apiService.post(this.appInfoService.getRootApiUrl + "profile/" + this.appInfoService.getCurrentProfile().id + "/bouquets/lock", payload);
    }

    // #endregion

    // #region SYSTEM

    public async getSystemValues(): Promise<SystemValues> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "system");
    }

    public async getAvailableStreamTypes(): Promise<string[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "types");
    }

    // #endregion

    // #region AUTH

    public async getClientData(): Promise<UserInfoV2> {
        let res = await this.apiService.get(this.appInfoService.getRootApiUrl + "client") as any;

        return res.user_info as UserInfoV2;
    }

    // #endregion

    // #region NOTIFICATIONS

    public async getClientNotifications(): Promise<NotificationBase[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "client/notifications");
    }

    public async markClientNotificationAsRead(notificationId: number): Promise<any> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "client/notifications/" + notificationId);
    }

    public async markAllClientNotificationsAsRead(): Promise<any> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "client/notifications/readAll");
    }

    // #endregion

    // #region ANNOUNCEMENTS

    public async getClientAnnouncements(): Promise<NotificationBase[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "client/announcements");
    }

    public async markClientAnnouncementAsRead(announcementId: number): Promise<any> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "client/announcements/" + announcementId);
    }

    public async markAllClientAnnouncementsAsRead(): Promise<any> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "client/announcements/readAll");
    }

    // #endregion

    // #region EPG

    public async getEpgByStreamId(streamId: number): Promise<CompleteEpgListing[]> {
        try {
            let backendRes = await this.apiService.get(this.appInfoService.getRootApiUrl + "epg?stream_id=" + streamId) as any;
            const dataAsJsMap = new Map<string, CompleteEpgListing[]>(Object.entries(backendRes));

            let res = Array.from(dataAsJsMap.values())[0];

            if (res == undefined) {
                res = new Array<CompleteEpgListing>();
            }
            return res;
        } catch (e) {
            console.log(e);
            return new Array<CompleteEpgListing>();
        }
    }

    public async getShortEpgByStreamId(streamId: number): Promise<CompleteEpgListing[]> {
        return await this.apiService.get(this.appInfoService.getRootApiUrl + "epg?action=short_epg&stream_id=" + streamId) as CompleteEpgListing[];
    }

    public async getShortEpgByCategoryId(categoryId: number): Promise<Map<string, CompleteEpgListing[]>> {
        try {
            let res = await this.apiService.get(this.appInfoService.getRootApiUrl + "epg?action=short_epg&category_id=" + categoryId) as any;
            const dataAsJsMap = new Map<string, CompleteEpgListing[]>(Object.entries(res));
            this.appInfoService.cacheShortEpg(dataAsJsMap);
            console.log("EPG cached")
            return dataAsJsMap;
        } catch (e) {
            return null;
        }
    }

    // #endregion
}