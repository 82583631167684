import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExitDialogComponent } from '../exit-dialog/exit-dialog.component';

@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.css'],
})
export class LicenceComponent implements OnInit {
  @ViewChild('licenceField') licenceField: ElementRef;
  @ViewChild('submitButton') submitButton: MatButton;

  public licence: string = '';
  public err = '';
  public deviceInfo;

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private platformService: BasePlatformService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.authService.getDeviceInfo;
    console.log(this.deviceInfo);
    setTimeout(() => this.licenceField.nativeElement.focus(), 100);
  }

  async submit() {
    let res = await this.authService.activateLicence(this.licence);

    if (res == true) {
      this.router.navigate(['/landing']);
    } else {
      console.log('COULD NOT ACTIVATE');
      this.err = 'COULD NOT ACTIVATE';
    }
  }

  onInputKeydown(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Enter':
      case 'Select':
      case 'Accept':
        this.submitButton.focus();
        break;
      case 'BrowserBack':
        if (!this.licence) this.openExitDialog();
        break;
      default:
        break;
    }
  }

  onButtonKeydown(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Enter':
      case 'Select':
      case 'Accept':
        this.submit();
        break;
      case 'Left':
        this.licenceField.nativeElement.focus();
        break;
      case 'BrowserBack':
        this.openExitDialog();
        break;
      default:
        break;
    }
  }

  async openExitDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '80rem';
    dialogConfig.height = '10rem';

    const dialogRef = this.dialog.open(ExitDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((shouldExit) => {
      if (shouldExit) this.authService.exitApplication();
    });
  }
}
