<div class="ocean-blue background row">
  <div class="list-container col-md-5">
    <app-ocean-blue-list [title]="title" [items]="items" (onItemClick)="onCardClick($event)"
    [shortList]="categoriesView"></app-ocean-blue-list>
  </div>
  <div class="player-container col-md-7">
    <app-player [url]="selectedStream?.url" [type]="0"
      (fullscreenChanged)="closeFullSreen()"></app-player>
      <app-stb-extreme-short-epg *ngIf="selectedStream?.id" [streamId]="selectedStream.id"
      [shortEpgForChannelList]="false" [currentCategory]="currentCategory.id"></app-stb-extreme-short-epg>
  </div>
</div>
<div class="control-info">
  <span class="red-mark"></span>
  {{'favorite' | translate}}
</div>