import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeStbExtremeComponent } from './components/stb-extreme/home-stb-extreme/home-stb-extreme.component';
import { ApiService } from './services/api.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInfoService } from './services/app-info.service';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatOptionModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlayerComponent } from './components/player/player.component';
import { LoginComponent } from './components/login/login.component';
import { FernHomeButtonComponent } from './components/fern-home-button/fern-home-button.component';
import { StbExtremeCategoryListComponent } from './components/stb-extreme/stb-extreme-category-list/stb-extreme-category-list.component';
import { StbExtremeItemsListComponent } from './components/stb-extreme/stb-extreme-items-list/stb-extreme-items-list.component';

import { LandingComponent } from './components/landing/landing.component';

import { ThemeSelectionComponent } from './components/theme-selection/theme-selection.component';
import { HomeFernComponent } from './components/fern/home-fern/home-fern.component';
import { StbExtremeShortEpgComponent } from './components/stb-extreme/stb-extreme-short-epg/stb-extreme-short-epg.component';
import { StbExtremeShortEpgListingComponent } from './components/stb-extreme/stb-extreme-short-epg/stb-extreme-short-epg-listing/stb-extreme-short-epg-listing.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CacheService } from './services/cache.service';
import { CachingComponent } from './components/caching/caching.component';
import { Base64DecodePipe } from './helpers/base64-decode.pipe';
import { StbExtremeStreamInfoComponent } from './components/stb-extreme/stb-extreme-stream-info/stb-extreme-stream-info.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { SortDialogComponent } from './components/sort-dialog/sort-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { StbExtremeTvArchiveChannelListComponent } from './components/stb-extreme/stb-extreme-tv-archive-channel-list/stb-extreme-tv-archive-channel-list.component';
import { environment } from '../environments/environment';
import { StbExtremeTvArhiveDetailsComponent } from './components/stb-extreme/stb-extreme-tv-archive-details/stb-extreme-tv-arhive-details.component';
import { MultiEpgComponent } from './components/multi-epg/multi-epg.component';
import { ToNumberPipe } from './helpers/string-to-number.pipe';
import { EpgInfoComponent } from './components/epg-info/epg-info.component';
import { AuthGuardService } from './services/auth-guard.service';
import { StbExtremeTvShowSeasonsListComponent } from './components/stb-extreme/stb-extreme-tv-show-seasons-list/stb-extreme-tv-show-seasons-list.component';
import { StbExtremeTvShowEpisodesListComponent } from './components/stb-extreme/stb-extreme-tv-show-episodes-list/stb-extreme-tv-show-episodes-list.component';
import { ContinuePlayingDialogComponent } from './components/continue-playing-dialog/continue-playing-dialog.component';
import { IsVisible } from './directives/is-visible.directive';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsHeaderComponent } from './components/settings/settings-header/settings-header.component';
import { ChangeLanguageComponent } from './components/settings/change-language/change-language.component';
import { ParentalControlsComponent } from './components/settings/parental-controls/parental-controls.component';
import { CategoryHidingComponent } from './components/settings/category-hiding/category-hiding.component';
import { MinorCategoryListComponent } from './components/settings/category-hiding/minor-category-list/minor-category-list.component';
import { ChangePinComponent } from './components/settings/change-pin/change-pin.component';
import { UserInfoComponent } from './components/settings/user-info/user-info.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StbExtremeDetailsComponent } from './components/stb-extreme/stb-details/stb-details.component';
import { ChangeDnsComponent } from './components/settings/change-dns/change-dns.component';
import { AppConfig } from './app.config';
import { SpeedTestComponent } from './components/settings/speed-test/speed-test.component';
import { FormatDatePipe } from './helpers/date-format.pipe';
import { SpeedTestModule } from 'ng-speed-test';
import { RadioComponent } from './components/radio/radio.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { PlayerSettingsComponent } from './components/settings/player-settings/player-settings.component';
import { FormatTitlePipe } from './helpers/title-format.pipe';
import { PlayerControlsComponent } from './components/player/player-controls/player-controls.component';
import { TimedProgressComponent } from './components/player/timed-progress/timed-progress.component';
import { SelectProfileComponent } from './components/select-profile/select-profile.component';
import { CreateProfileComponent } from './components/create-profile/create-profile.component';
import { HomeOceanBlueComponent } from './components/ocean-blue/home-ocean-blue/home-ocean-blue.component';
import { GenericService } from './services/generic.service';
import { AuthService } from './services/auth.service';
import { LicenceComponent } from './components/licence/licence.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { PcMinorCategoryListComponent } from './components/settings/parental-controls/minor-category-list/pc-minor-category-list.component';
import { PinRequiredDialogComponent } from './components/pin-required-dialog/pin-required-dialog.component';
import { StbExtremeMovieViewComponent } from './components/stb-extreme/stb-extreme-movie-view/stb-extreme-movie-view.component';
import { LogService } from './services/log.service';
import { LogPublishersService } from './services/log-publishers.service';
import { GlobalErrorHandler } from './helpers/global-error.handler';
import { OceanBlueGridComponent } from './components/ocean-blue/ocean-blue-grid/ocean-blue-grid.component';
import { TVChannelsOceanBlueComponent } from './components/ocean-blue/tv-channels-ocean-blue/tv-channels-ocean-blue.component';
import { OceanBlueListComponent } from './components/ocean-blue/ocean-blue-list/ocean-blue-list.component';
import { OceanBlueHeaderComponent } from './components/ocean-blue/ocean-blue-header/ocean-blue-header.component';
import { MoviesOceanBlueComponent } from './components/ocean-blue/movies-ocean-blue/movies-ocean-blue.component';
import { TvShowsOceanBlueComponent } from './components/ocean-blue/tv-shows-ocean-blue/tv-shows-ocean-blue.component';
import { RadioOceanBlueComponent } from './components/ocean-blue/radio-ocean-blue/radio-ocean-blue.component';
import { RemoveYearPipe } from './helpers/remove-year.pipe';
import { BooleanToStringPipe } from './helpers/boolean-to-string.pipe';
import { EvelinService } from './services/evelin.service';
import { HomeUserInfoComponent } from './components/stb-extreme/home-stb-extreme/user-info/home-user-info.component';
import { ExitDialogComponent } from './components/exit-dialog/exit-dialog.component';
import { NotificationComponent } from './components/stb-extreme/notification/notification.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeStbExtremeComponent,
    LanguageSelectComponent,
    PlayerComponent,
    LoginComponent,
    FernHomeButtonComponent,
    StbExtremeCategoryListComponent,
    StbExtremeItemsListComponent,
    LandingComponent,
    CachingComponent,
    ThemeSelectionComponent,
    HomeFernComponent,
    StbExtremeShortEpgComponent,
    StbExtremeShortEpgListingComponent,
    Base64DecodePipe,
    StbExtremeStreamInfoComponent,
    SortDialogComponent,
    StbExtremeTvArchiveChannelListComponent,
    StbExtremeTvArhiveDetailsComponent,
    MultiEpgComponent,
    ToNumberPipe,
    EpgInfoComponent,
    StbExtremeTvShowSeasonsListComponent,
    StbExtremeTvShowEpisodesListComponent,
    StbExtremeDetailsComponent,
    ContinuePlayingDialogComponent,
    SettingsComponent,
    SettingsHeaderComponent,
    ChangeLanguageComponent,
    ParentalControlsComponent,
    PcMinorCategoryListComponent,
    CategoryHidingComponent,
    MinorCategoryListComponent,
    ChangePinComponent,
    UserInfoComponent,
    ChangeDnsComponent,
    SpeedTestComponent,
    IsVisible,
    FormatDatePipe,
    BooleanToStringPipe,
    FormatTitlePipe,
    RemoveYearPipe,
    RadioComponent,
    PlayerSettingsComponent,
    PlayerControlsComponent,
    TimedProgressComponent,
    SelectProfileComponent,
    CreateProfileComponent,
    HomeOceanBlueComponent,
    LicenceComponent,
    EditProfileComponent,
    PinRequiredDialogComponent,
    StbExtremeMovieViewComponent,
    OceanBlueGridComponent,
    TVChannelsOceanBlueComponent,
    OceanBlueListComponent,
    OceanBlueHeaderComponent,
    MoviesOceanBlueComponent,
    TvShowsOceanBlueComponent,
    RadioOceanBlueComponent,
    HomeUserInfoComponent,
    ExitDialogComponent,
    NotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatSelectModule,
    MatSliderModule,
    MatOptionModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatListModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatRadioModule,
    SpeedTestModule,
    MatProgressSpinnerModule 
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    AuthGuardService,
    ApiService,
    EvelinService,
    GenericService,
    AuthService,
    AppInfoService,
    AppConfig,
    CacheService,
    LogService,
    LogPublishersService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    ...environment.providers,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '-10.json');
}
