import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SortingHelper } from '../../helpers/sorting.helper';
import { ThemeHelper } from '../../helpers/theme.helper';
import { StreamOptionType } from '../../models/category.model';
import { Stream, RadioStream } from '../../models/stream.model';
import { Theme } from '../../models/theme.model';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { AppInfoService } from '../../services/app-info.service';
import { GenericService } from '../../services/generic.service';
import { PlayerComponent } from '../player/player.component';
import { SortDialogComponent } from '../sort-dialog/sort-dialog.component';
import { Location } from '@angular/common';
import { PlayerState } from '../player/player.models';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent implements OnInit {
  @ViewChildren('channelsListPlaying') channelsListPlaying: QueryList<any>;
  @ViewChild('channelsListButtonBack') channelsListButtonBack: ElementRef;

  @ViewChild('channelsListButtonSearch') channelsListButtonSearch: ElementRef;

  @ViewChild('channelsListButtonSort') channelsListButtonSort: ElementRef;
  @ViewChild('channelsListSearchInput') channelsListSearchInput: ElementRef;
  @ViewChild(PlayerComponent) player: PlayerComponent;

  @ViewChild('controlButtons') controlButtons: ElementRef;
  @ViewChild('blackScreenElement') blackScreenElement: ElementRef;

  numberButtonsStep: number;
  currentCategory: number;
  currentType: StreamOptionType = StreamOptionType.Radio;
  currentTitle: string;
  watchItems: RadioStream[];
  allItems: any[];
  allItemsPointer: number = 0;
  watchItemsBackup: RadioStream[];

  public selectedTheme: string;
  selectedStream: RadioStream = new RadioStream();

  sortVisible = false;
  sortingOption = 'default';
  searchValue = '';
  searchBoxVisible = false;

  numberHeaderButtonsStep: number = 0;
  channelsListHeaderButtons: any;
  screenClass: string = 'min-screen';

  isPlayerFullScreen = false;
  isPlayerHidden = true;
  canPlay = false;
  streamInfo: Array<any> | null = null;

  pressedKeys: string = "";

  public browserVersion = '';
  muted = false;
  blackScreen = false;
  playing = false;
  pagingPointer = 2;

  constructor(
    private route: ActivatedRoute,
    private appInfoService: AppInfoService,
    private genericService: GenericService,
    private platformService: BasePlatformService,
    public dialog: MatDialog,
    private location: Location,
    public translate: TranslateService,
    private evelinService: EvelinService
  ) { }

  ngOnInit(): void {
    this.browserVersion = this.genericService.getBrowserVersion();
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );

    this.route.params.subscribe((params: Params) => {
      if (params['category']) {
        this.currentCategory = parseInt(params['category']);
      }
    });

    this.regenerateComponent();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.changeFocusChannelsButtons(0);
      this.channelsListHeaderButtons = [
        this.channelsListButtonBack,
        this.channelsListButtonSearch,
        this.channelsListButtonSort,
      ];
    }, 1000);
  }

  fillWatchItems() {    
    this.watchItems = this.allItems
      ? this.allItems.slice(
        this.allItemsPointer * 35,
        this.allItemsPointer * 35 + 35
      )
      : this.allItems;

      this.evelinService.getPaginatedRadiosFromCategory(this.currentCategory, this.pagingPointer, 35)
      .then(result => {
        this.allItems.concat(result.data);
        this.pagingPointer++;
      });
  }

  async regenerateComponent() {
    this.allItems = (await this.evelinService.getAllRadiosFromCategory(this.currentCategory)).data as Stream[];
    this.fillWatchItems();

    console.log(this.watchItems)
    this.currentTitle = "Radio";

    this.watchItemsBackup = this.watchItems;
  }

  async channelClicked(channel: RadioStream) {
    this.selectedStream = channel;
  }

  channelFocusTimeout: any;
  channelFocused(channel: Stream) {
    if (this.channelFocusTimeout) clearTimeout(this.channelFocusTimeout);
  }

  toggleSortDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '900px';
    dialogConfig.height = '400px';
    dialogConfig.data = { sortingOption: this.sortingOption };
    const dialogRef = this.dialog.open(SortDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((sort) => {
      if (sort == 'default') {
        this.allItems = this.allItems.sort(
          SortingHelper.dynamicSort('num')
        );
      } else if (sort == 'asc') {
        this.allItems = this.allItems.sort(
          SortingHelper.dynamicSort('name')
        );
      } else if (sort == 'desc') {
        this.allItems = this.allItems.sort(
          SortingHelper.dynamicSort('-name')
        );
      } else if (sort == 'date') {
        this.allItems = this.allItems.sort((a: any, b: any) => parseInt(a.added) > parseInt(b.added) ? -1 : 1);
      }

      this.allItemsPointer = 0;
      this.numberButtonsStep = 0;
      this.fillWatchItems();

      this.sortingOption = sort;
    });
  }

  toggleSearchBox() {
    this.searchBoxVisible = !this.searchBoxVisible;
    setTimeout(() => {
      this.channelsListSearchInput.nativeElement.focus();
    }, 100);
  }

  hideSearchBox() {
    this.channelsListHeaderButtons[
      this.numberHeaderButtonsStep
    ].nativeElement.focus();
    this.searchBoxVisible = !this.searchBoxVisible;
  }

  searchValueChanged(e: any) {
    this.searchValue = e.target.value;
    this.watchItems = this.allItems.filter((x) =>
      x.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
  }

  goBack() {
    this.location.back();
  }

  changeFocusChannelsButtons(step: number) {
    if (this.watchItems.length - 1 == this.numberButtonsStep && step > 0) {
      //set range for movement
      if (this.watchItems.length === 35) {
        this.allItemsPointer++;
        this.fillWatchItems();
        this.numberButtonsStep = 0;
        setTimeout(() => this.focusItem(this.numberButtonsStep), 50);
      }
      return;
    }

    if (this.numberButtonsStep == 0 && step < 0) {
      if (this.allItemsPointer > 0) {
        this.allItemsPointer--;
        this.fillWatchItems();
        this.numberButtonsStep = 29;
        setTimeout(() => this.focusItem(this.numberButtonsStep), 50);
      } else
        this.changeFocusHomeChannelHeaderButtons(0); // set focus on first button on header
      return;
    }

    if (step > 0) {
      this.numberButtonsStep++;
    } else if (step < 0) {
      this.numberButtonsStep--;
    } else if (step == 0) {
      this.numberButtonsStep = 0;
    }

    this.focusItem(this.numberButtonsStep);
  }

  focusItem = (index: number): void => {
    this.channelsListPlaying
      .toArray()[0]
      .nativeElement.children[index].children[0].focus();
  }

  changeChannel(step: number) {
    this.pressedKeys += " " + step;

    if (step > 0) {
      this.numberButtonsStep++;
    } else if (step < 0) {
      this.numberButtonsStep--;
    } else if (step == 0) {
      this.numberButtonsStep = 0;
    }

    this.channelsListPlaying
      .toArray()[0]
      .nativeElement.children[this.numberButtonsStep].children[0].click();
  }

  onKeydownHeaderSearchInput(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        break;
      case 'Enter':
        break;
      case 'Right':
      case 'BrowserBack':
        this.hideSearchBox();
        break;
      default:
        break;
    }
  }

  onKeydownHeaderButtons(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        this.changeFocusChannelsButtons(0);
        break;
      case 'Enter':
        this.channelsListHeaderButtons[
          this.numberHeaderButtonsStep
        ].nativeElement.click();
        break;
      case 'Left':
        this.changeFocusHomeChannelHeaderButtons(-1);
        break;
      case 'Right':
        this.changeFocusHomeChannelHeaderButtons(1);
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  changeFocusHomeChannelHeaderButtons(step: number) {
    if (
      (3 == this.numberHeaderButtonsStep && step > 0) ||
      (this.numberHeaderButtonsStep == 0 && step < 0)
    )
      //set range for movement
      return;

    if (step > 0) {
      this.numberHeaderButtonsStep++;
    } else if (step < 0) {
      this.numberHeaderButtonsStep--;
    } else if (step == 0) {
      this.numberHeaderButtonsStep = 0;
    }

    this.channelsListHeaderButtons[
      this.numberHeaderButtonsStep
    ].nativeElement.focus();
  }

  onKeydown(e: any, channel: RadioStream) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.changeFocusChannelsButtons(-1);
        break;
      case 'Down':
        this.changeFocusChannelsButtons(1);
        break;
      case 'Left':
        this.goBack();
        break;
      case 'Right':
        this.selectButton(0);
        break;
      case 'Enter':
        if (channel.id == -1)
          this.goToTop();
        else
          this.channelClicked(channel);
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  goToTop() {
    this.allItemsPointer = 0;
    this.fillWatchItems();
    this.numberButtonsStep = 0;
    setTimeout(() => this.focusItem(this.numberButtonsStep), 20);
  }

  selectButton(index) {
    this.controlButtons.nativeElement.children[index]?.focus();
  }

  onControlKeydown(e: any, index) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Left':
        if (index > 0) {
          index--;
          this.selectButton(index);
        } else {
          this.focusItem(this.numberButtonsStep);
        }
        break;
      case 'Right':
        index++;
        this.selectButton(index);
        break;
      case 'Enter':
        this.onControlClick(index);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onControlClick(index) {
    switch (index) {
      case 0:
        if (this.numberButtonsStep > 0) {
          this.numberButtonsStep--;
          this.focusItem(this.numberButtonsStep);
          this.channelsListPlaying
            .toArray()[0]
            .nativeElement.children[this.numberButtonsStep].children[0].click();
          this.selectButton(index);
        }
        break;
      case 1:
        this.player.togglePlay();
        this.playing = this.player.playerState === PlayerState.Playing;
        break;
      case 2:
        this.numberButtonsStep++;
        this.focusItem(this.numberButtonsStep);
        this.channelsListPlaying
          .toArray()[0]
          .nativeElement.children[this.numberButtonsStep].children[0].click();
        this.selectButton(index);
        break;
      case 3:
        this.muted = !this.muted;
        this.player.mute();
        break;
      case 4:
        this.blackScreen = true;
        this.blackScreenElement.nativeElement.focus();
        break;
      default:
        break;
    }
  }

  exitBlackScreen() {
    this.blackScreen = false;
    this.blackScreenElement.nativeElement.focus();
    this.selectButton(4);
  }

  onFocus(channel: Stream) {
    this.channelFocused(channel);
  }
}
