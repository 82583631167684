import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AppInfoService } from "./app-info.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private appInfoService: AppInfoService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    let tokenExists = this.appInfoService.tokenExists();

    if (!tokenExists && this.shouldCheckValidityOnCurrentUrl()) {
      this.router.navigate(["/landing"]);
      return false;
    }

    return true;
  }

  private shouldCheckValidityOnCurrentUrl() {
    let url = this.router.url;
    if (
      url.includes('landing') ||
      url.includes('login') ||
      url.includes('language-select') ||
      url.includes('caching') ||
      url.includes('theme-selection')
    ) {
      return false;
    }

    return true;
  }
}