import { Injectable } from '@angular/core';
import { LogEntry, LogLevel } from '../models/log-entry.model';
import { LogPublisher } from '../helpers/log-publisher';
import { LogPublishersService } from './log-publishers.service';
import * as Constants from '../helpers/constants.helper';
import { UserInfoV2 } from '../models/login.model';

@Injectable()
export class LogService {
  publishers: LogPublisher[];
  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  username = JSON.parse(
    localStorage.getItem(Constants.fullAuthDataV2) as string
  ) as UserInfoV2;

  constructor(private publishersService: LogPublishersService) {
    this.publishers = this.publishersService.publishers;
  }

  debug(msg: string, optionalParams: string = "") {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, optionalParams: string = "") {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, optionalParams: string = "") {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, optionalParams: string = "") {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, optionalParams: string = "") {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, optionalParams: string = "") {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  private writeToLog(msg: string, level: LogLevel, params: string) {
    if (this.shouldLog(level)) {
      let entry: LogEntry = new LogEntry();
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = this.username?.username + " --- " + params;
      for (let logger of this.publishers) {
        logger.log(entry).subscribe(response => console.log(response));
      }
    }
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      ret = true;
    }
    return ret;
  }
}