import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { AuthService } from 'src/app/services/auth.service';
import { version } from 'src/app/models/version';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExitDialogComponent } from '../exit-dialog/exit-dialog.component';
import { exitApplication } from '../../platforms/tizen/utils/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('loginUsername') inputUsername: ElementRef;
  @ViewChild('loginPassword') inputPassword: ElementRef;
  @ViewChild('buttonPassword') buttonPassword: ElementRef;
  @ViewChild('backBtn', { read: ElementRef, static: false })
  backButton: ElementRef;
  @ViewChild('loginSubmit', { read: ElementRef, static: false })
  private loginSubmit: ElementRef;
  @ViewChild('passwordlessLoginBtn', { read: ElementRef, static: false })
  private passwordlessLoginBtn: ElementRef;
  @ViewChild('passwordlessPinInput') inputPin: ElementRef;

  public usernameTxt: string;
  public passwordTxt: string;

  public username: string = '';
  public password: string = '';
  public pin: string = '';
  public hide = true;
  public passwordlessLogin = true;
  public backButtonVisible = false;

  public appVersion = version;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private platformService: BasePlatformService,
    private location: Location,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {
    this.generateIcons(iconRegistry, sanitizer);
  }

  ngOnInit() {
    this.pin = Math.floor(100000 + Math.random() * 900000).toString();
    if (this.router.url.indexOf('change-user') != -1) {
      this.backButtonVisible = true;
    }
  }

  async ngAfterViewInit() {
    this.passwordlessLoginBtn.nativeElement.focus();

    await this.initiatePasswordlessLogin();
  }

  public callingInterval;
  private async initiatePasswordlessLogin() {
    let res = await this.authService.passwordlessLogin(this.pin);

    if (res == 401) {
      this.callingInterval = setInterval(async () => {
        res = await this.authService.passwordlessLogin(this.pin);
        if (res == true) {
          clearInterval(this.callingInterval);

          this.router.navigate(['/landing']);
        }
      }, 10000);
    }
  }

  public async onSubmit(): Promise<void> {
    let res = await this.authService.login(this.username, this.password);

    this.handleServiceResponse(res);
  }

  private handleServiceResponse(res: any) {
    if (res == true) {
      clearInterval(this.callingInterval);
      this.router.navigate(['/landing']);
    } else if (res == 403) {
      this.username = '';
      this.password = '';
      this.setFocus(this.inputUsername);
      window.alert('Nemate dovoljno kredita! ');
    } else {
      let message = this.translate.instant('login.error-message');
      window.alert(message);
      setTimeout(() => {
        this.username = '';
        this.password = '';
        this.setFocus(this.inputUsername);
      }, 800);
    }
  }

  setFocus(element: any) {
    const ele = element?.nativeElement;
    if (ele) {
      ele.focus();
    }
  }

  onKeydownUsername(e: any) {
    let key = this.platformService.keymapper(e);

    if (key == 'Down') {
      this.setFocus(this.inputPassword);
    }
    switch (key) {
      case 'Enter':
      case 'Select':
      case 'Accept':
        this.setFocus(this.inputPassword);
        e.preventDefault();
        break;
      case 'Down':
        this.setFocus(this.inputPassword);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownPassword(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Enter':
      case 'Select':
      case 'Accept':
        this.setFocus(this.loginSubmit);
        e.preventDefault();
        break;
      case 'Up':
        this.setFocus(this.inputUsername);
        break;
      case 'Down':
        this.setFocus(this.loginSubmit);
        break;
      case 'Right':
        console.log(this.buttonPassword);
        this.setFocus(this.buttonPassword);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownPasswordButton(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Ok':
        this.buttonPassword.nativeElement.click();
        break;
      case 'Left':
        this.setFocus(this.inputPassword);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownSubmit(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.setFocus(this.inputPassword);
        break;
      case 'Enter':
        this.onSubmit();
        break;
      case 'Down':
        this.passwordlessLoginBtn.nativeElement.focus();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownPasswordlessLogin(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.setFocus(this.loginSubmit);
        break;
      case 'Down':
        if (this.backButtonVisible) this.setFocus(this.backButton);
        break;
      case 'Enter':
        this.passwordlessLogin = !this.passwordlessLogin;
        this.passwordlessLoginBtn.nativeElement.click();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownBack(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.setFocus(this.passwordlessLoginBtn);
        break;
      case 'Enter':
        this.router.navigate(['/settings']);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onPasswordlessLogin() {
    this.passwordlessLogin = !this.passwordlessLogin;
  }

  goBack() {
    if (this.backButtonVisible) {
      this.router.navigate(['/settings']);
    } else {
      this.openExitDialog();
    }

    //this.location.back();
  }

  private generateIcons(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry
      .addSvgIcon(
        'user',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/onboarding/user_icon.svg'
        )
      )

      .addSvgIcon(
        'password',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/onboarding/password_icon.svg'
        )
      );

    this.translate
      .get('login.username')
      .toPromise()
      .then((res) => {
        this.usernameTxt = res;
      });
    this.translate
      .get('login.password')
      .toPromise()
      .then((res) => {
        this.passwordTxt = res;
      });
  }

  async openExitDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '80rem';
    dialogConfig.height = '10rem';

    const dialogRef = this.dialog.open(ExitDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((shouldExit) => {
      if (shouldExit) this.authService.exitApplication();
    });
  }
}
