import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppInfoService } from '../../../services/app-info.service';
import { HomeBaseComponent } from '../../home-base/home-base.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home-fern',
  templateUrl: './home-fern.component.html',
  styleUrls: ['./home-fern.component.css'],
})
export class HomeFernComponent extends HomeBaseComponent {
  constructor(
    public router: Router,
    public appInfo: AppInfoService,
    public translate: TranslateService,
    public matDialog: MatDialog
  ) {
    super(router, appInfo, translate, matDialog);
  }

  ngOnInit(): void {}
}
