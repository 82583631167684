import {
  Component, ElementRef, ViewChild,
} from '@angular/core';
import { AppInfoService } from '../../../../services/app-info.service';
import { Router } from '@angular/router';
import { UserInfoV2 } from '../../../../models/login.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { IpInfo } from 'src/app/models/ipinfo.model';

import { EvelinService } from '../../../../services/evelin.service';
import { NotificationBase } from '../../../../models/client-notification.model';

@Component({
  selector: 'home-user-info',
  templateUrl: './home-user-info.component.html',
  styleUrls: ['./home-user-info.component.css'],
})
export class HomeUserInfoComponent {
  selectedTheme: string = 'stb-extreme';
  userInfo: UserInfoV2;
  expiryDate: Date;
  ipInfo = new IpInfo();
  currentProfile: any;
  deviceInfo: any;
  showInfo = false;
  showInfoText = false;
  greetingMessage = "";
  @ViewChild('info') info: ElementRef;
  notifications: NotificationBase[] = [];

  constructor(
    public router: Router,
    public appInfo: AppInfoService,
    public translate: TranslateService,
    private authService: AuthService,
    private evelinService: EvelinService
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      this.userInfo = this.appInfo.getUserInfoV2;
      if (this.userInfo.exp_date != null)
        this.expiryDate = new Date(
          parseInt(this.userInfo.exp_date as string) * 1000
        );
      else
        this.expiryDate = new Date(2100, 1, 1, 1, 1, 1, 1);

      this.currentProfile = this.appInfo.getCurrentProfile();
      console.log(this.currentProfile);
      this.ipInfo = this.appInfo.getIpAddress();
      this.deviceInfo = this.authService.getDeviceInfo;

      const currentHour = new Date().getHours();
      if (currentHour >= 6 && currentHour < 12)
        this.greetingMessage = "greeting.message1";
      else if (currentHour >= 12 && currentHour < 18)
        this.greetingMessage = "greeting.message2";
      else if (currentHour >= 18 || currentHour < 6)
        this.greetingMessage = "greeting.message3";

    } catch (e) {
      console.log("not tizen");
    }
    //this.refreshNotifications();
  }

  refreshNotifications() {
    this.evelinService.getClientNotifications()
      .then(notifications => {
        console.log(notifications);
        this.notifications = notifications.filter(n => !n.read);
      });
  }

  setDisplayState(state: boolean) {
    this.showInfo = state;
    if (state) {
      this.info.nativeElement.focus();
      setTimeout(() => this.showInfoText = this.showInfo, 400);      
      this.refreshNotifications();
    }
    else
      this.showInfoText = false;
  }
}