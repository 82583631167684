import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppInfoService } from '../../services/app-info.service';
import { StreamOptionType } from '../../models/category.model';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { PlayerControlEvents, PlayerState } from './player.models';
import { GenericService } from '../../services/generic.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css'],
})
export class PlayerComponent implements OnInit {
  @ViewChild('videoContainer') videoContainer: ElementRef;
  @ViewChild('ratioContainer') ratioContainer: ElementRef;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild('infoDiv') infoDiv: ElementRef;

  @Input() url: string;
  @Input() type: StreamOptionType;
  @Output() fullscreenChanged = new EventEmitter<boolean>();
  @Output() onVideoChanged = new EventEmitter<number>();
  isFullScreen = false;
  _errorCheckInterval: any = null;
  _lastStream: string;
  info: any[] | null = null;
  icon: string;
  timeoutObject: any;

  smallScreen = false;
  settings: any;
  autoplaying = false;
  buffering = 0;
  progressDuration: number;

  playerState: PlayerState = 0;
  playerErrorCounter = 0;
  hiddenCounter = 0;
  shouldRestart = false;
  version = "";

  constructor(
    private appInfoService: AppInfoService,
    private platformService: BasePlatformService,
    private genericService: GenericService
  ) { }

  ngOnInit(): void {
    this.settings = this.appInfoService.getPlayerSettings();
    this.version = this.genericService.getBrowserVersion();
  }

  ngOnDestroy(): void {
    clearInterval(this._errorCheckInterval);
  }

  ngOnChanges() {
    if (this.url && this.type !== StreamOptionType.TVArchive)
      this.play();
  }

  savedDuration = 0;
  private _lastStreamRetryTo: any = null;
  retryLastStream(): void {
    clearTimeout(this._lastStreamRetryTo);
    this._lastStreamRetryTo = setTimeout(() => {

      if (this.type !== StreamOptionType.TVChannels)
        this.savedDuration = this.videoplayer.nativeElement.currentTime;

      this.videoplayer.nativeElement.src = this._lastStream;
      this.videoplayer.nativeElement.load();
      this.videoplayer.nativeElement.play();

      if (this.type !== StreamOptionType.TVChannels)
        this.videoplayer.nativeElement.currentTime = this.savedDuration;
    }, 1000);
  }

  errorWatch(): void {
    clearInterval(this._errorCheckInterval);
    let _counter = 0;
    this.hiddenCounter = 0;
    this.playerErrorCounter = 0;
    this._errorCheckInterval = setInterval(() => {
      if (this.type !== 0) {
        if (this.videoplayer.nativeElement.readyState != 4)
          _counter++;
        else
          _counter = 0;
      } else if (!this.version.includes("Tizen 5.0")) {
        if (this.playerErrorCounter === this.videoplayer.nativeElement.currentTime)
          _counter++;
        else
          _counter = 0;
        this.playerErrorCounter = this.videoplayer.nativeElement.currentTime;
      }

      if (_counter > 20) {
        this.retryLastStream();
        _counter = 0;
      }
    }, 1000);
  }

  async play(duration = 0, startTime = "") {
    this._lastStream = this.url.split("https").join("http");
    if (this._lastStream.indexOf('token') === -1)
      this._lastStream += "?token=" + this.appInfoService.getToken();
    if (this._lastStream.indexOf('6287422evelin=true') === -1 && this.type === StreamOptionType.TVChannels)
      this._lastStream += "&6287422evelin=true";

    this._lastStream+="&type=m3u&output=hls";
    console.log(this._lastStream)

    this.videoplayer.nativeElement.src = this._lastStream;

    this.errorWatch();
    this.videoplayer.nativeElement.load();

    if (this.settings?.bufferingTime) {
      this.progressDuration = this.settings?.bufferingTime;
      setTimeout(() => {
        this.progressDuration = 0;
        this.videoplayer.nativeElement.play();
      }, this.settings?.bufferingTime * 1000);
    }
    else {
      this.videoplayer.nativeElement.play();
    }

    this.playerState = PlayerState.Playing;
  }

  setFullScreen(streamInfo = null, icon = "") {
    this.isFullScreen = true;
    if (streamInfo)
      this.info = streamInfo;
    this.icon = icon;
    if (this.type === StreamOptionType.TVChannels)
      setTimeout(() => this.info = null, 10000);
    setTimeout(() => this.videoContainer.nativeElement.focus(), 100);
  }

  closeFullScreen() {
    if (this.type !== StreamOptionType.TVChannels)
      this.videoplayer.nativeElement.pause();

    if (this.smallScreen)
      this.changeRatio();

    this.isFullScreen = false;
    this.fullscreenChanged.emit(false);
  }

  changeVideo(step: number) {
    this.onVideoChanged.emit(step);
  }

  onControlChange(event: PlayerControlEvents) {
    if (event === PlayerControlEvents.Close)
      this.closeFullScreen();
    else if (event === PlayerControlEvents.ChangeRatio)
      this.changeRatio();
    else
      this.togglePlay();
  }

  togglePlay() {
    if (this.playerState === PlayerState.Playing) {
      this.videoplayer.nativeElement.pause();
      this.playerState = PlayerState.Paused;
    } else if (this.playerState === PlayerState.Paused) {
      this.videoplayer.nativeElement.play();
      this.playerState = PlayerState.Playing;
    }
  }

  mute() {
    this.videoplayer.nativeElement.muted = !this.videoplayer.nativeElement.muted;
  }

  changeRatio() {
    if (this.smallScreen) {
      this.smallScreen = false;
      this.ratioContainer.nativeElement.style.width = "100%";
    } else {
      this.smallScreen = true;
      this.ratioContainer.nativeElement.style.width = this.ratioContainer.nativeElement.offsetHeight * 4 / 3 + "px";
    }
  }

  setTime(time: number) {
    this.videoplayer.nativeElement.currentTime = time;
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Up':
      case 'ChannelMinus':
        this.changeVideo(-1);
        break;
      case 'Down':
      case 'ChannelPlus':
        this.changeVideo(1);
        break;
      case 'Left':
      case 'Right':
        this.changeRatio();
        break;
      case 'Enter':
        if (this.type === StreamOptionType.TVChannels)
          this.closeFullScreen();
        break;
      case 'BrowserBack':
        this.closeFullScreen();
        break;
      default:
        break;
    }
  }

}
