export class TvShowInfo {
  seasons?: any[];
  info?: Info;
  episodes: Record<string, Episode[]>;
}

export class Episode {
  id?: string;
  episode_num?: number;
  title?: string;
  container_extension?: string;
  info?: EpisodeInfo;
  custom_sid?: string;
  added?: string;
  season?: number;
  direct_source?: string;
}

export class EpisodeInfo {
  releasedate?: string;
  plot?: string;
  duration_secs?: number;
  duration?: string;
  video?: Video;
  audio?: Audio;
  bitrate?: number;
  rating?: string;
  season?: string;
  tmdb_id?: string;
}

export class Audio {
  index?: number;
  codec_name?: string;
  codec_long_name?: string;
  profile?: string;
  codec_type?: string;
  codec_time_base?: string;
  codec_tag_string?: string;
  codec_tag?: string;
  sample_fmt?: string;
  sample_rate?: string;
  channels?: number;
  channel_layout?: string;
  bits_per_sample?: number;
  r_frame_rate?: string;
  avg_frame_rate?: string;
  time_base?: string;
  start_pts?: number;
  start_time?: string;
  disposition?: Disposition;
  tags?: Tags;
}

export class Tags {
  title?: string;
  ENCODER?: string;
  DURATION?: string;
}

export class Video {
  index?: number;
  codec_name?: string;
  codec_long_name?: string;
  profile?: string;
  codec_type?: string;
  codec_time_base?: string;
  codec_tag_string?: string;
  codec_tag?: string;
  width?: number;
  height?: number;
  coded_width?: number;
  coded_height?: number;
  has_b_frames?: number;
  sample_aspect_ratio?: string;
  display_aspect_ratio?: string;
  pix_fmt?: string;
  level?: number;
  chroma_location?: string;
  field_order?: string;
  refs?: number;
  is_avc?: string;
  nal_length_size?: string;
  r_frame_rate?: string;
  avg_frame_rate?: string;
  time_base?: string;
  start_pts?: number;
  start_time?: string;
  bits_per_raw_sample?: string;
  disposition?: Disposition;
  tags?: Tags;
}

interface Disposition {
  default?: number;
  dub?: number;
  original?: number;
  comment?: number;
  lyrics?: number;
  karaoke?: number;
  forced?: number;
  hearing_impaired?: number;
  visual_impaired?: number;
  clean_effects?: number;
  attached_pic?: number;
  timed_thumbnails?: number;
}

interface Info {
  name?: string;
  cover?: string;
  plot?: string;
  cast?: string;
  director?: string;
  genre?: string;
  releaseDate?: string;
  last_modified?: string;
  rating?: string;
  rating_5based?: number;
  backdrop_path?: any[];
  youtube_trailer?: string;
  episode_run_time?: string;
  category_id?: string;
}

