<div [class]="theme">
    <div class="row">
      <button #epgButtonBack type="button" class="btn btn-secondary buttons-headers col-md-2" (click)="goBack()"
        (keydown)="onKeydownHeaderButtons($event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-left"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
        </svg>
      </button>
    </div>
    <div class="col-md-12">
      <div #channelList *ngFor="let item of channels; index as i" (click)="onChannelClicked(item)">
        <div class="title" *ngIf="isTitleItem(item)">
          {{formatTitle(item)}}
        </div>
        <div *ngIf="!isTitleItem(item)" class="channel-list-playing-card" [tabIndex]="1" (keydown)="onKeydown($event, item, i)">
          <span class="index"> {{ i }}</span>
          <img src="{{ item.icon }}" class="channel-icon" />
          <span class="channel-title">{{ item.name }}</span>
        </div>
      </div>
    </div>
</div>