<div class="row movie-details">
  <div class="info">
    <div class="title">{{details.name | formatTitle}}</div>
    <div class="item"><span>{{ "details.date" | translate }}: </span>{{details.releasedate || details.releaseDate?.split('-')[0] }}</div>
    <div class="item"><span>{{ "details.genre" | translate }}: </span>{{details.genre}}</div>
    <div class="item"><span>{{ "details.duration" | translate }}: </span>{{details.duration || details.episode_run_time}}</div>
    <hr />
    <div class="item"><span>{{ "details.director" | translate }}: </span>{{details.director}}</div>
    <div class="item"><span>{{ "details.cast" | translate }}: </span>{{details.cast}}</div>
  </div>
  <img [src]="details.cover_big || details.cover" class="streamIconImg" />
  <hr />
  <div class="item">{{details.description || details.plot}}</div>
</div>