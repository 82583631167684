<div [class]="selectedTheme" class="list-header">
  <button #buttonBack type="button" class="btn btn-secondary buttons-headers" (click)="onBackClicked()"
    (keydown)="onKeydown($event)">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left"
      viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
    </svg>
  </button>
  <label *ngIf="!searchBoxVisible" class="channel-list-header-title">{{ title }}</label>
  <input *ngIf="searchBoxVisible" #channelsListSearchInput placeholder="Pretraga" [value]="searchValue"
    (input)="searchValueChanged($event)" (keydown)="onKeydownInput($event)" />
  <button #buttonSort type="button" class="btn btn-secondary buttons-headers sort-button" (click)="toggleSortDialog()"
    (keydown)="onKeydown($event)">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-sort-down"
      viewBox="0 0 16 16">
      <path
        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
    </svg>
  </button>
  <button #buttonSearch type="button" class="btn btn-secondary buttons-headers search-button"
    (click)="toggleSearchBox()" (keydown)="onKeydown($event)">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-search"
      viewBox="0 0 16 16">
      <path
        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
    </svg>
  </button>
</div>