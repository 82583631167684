import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppInfoService } from './services/app-info.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { EvelinService } from './services/evelin.service';
import { exitApplication } from './platforms/tizen/utils/utils';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExitDialogComponent } from './components/exit-dialog/exit-dialog.component';
import { BasePlatformService } from './platforms/iplatform.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isOnline = true;
  constructor(
    private translate: TranslateService,
    private evelinService: EvelinService,
    private authService: AuthService,
    private appInfoService: AppInfoService,
    private router: Router,
    public dialog: MatDialog,
    private platformService: BasePlatformService
  ) {
    // this.translate.use('hr');

    window.addEventListener('online', () => {
      this.isOnline = true;
    });

    window.addEventListener('offline', () => {
      this.isOnline = false;
    });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    let key = this.platformService.keymapper(event);
    console.log(key);
    if (this.dialog.openDialogs && this.dialog.openDialogs.length) return;
    if (key === 'Exit' || key == '27') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.hasBackdrop = true;
      dialogConfig.width = '80rem';
      dialogConfig.height = '10rem';

      const dialogRef = this.dialog.open(ExitDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((shouldExit) => {
        if (shouldExit) exitApplication();
      });
    }
  }
  async ngOnInit(): Promise<void> {
    setTimeout(async () => await this.checkIfAuthorized());
  }

  async checkIfAuthorized() {
    if (this.shouldCheckValidityOnCurrentUrl()) {
      let tokenExists = this.appInfoService.tokenExists();
      let userValid = false;
      if (tokenExists) {
        userValid = await this.authService.isTokenValid();
      }

      if (!userValid) {
        console.log('User invalid: AppComponent');
        this.router.navigate(['/landing']);
      } else {
        console.log('Fetching system values...');
        this.appInfoService.setSystemValues(
          await this.evelinService.getSystemValues()
        );
      }
    }
    console.log('Checking if user is authorized...');
    setTimeout(() => this.checkIfAuthorized(), 300000);
  }

  private shouldCheckValidityOnCurrentUrl() {
    let url = this.router.url;
    if (
      url.includes('landing') ||
      url.includes('login') ||
      url.includes('language') ||
      url.includes('caching') ||
      url.includes('theme') ||
      url.includes('profile') ||
      url.includes('licence')
    ) {
      return false;
    }

    return true;
  }
}
