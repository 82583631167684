import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CompleteEpgListing, ShortEpgListing } from '../../../../models/epg.model';
import { Base64Decoder } from '../../../../helpers/base64-decode.pipe';

@Component({
  selector: 'app-stb-extreme-short-epg-listing',
  templateUrl: './stb-extreme-short-epg-listing.component.html',
  styleUrls: ['./stb-extreme-short-epg-listing.component.css']
})
export class StbExtremeShortEpgListingComponent implements OnInit {

  @ViewChild('textContainer') private textContainer: ElementRef;

  @Input() epgItem: CompleteEpgListing;
  @Input() progressBarVisible: boolean;
  @Input() focused: boolean = false;
  @Input() smallText: boolean = false;

  progressBarValue: number;
  title: string;
  marquee: boolean;

  constructor() { }

  ngOnInit(): void {
    if (this.epgItem && this.epgItem.title) {
      this.title = this.epgItem.title;
      if (this.progressBarVisible) {
        let start = new Date(this.epgItem.start_timestamp * 1000).getTime();
        let end = new Date(this.epgItem.end_timestamp * 1000).getTime();
        let total = end - start;
        let current = new Date().getTime() - start;
        var percentage = (current / total) * 100;
        this.progressBarValue = percentage;
      }
    }
  }

  ngOnChanges(): void {
    this.marquee = this.focused && this.textContainer?.nativeElement.scrollWidth > this.textContainer?.nativeElement.clientWidth;
  }

}
