import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppInfoService } from '../../services/app-info.service';
import { CategoryStream, StreamOptionType } from '../../models/category.model';
import { Router } from '@angular/router';

import { ThemeHelper } from '../../helpers/theme.helper';
import { Theme } from '../../models/theme.model';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { exitApplication } from '../../platforms/tizen/utils/utils';
import {
  LiveStream,
  MovieStream,
  RadioStream,
  Stream,
  TVShowStream,
} from '../../models/stream.model';
import { LogService } from '../../services/log.service';
import { EvelinService } from 'src/app/services/evelin.service';
import { version } from 'src/app/models/version';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExitDialogComponent } from '../exit-dialog/exit-dialog.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-caching',
  templateUrl: './caching.component.html',
  styleUrls: ['./caching.component.css'],
})
export class CachingComponent implements OnInit {
  @ViewChild('page', { read: ElementRef, static: true })
  private page: ElementRef;

  @ViewChild('buttonReload', { read: ElementRef, static: true })
  private buttonReload: ElementRef;
  @ViewChild('buttonProfile', { read: ElementRef, static: true })
  private buttonProfile: ElementRef;
  @ViewChild('buttonExit', { read: ElementRef, static: true })
  private buttonExit: ElementRef;
  progress: number = 0;
  progressLimiter = 0;
  progressInterval: any;

  public appVersion = version;

  constructor(
    private appInfoService: AppInfoService,
    private evelinService: EvelinService,
    private router: Router,
    private platformService: BasePlatformService,
    private logger: LogService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.page.nativeElement.className = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
    this.setFocus(this.buttonReload);

    this.progressInterval = setInterval(() => {
      if (this.progress < this.progressLimiter) this.progress++;

      if (this.progress >= 100) this.router.navigate(['/landing']);
    }, 100);

    await this.cacheBackendData();
    this.appInfoService.setCacheTime = new Date();
  }

  ngOnDestroy() {
    clearInterval(this.progressInterval);
  }

  sleep(ms: number) {
    return new Promise((r) => setTimeout(r, ms));
  }

  async cacheBackendData() {
    this.progressLimiter = 50;
    await this.cacheTvChannels();
    this.progressLimiter = 30;
    await this.cacheTvShows();
    this.progressLimiter = 45;
    await this.cacheMovies();
    this.progressLimiter = 60;
    await this.cacheRadioStreams();
    //this.progressLimiter = 75;
    await this.cacheFavourites();
    //this.progressLimiter = 90;
    //await this.xtreamService.getXmlTv();
    this.progressLimiter = 100;
  }

  extractStreamsFromCategories<T>(categories: CategoryStream[]): T[] {
    let temp = categories.map(
      (category: any) => category.streams || category.series
    );
    return [].concat.apply([], temp) as T[];
  }

  private async cacheTvChannels() {
    let categories = await this.evelinService.getAllCategoriesForLive();

    if (categories && categories.length) {
      this.appInfoService.cacheCategories(StreamOptionType.TVChannels, this.sortCategories(categories));
      let liveStreams = await this.evelinService.getLiveStreamsRoot();
      this.appInfoService.cacheTvChannels(liveStreams);
    } else {
      this.appInfoService.cacheCategories(StreamOptionType.TVChannels, []);
    }
  }

  private async cacheTvShows() {
    let categories = await this.evelinService.getAllCategoriesForTvShows();

    if (categories && categories.length) {
      this.appInfoService.cacheCategories(StreamOptionType.TVShows, this.sortCategories(categories));
    } else {
      this.appInfoService.cacheCategories(StreamOptionType.TVShows, []);
    }
  }

  private async cacheMovies() {    
    let categories = await this.evelinService.getAllCategoriesForMovies();

    if (categories && categories.length) {
      this.appInfoService.cacheCategories(StreamOptionType.Movies, this.sortCategories(categories));
    } else {
      this.appInfoService.cacheCategories(StreamOptionType.Movies, []);
    }
  }

  private async cacheRadioStreams() {
    let categories = await this.evelinService.getAllCategoriesForRadio();
    if (categories && categories.length) {
      this.appInfoService.cacheCategories(StreamOptionType.Radio, this.sortCategories(categories));

      let radioStreams = await this.evelinService.getRadiosRoot();
      this.appInfoService.cacheRadio(radioStreams);
    } else {
      this.appInfoService.cacheCategories(StreamOptionType.Radio, []);
    }
  }

  private async cacheFavourites() {
    let root = await this.evelinService.getFavouritesRoot();

    if (root && root.categories) {
      let streams = this.extractStreamsFromCategories<LiveStream>(
        root.categories
      );
      if (streams && streams.length) {
        let favsCategory = this.evelinService.generateFavuoritesCategory();
        this.appInfoService.appendFavouritesCategory(favsCategory);
        //this.appInfoService.cacheFavourites(streams);
      }
    }
  }

  setFocus(element: any) {
    const ele = element.nativeElement;
    if (ele) {
      setTimeout(() => {
        ele.focus();
      }, 0);
    }
  }
  onKeydownButtonReload(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key) {
      case 'Right':
        this.setFocus(this.buttonProfile);
        break;
      case 'Enter':
        this.router.navigate(['caching']);
        break;
      case 'BrowserBack':
        this.openExitDialog();
        break;
      default:
        break;
    }
  }
  onKeydownButtonProfile(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key) {
      case 'Left':
        this.setFocus(this.buttonReload);
        break;
      case 'Right':
        this.setFocus(this.buttonExit);
        break;
      case 'Enter':
        this.router.navigate(['select-profile', false, 0]);
        break;
      case 'BrowserBack':
        this.openExitDialog();
        break;
      default:
        break;
    }
  }
  onKeydownButtonExit(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key) {
      case 'Left':
        this.setFocus(this.buttonProfile);
        break;
      case 'Enter':
        exitApplication();
        break;
      case 'BrowserBack':
        this.openExitDialog();
        break;
      default:
        break;
    }
  }
  onClickButtonReload() {
    this.router.navigate(['caching']);
  }
  onClickButtonProfile() {
    this.router.navigate(['select-profile', false, 0]);
  }

  async openExitDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '80rem';
    dialogConfig.height = '10rem';

    const dialogRef = this.dialog.open(ExitDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((shouldExit) => {
      if (shouldExit) this.authService.exitApplication();
    });
  }

  private sortCategories(categories: CategoryStream[]): CategoryStream[] {
    return categories.sort((a, b) => a.cat_order - b.cat_order);
  }
}
