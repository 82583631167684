<div class="background">
  <div class="row info">
    <div>{{ currentTime | date : "HH:mm" }}</div>
    <div>
      <p>
        {{ "settings.expiry-date" | translate }}:
      </p>
      <p>
        {{ expiryDate | date : "dd.MM.yyyy" }}
      </p>
    </div>
    <div>
      <p>
        {{ "settings.connections-number" | translate }}:
      </p>
      <p>
        {{ userInfo.max_connection }}
      </p>
    </div>
    <div>
      <p>
        IP:
      </p>
      <p>{{ ipInfo.ip }} {{ ipInfo.org }}
      </p>
    </div>
    <div>
      <p>
        Lista:
      </p>
      <p>{{ userInfo?.username }}
      </p>
    </div>
    <div>
      <p>
        Profil:
      </p>
      <p>{{ currentProfile?.profile_name }}
      </p>
    </div>
  </div>
  <app-ocean-blue-grid [type]="'card'" [items]="cards" (onItemClick)="onCardClick($event)"></app-ocean-blue-grid>
</div>