import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import * as Constants from '../helpers/constants.helper';
import { AppInfoService } from './app-info.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private appInfoService: AppInfoService, private router: Router, private logService: LogService) {
    this.router.onSameUrlNavigation = 'reload';
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.appInfoService.getToken();

    if (token) {
      const cloned = httpRequest.clone({
        headers: httpRequest.headers.set("Authorization",
          "Bearer " + token)
      });

      return next.handle(cloned)
        .pipe(
          catchError(error => {
            console.log(error)

            if ((error.status === 401 && error.url == "https://api.myevelin.com/api/authorize") || (error.status === 404 && error.url.indexOf("epg") != -1)) {
              console.log("SUCCESS")
              return next.handle(httpRequest);
            }

            this.handleError(error);
            if (error.status === 401) {
              localStorage.removeItem(Constants.localStorageToken);
              this.router.navigate(['/landing']);
            } else {
              return throwError(error);
            }
          }));
    } else {
      return next.handle(httpRequest);
    }
  }

  handleError(error: Error | any) {
    console.log("Handling error in auth interceptor")
    console.log(error.stack, error.message, error.rejection);

    if (error.message) {
      if (error.stack) {
        this.logService.error(error.message, error.stack);
      } else {
        this.logService.error(error.message);
      }
    } else if (error.rejection) {
      if (error.stack) {
        this.logService.error(error.rejection, error.stack);
      } else {
        this.logService.error(error.rejection);
      }
    } else {
      try {
        this.logService.error(JSON.stringify(error));
      }
      catch {
        this.logService.error("Failed to stringify error - auth.interceptor.ts")
      }
    }
  }
}


