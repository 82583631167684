<div [class]="selectedTheme">
  <div class="background">
    <h1 mat-dialog-title>
      <!-- <mat-icon class="icon-sort">sort</mat-icon> -->
      <span class="mat-dialog-title">{{ "sort.channels" | translate }}</span>
    </h1>
    <div mat-dialog-content>
      <mat-radio-group class="radio-group">
        <mat-radio-button
          #sortDialogFirstRadioButton
          value="default"
          [checked]="inputValue == 'default'"
          (change)="changeSelectedValue('default')"
          (keydown)="onKeydown($event, false)"
          >{{ "sort.default" | translate }}</mat-radio-button
        >
        <mat-radio-button
          value="asc"
          [checked]="inputValue == 'asc'"
          (change)="changeSelectedValue('asc')"
          (keydown)="onKeydown($event, false)"
          >A-Z</mat-radio-button
        >
        <mat-radio-button
          value="desc"
          [checked]="inputValue == 'desc'"
          (change)="changeSelectedValue('desc')"
          (keydown)="onKeydown($event, false)"
          >Z-A</mat-radio-button
        >
        <mat-radio-button
          value="date"
          [checked]="inputValue == 'date'"
          (change)="changeSelectedValue('date')"
          (keydown)="onKeydown($event, true)"
          >{{ "sort.date" | translate }}</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div mat-dialog-actions>
      <button
        #sortDialogButtonOk
        class="button mat-button mat-dialog-close cdkFocusInitial"
        (click)="close()"
        (keydown)="onKeydownButton($event)"
      >
        Ok
      </button>
    </div>
  </div>
</div>
