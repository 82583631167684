export enum PlayerControlEvents {
    Play,
    Pause,
    Close,
    ChangeRatio,
    Forward,
    Backward
}

export enum PlayerButtons {
    Rewind,
    PlayPause,
    Forward,
    Ratio
}

export enum PlayerState {
    Hidden,
    Loading,
    Playing,
    Paused,
    Broken
}


