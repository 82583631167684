<div [class]="selectedTheme">
  <div class="background">
    <div class="content">
      <mat-radio-group aria-label="Select an option" class="radio-group">
        <span class="title">{{ "language.choose-language" | translate }}</span>
        <mat-radio-button
          #firstRadioButtonElement
          value="en"
          [checked]="selectedLanguage == 'en'"
          (change)="radioChange('en')"
          (keydown)="onKeydown($event, false)"
          >{{ "language.english" | translate }}</mat-radio-button
        >
        <mat-radio-button
        #secondRadioButtonElement
          value="hr"
          [checked]="selectedLanguage == 'hr'"
          (change)="radioChange('hr')"
          (keydown)="onKeydown($event, false)"
          >{{ "language.croatian" | translate }}</mat-radio-button
        >
        <mat-radio-button
        #thirdRadioButtonElement
          value="de"
          [checked]="selectedLanguage == 'de'"
          (change)="radioChange('de')"
          (keydown)="onKeydown($event, true)"
          >{{ "language.german" | translate }}</mat-radio-button
        >
      </mat-radio-group>
      <button #buttonSave (click)="confirm()" (keydown)="onKeydownButton($event)" class="button mat-button mat-dialog-close cdkFocusInitial">
        {{ "settings.save" | translate }}
      </button>
    </div>

  </div>
</div>
