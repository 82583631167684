import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppInfoService } from '../../../services/app-info.service';
import { Location } from '@angular/common';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { PlayerComponent } from '../../player/player.component';
import { ThemeHelper } from '../../../helpers/theme.helper';
import { Theme } from '../../../models/theme.model';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-stb-extreme-tv-show-seasons-list',
  templateUrl: './stb-extreme-tv-show-seasons-list.component.html',
  styleUrls: ['./stb-extreme-tv-show-seasons-list.component.css'],
})
export class StbExtremeTvShowSeasonsListComponent implements OnInit {
  public epgInfo;
  public streamId: number;
  public selectedTheme = 'stb-extreme';
  public sortedItems = new Array<number>();
  public currentEpisodes = [];
  public currentSeason = 0;
  public currentEpisode = 0;
  public streamUrl: string;
  public isPlayerHidden = true;

  @ViewChildren('seasonsList') seasonsList: QueryList<any>;
  @ViewChildren('episodesList') episodesList: QueryList<any>;
  @ViewChild('channelsListButtonBack') channelsListButtonBack: ElementRef;
  @ViewChild(PlayerComponent) player: PlayerComponent;

  constructor(
    private route: ActivatedRoute,
    private appInfoService: AppInfoService,
    private evelinService: EvelinService,
    private router: Router,
    private platformService: BasePlatformService,
    public dialog: MatDialog,
    private location: Location
  ) {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(selectedTheme as Theme);
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params: Params) => {
      if (params['streamId']) {
        this.streamId = parseInt(params['streamId']);
        console.log(this.streamId);
      }
    });

    this.epgInfo = await this.evelinService.getTvShowInfoById(this.streamId);
    console.log(this.epgInfo);
    let sortedStrings = Object.keys(this.epgInfo.seasons);
    sortedStrings.forEach((element) => {
      this.sortedItems.push(parseInt(element));
    });
    this.sortedItems = this.sortedItems.sort((a, b) => a - b);

    setTimeout(() => this.focusItem(0), 1000);
  }

  onKeydownHeaderButtons(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        this.focusItem(0);
        break;
      case 'Enter':
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydown(e: any, channel: any, index: number) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key) {
      case 'Up':
        if (index > 0) {
          index--;
          this.focusItem(index);
        } else {
          this.channelsListButtonBack.nativeElement.focus();
        }
        break;
      case 'Down':
        index++;
        this.focusItem(index);
        break;
      case 'Right':
        this.focusEpisode(0);
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  onEpisodeKeydown(e: any, channel: any, index: number) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key) {
      case 'Up':
        if (index > 0) {
          index--;
          this.focusEpisode(index);
        }
        break;
      case 'Down':
        index++;
        this.focusEpisode(index);
        break;
      case 'Left':
        this.focusItem(this.currentSeason);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Enter':
        this.streamUrl = this.currentEpisodes[index].url;
        console.log(this.streamUrl);
        this.isPlayerHidden = false;
        this.player.setFullScreen(this.epgInfo?.info);
        break;
      default:
        break;
    }
  }

  focusItem(index: number) {
    this.seasonsList
      .toArray()[0]
      .nativeElement.children[index].children[0].focus();

    this.currentEpisodes = this.epgInfo.seasons[index + 1];
    console.log(this.currentEpisodes);
    this.currentSeason = index;
  }

  focusEpisode(index: number) {
    this.episodesList
      .toArray()[0]
      .nativeElement.children[index].children[0].focus();
    this.currentEpisode = index;
  }


  itemClicked(season: number) {
    this.router.navigate([
      'stb-extreme-tv-show-episodes-list',
      (season + 1),
      this.streamId,
    ]);
  }

  onFocus(season: any) { }

  goBack() {
    this.location.back();
  }

  closeFullSreen() {
    this.isPlayerHidden = true;
    setTimeout(() => this.focusEpisode(this.currentEpisode));
  }

  nextEpisode() {
    // if (this.selectedIndex === this.episodes.length - 1)
    //   return;

    //this.itemClicked(this.selectedIndex + 1);
  }
}
