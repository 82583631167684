<div [class]="selectedTheme">
  <div class="row background">
    <div class="row movie-details">
      <div class="info">
        <div class="title">{{details.name | formatTitle}}</div>
        <div class="item"><span>{{ "details.date" | translate }}: </span>{{details.releasedate ||
          details.releaseDate?.split('-')[0] }}</div>
          <div class="item"><span>{{ "details.rating" | translate }}: </span>{{details.rating}}</div>
        <div class="item"><span>{{ "details.genre" | translate }}: </span>{{details.genre}}</div>
        <div class="item"><span>{{ "details.duration" | translate }}: </span>{{details.duration ||
          details.episode_run_time}}</div>
        <hr />
        <div class="item"><span>{{ "details.director" | translate }}: </span>{{details.director}}</div>
        <div class="item"><span>{{ "details.cast" | translate }}: </span>{{details.cast}}</div>
        <hr />
        <div #description class="item description">{{details.description}}</div>
      </div>
      <img [src]="details.cover_big || details.cover" class="streamIconImg" />
    </div>
    <div class="buttons">
      <!--<button #trailerButton mat-button class="button" disabled="true">{{ "details.trailer" | translate }}</button>-->
      <button #playButton mat-button class="button" (keydown)="onKeydownPlay($event)">{{ "details.play" | translate }}</button>
      <button #backButton mat-button class="button" (keydown)="onKeydownBack($event)">{{ "details.back" | translate }}</button>
    </div>
  </div>
</div>
<app-player [url]="url" [type]="1" (fullscreenChanged)="closeFullSreen()"></app-player>