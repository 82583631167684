<div [class]="selectedTheme">
    <div class="background">
        <div class="content">

            <div class="row s-test">
                <div class="col-md-4">
                    <h2 class="title">{{ "speed-test.title" | translate }} </h2>
                    <button id="startButton" #startButton mat-button class="button" (click)="initiateSpeedTest()"
                        (keydown)="onKeydown($event, 1)">{{ "speed-test.start" | translate }}</button>
                    <button #backButton mat-button class="button" (keydown)="onKeydown($event, 0)">{{ "speed-test.back" | translate }}</button>
                    <div *ngIf="!testRunning && resultsReady" class="row result">
                        <p>{{ "speed-test.current" | translate }}: {{speed}} Mbps</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <p>{{ ipInfo.ip }}</p>
                    <p>{{ ipInfo.org }}</p>

                    <div *ngIf="!testRunning && !resultsReady">
                        {{ "speed-test.explanation" | translate }}                        
                    </div>

                    <div *ngIf="testRunning && !resultsReady" class="row">
                        <div class="col-md-12">
                            {{ "speed-test.calculating" | translate }}
                        </div>
                    </div>

                    <div *ngIf="!testRunning && resultsReady">
                        <table>
                            <tr>
                                <th>{{ "speed-test.resolutions" | translate }}</th>
                                <th>{{ "speed-test.bandwidth" | translate }}</th>
                                <th>{{ "speed-test.bandwidth" | translate }}</th>
                            </tr>
                            <tr>
                                <td>{{ "speed-test.codec" | translate }}</td>
                                <td>h264</td>
                                <td>h265</td>
                            </tr>
                            <tr>
                                <td>SD</td>
                                <td>2 - 3 Mbps</td>
                                <td>1.5 Mbps</td>
                            </tr>
                            <tr>
                                <td>1280x720 (HD)</td>
                                <td>3 - 5 Mbps</td>
                                <td>2 Mbps</td>
                            </tr>
                            <tr>
                                <td>1920x1080 (FHD)</td>
                                <td>5 - 13 Mbps</td>
                                <td>5 Mbps</td>
                            </tr>
                            <tr>
                                <td>3840x2160 (UHD)</td>
                                <td>25 - 40 Mbps</td>
                                <td>15 Mbps</td>
                            </tr>
                            <tr>
                                <td>4096x2160 (4K)</td>
                                <td>32 - 50 Mbps</td>
                                <td>20 Mbps</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>