import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { Router } from '@angular/router';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';
 
import { AppInfoService } from 'src/app/services/app-info.service';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-change-dns',
  templateUrl: './change-dns.component.html',
  styleUrls: ['./change-dns.component.css']
})
export class ChangeDnsComponent implements OnInit {
  @ViewChild('buttonSave') buttonSave: MatButton;
  @ViewChild('firstRadioButtonElement')
  firstRadioButtonElement: MatRadioButton;

  constructor(
    public appInfoService: AppInfoService,
    private router: Router,
    private platformService: BasePlatformService
  ) { }

  public selectedDns = this.appInfoService.getDns;
  private selectedDnsBackup = this.appInfoService.getDns;
  public dns1Available = false;
  public dns2Available = false;

  selectedTheme: string;
  ngOnInit() {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );

    this.dns1Available = this.appInfoService.getDns1Value != null;
    this.dns2Available = this.appInfoService.getDns2Value != null;
  }
  ngAfterViewInit() {
    setTimeout(()=>{
    this.buttonSave.focus();
    },100);
  }

  confirm() {
    this.appInfoService.setDns = this.selectedDns as string;
    console.log(this.selectedDns, this.selectedDnsBackup)
    if (this.selectedDns != this.selectedDnsBackup) {
      this.router.navigate(['/caching']);
    } else {
      this.router.navigate(['/settings']);
    }
  }

  radioChange(dns: string) {
    this.selectedDns = dns;
  }

  back() {
    this.router.navigate(['/settings']);
  }

  onKeydown(e: any, lastElement: boolean) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        if (lastElement) {
          this.buttonSave.focus();
          e.preventDefault();
        }
        break;
      case 'Enter':

        break;
      case 'Right':
        this.buttonSave.focus();
        e.preventDefault();
        break;
      case 'BrowserBack':
        this.back();
        break;

      default:
        break;
    }
  }

  onKeydownButton(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.firstRadioButtonElement.focus();
        break;
      case 'Enter':
        this.confirm();
        break;
      case 'BrowserBack':
        this.back();
        break;

      default:
        break;
    }
  }
}
