import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeYear' })
export class RemoveYearPipe implements PipeTransform {
    transform(title: string): string {
        if (!title) 
            return "";

        const result = title.replace(/(\b\w+\b)\s\d{4}/, "$1");
        return result;
    }
}
