import {
  Component, ElementRef, Inject, ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppInfoService } from '../../../services/app-info.service';
import { ThemeHelper } from '../../../helpers/theme.helper';
import { Theme } from '../../../models/theme.model';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent {

  @ViewChild('buttonOK') buttonOK: ElementRef;  
  notification: any = {};
  selectedTheme: string;

  constructor(
    private appInfoService: AppInfoService,
    public dialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.notification = data;
  }

  ngOnInit() {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
    this.buttonOK.nativeElement.focus();
  }

  close() {
    this.dialogRef.close();
  }
}