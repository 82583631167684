import { HttpClient, HttpErrorResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, retry, map, retryWhen, delay, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

  public async get(endpoint: string) {
    return await this.http.get<any>(endpoint).toPromise();
  }

  public async post(endpoint: string, object: any) {
    return await this.http.post<any>(endpoint, object).toPromise();
  }

  public async patch(endpoint: string, object: any) {
    return await this.http.patch<any>(endpoint, object).toPromise();
  }

  public deleteAsPromise(endpoint: string, object: any) {
    let req = new HttpRequest('DELETE', endpoint);
    let newReq = req.clone({ body: object });
    return this.http.request(newReq)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    }
    else {
      console.error(
        `Backend returned code ${error.status} ##########` +
        `body was: ${error.error} ##########` +
        `body stringified was: ${JSON.stringify(error.error)} ##########` +
        `  ${JSON.stringify(error)}`);
    }
    return throwError(error);
  };

}
