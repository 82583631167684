import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BasePlatformService } from 'src/app/platforms/iplatform.interface';
import { Location } from '@angular/common';
import { AppInfoService } from 'src/app/services/app-info.service';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.css']
})
export class CreateProfileComponent implements OnInit {
  @ViewChild('profileNameInput') inputProfileName: ElementRef;
  @ViewChild('typeInput') inputType: ElementRef;
  @ViewChild('profilePinInput') inputProfilePin: ElementRef;
  @ViewChild('buttonHideShow', { read: ElementRef, static: true })
  private buttonHideShow: ElementRef;
  @ViewChild('confirmBtn', { read: ElementRef, static: true })
  private confirmBtn: ElementRef;
  @ViewChild('backBtn', { read: ElementRef, static: true })
  private backBtn: ElementRef;
  @ViewChild('typesList') typesList: ElementRef;
  @ViewChild('avatarsList') avatarsList: ElementRef;

  public profileNameTxt: string;
  public profilePinTxt: string;
  public typeTxt: string;

  public profileName: string = "";
  public profilePin: string = "";
  // public types = ["Normal", "Adult", "Kids"];
  public types = ["Normal"]; // Only for store
  public avatars = [
    "assets/images/profile_avatar/ic_avatar_0.png",
    "assets/images/profile_avatar/ic_avatar_1.png",
    "assets/images/profile_avatar/ic_avatar_2.png",
    "assets/images/profile_avatar/ic_avatar_3.png",
    "assets/images/profile_avatar/ic_avatar_4.png",
    "assets/images/profile_avatar/ic_avatar_5.png",
    "assets/images/profile_avatar/ic_avatar_6.png",
    "assets/images/profile_avatar/ic_avatar_7.png",
    "assets/images/profile_avatar/ic_avatar_8.png"];
  hide = true;
  error = 0;
  selectedType = 0;
  selectedAvatar = 0;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private evelinService: EvelinService,
    private platformService: BasePlatformService,
    private location: Location,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private appInfoService: AppInfoService
  ) {
    this.generateIconsAndTranslations(iconRegistry, sanitizer);
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.setFocus(this.inputProfileName);
  }

  public async onSubmit(): Promise<void> {
    try {
      let res = await this.evelinService.createClientProfile(this.profileName, this.profilePin, this.selectedAvatar, this.types[this.selectedType].toLowerCase());
      this.appInfoService.setCurrentProfile(res);
      this.router.navigate(['/category-hiding', true, res.id]);
    } catch (e) {
      if (e.error?.message === "The pin field must be at least 4 characters.")
        this.error = 1;
      else if (e.error?.message === "Maximum number of profiles reached")
        this.error = 2;
      else
        this.error = 3;
    }
  }

  setFocus(element: any) {
    const ele = element.nativeElement;
    if (ele) {
      ele.focus();
    }
  }

  onKeydownProfileName(e: any) {
    let key = this.platformService.keymapper(e);

    if (key == 'Down') {
      this.setFocus(this.inputProfilePin);
    }
    switch (key) {
      case 'Enter':
      case 'Select':
      case 'Accept':
        e.preventDefault();
        break;
      case 'Down':
        this.setFocus(this.inputProfilePin);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownProfilePin(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Enter':
      case 'Select':
      case 'Accept':
        e.preventDefault();
        break;
      case 'Up':
        this.setFocus(this.inputProfileName);
        break;
      case 'Down':
        //this.setFocus(this.confirmBtn);
        this.typesList.nativeElement.children[0].focus();
        break;
      case 'Right':
        this.setFocus(this.buttonHideShow);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownHideShowButton(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Ok':
        this.buttonHideShow.nativeElement.click();
        break;
      case 'Left':
        this.setFocus(this.inputProfilePin);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  onKeydownSubmit(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.avatarsList.nativeElement.children[this.selectedAvatar].focus();
        break;
      case 'Enter':
        e.preventDefault();
        this.onSubmit();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Left':
        this.setFocus(this.backBtn);
        break;
      default:
        break;
    }
  }

  onKeydownBack(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.avatarsList.nativeElement.children[this.selectedAvatar].focus();
        break;
      case 'Enter':
        this.backBtn.nativeElement.click();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Right':
        this.setFocus(this.confirmBtn);
        break;
      default:
        break;
    }
  }

  onTypeKeydown(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.setFocus(this.inputProfilePin);
        break;
      case 'Down':
        this.avatarsList.nativeElement.children[this.selectedAvatar].focus();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Left':
        // if (this.selectedType > 0)
        //   this.selectedType--;
        // this.typesList.nativeElement.children[this.selectedType].focus();
        break;
      case 'Right':
        // if (this.selectedType < 2)
        //   this.selectedType++;
        // this.typesList.nativeElement.children[this.selectedType].focus();
        break;
        // Left and Right is commented out due to the use of the store
      default:
        break;
    }
  }

  onAvatarKeydown(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.typesList.nativeElement.children[this.selectedType].focus();
        break;
      case 'Down':
        this.setFocus(this.confirmBtn);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Left':
        if (this.selectedAvatar > 0)
          this.selectedAvatar--;
        this.avatarsList.nativeElement.children[this.selectedAvatar].focus();
        break;
      case 'Right':
        if (this.selectedAvatar < 8)
          this.selectedAvatar++;
        this.avatarsList.nativeElement.children[this.selectedAvatar].focus();
        break;
      default:
        break;
    }
  }

  goBack() {
    this.location.back();
  }


  private generateIconsAndTranslations(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry
      .addSvgIcon(
        'user',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/onboarding/user_icon.svg'
        )
      )

      .addSvgIcon(
        'password',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/onboarding/password_icon.svg'
        )
      );

    this.translate
      .get('profile.profile-name')
      .toPromise()
      .then((res) => {
        this.profileNameTxt = res;
      });

    this.translate
      .get('profile.profile-pin')
      .toPromise()
      .then((res) => {
        this.profilePinTxt = res;
      });

    this.translate
      .get('profile.type')
      .toPromise()
      .then((res) => {
        this.typeTxt = res;
      });
  }
}
