import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Theme } from '../../../models/theme.model';
import { ThemeHelper } from '../../../helpers/theme.helper';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { PcMinorCategoryListComponent } from './minor-category-list/pc-minor-category-list.component';
import { BouquetLockPayload, ProfileBouquet } from '../../../models/bouquet.model';
import { ArraysHelper } from '../../../helpers/arrays.helper';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-parental-controls',
  templateUrl: './parental-controls.component.html',
  styleUrls: ['./parental-controls.component.css']
})
export class ParentalControlsComponent implements OnInit {

  @ViewChild("confirmButton", { read: ElementRef, static: true }) confirmButton: ElementRef;
  @ViewChild("backButton", { read: ElementRef, static: true }) backButton: ElementRef;
  @ViewChild("channelButton", { read: ElementRef, static: true }) channelButton: ElementRef;
  @ViewChild("moviesButton", { read: ElementRef, static: true }) moviesButton: ElementRef;
  @ViewChild("showsButton", { read: ElementRef, static: true }) showsButton: ElementRef;

  @ViewChild("tvChannelCategoriesList") tvChannelCategoriesList: PcMinorCategoryListComponent;
  @ViewChild("tvShowCategoriesList") tvShowCategoriesList: PcMinorCategoryListComponent;
  @ViewChild("movieCategoriesList") movieCategoriesList: PcMinorCategoryListComponent;

  constructor(
    private router: Router,
    private evelinService: EvelinService,
    private translate: TranslateService,
    private appInfo: AppInfoService,
    private appInfoService: AppInfoService,
    private platformService: BasePlatformService,
    private route: ActivatedRoute
  ) { }

  selectedTheme: string;
  public majorCategories: any[];
  public tvChannels = "";
  public movies = "";
  public tvShows = "";
  public selectedMajorCategory: any;

  public showTvChannelsList = true;
  public showTvShowsList = false;
  public showMoviesList = false;

  public tvChannelCategories = new Array<ProfileBouquet>();
  public tvShowCategories = new Array<ProfileBouquet>();
  public movieCategories = new Array<ProfileBouquet>();

  public lockedTvShowCategories = new Array<ProfileBouquet>();
  public lockedTvChannelCategories = new Array<ProfileBouquet>();
  public lockedMovieCategories = new Array<ProfileBouquet>();

  public lockedTvShowCategoriesBackup = new Array<ProfileBouquet>();
  public lockedTvChannelCategoriesBackup = new Array<ProfileBouquet>();
  public lockedMovieCategoriesBackup = new Array<ProfileBouquet>();

  public resourcesLoaded = false;

  newProfileId = 0;

  async ngOnInit(): Promise<void> {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );

    this.translate.get("settings.tv-channels").subscribe(res => {
      this.tvChannels = res;
    });
    this.translate.get("settings.tv-shows").subscribe(res => {
      this.tvShows = res;
    });
    this.translate.get("settings.movies").subscribe(res => {
      this.movies = res;
    });

    await this.initializeComponentData();
    this.confirmButton.nativeElement.focus();
  }

  private async initializeComponentData() {
    this.majorCategories = [{ name: this.tvChannels, id: 0 }, { name: this.movies, id: 1 }, { name: this.tvShows, id: 2 }];
    this.selectedMajorCategory = 0;

    let types = await this.evelinService.getAvailableStreamTypes();
    let profileBouquets = await this.evelinService.getProfilesBouquets(this.appInfo.getCurrentProfile().id);

    let liveTypes = types.filter(x => x.indexOf("live") != -1);
    let movieTypes = types.filter(x => x.indexOf("movie") != -1);
    let tvShowTypes = types.filter(x => x.indexOf("series") != -1);

    this.tvChannelCategories = profileBouquets.filter(x => liveTypes.includes(x.type));
    this.tvShowCategories = profileBouquets.filter(x => tvShowTypes.includes(x.type));
    this.movieCategories = profileBouquets.filter(x => movieTypes.includes(x.type));

    this.lockedTvChannelCategories = this.tvChannelCategories.filter(x => x.pivot.locked == 1);
    this.lockedTvShowCategories = this.tvShowCategories.filter(x => x.pivot.locked == 1);
    this.lockedMovieCategories = this.movieCategories.filter(x => x.pivot.locked == 1);

    this.lockedTvChannelCategoriesBackup = this.lockedTvChannelCategories;
    this.lockedTvShowCategoriesBackup = this.lockedTvShowCategories;
    this.lockedMovieCategoriesBackup = this.lockedMovieCategories;

    this.resourcesLoaded = true;
  }

  async confirm() {
    this.resourcesLoaded = false;

    let toBeLockedTvChannels = ArraysHelper.existsInFirstArray(this.lockedTvChannelCategories, this.lockedTvChannelCategoriesBackup);
    let toBeLockedMovies = ArraysHelper.existsInFirstArray(this.lockedMovieCategories, this.lockedMovieCategoriesBackup);
    let toBeLockedTvShows = ArraysHelper.existsInFirstArray(this.lockedTvShowCategories, this.lockedTvShowCategoriesBackup);

    let toBeLocked = [...toBeLockedTvChannels.map(x => x.id), ...toBeLockedMovies.map(x => x.id), ...toBeLockedTvShows.map(x => x.id)];

    let toBeUnlockedTvChannels = ArraysHelper.existsInFirstArray(this.lockedTvChannelCategoriesBackup, this.lockedTvChannelCategories);
    let toBeUnlockedMovies = ArraysHelper.existsInFirstArray(this.lockedMovieCategoriesBackup, this.lockedMovieCategories);
    let toBeUnlockedTvShows = ArraysHelper.existsInFirstArray(this.lockedTvShowCategoriesBackup, this.lockedTvShowCategories);

    let toBeUnlocked = [...toBeUnlockedTvChannels.map(x => x.id), ...toBeUnlockedMovies.map(x => x.id), ...toBeUnlockedTvShows.map(x => x.id)];

    for (const id of toBeLocked) {
      let payload = new BouquetLockPayload();
      payload.bouquet_id = id;
      payload.locked = true;
      await this.evelinService.toggleBouquetLock(payload);
    }

    for (const id of toBeUnlocked) {
      let payload = new BouquetLockPayload();
      payload.bouquet_id = id;
      payload.locked = false;
      await this.evelinService.toggleBouquetLock(payload);
    }

    this.resourcesLoaded = true;

    if (toBeLocked.length || toBeUnlocked.length) {
      this.router.navigate(['/caching']);
    } else {
      this.router.navigate(['/settings']);
    }
  }

  goBack() {
    this.router.navigate(['/settings']);
  }

  async majorCategorySelectionChange(e: any) {
    this.selectedMajorCategory = e;

    switch (this.selectedMajorCategory) {
      case 0:
        this.showTvChannelsList = true;
        this.showMoviesList = false;
        this.showTvShowsList = false;
        break;
      case 1:
        this.showTvChannelsList = false;
        this.showMoviesList = true;
        this.showTvShowsList = false;
        break;
      case 2:
        this.showTvChannelsList = false;
        this.showMoviesList = false;
        this.showTvShowsList = true;
        break;

    }
  }

  tvChannelsLockedCategoriesChanged(e: ProfileBouquet[]) {
    this.lockedTvChannelCategories = e;
  }

  tvShowsLockedCategoriesChanged(e: ProfileBouquet[]) {
    this.lockedTvShowCategories = e;
  }

  moviesLockedCategoriesChanged(e: ProfileBouquet[]) {
    this.lockedMovieCategories = e;
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    switch (key.toString()) {
      case 'BrowserBack':
          this.goBack();
        break;
      case 'Left':
        if (this.backButton.nativeElement === document.activeElement)
          this.focusConfirm();
        else if (this.showTvChannelsList)
          this.tvChannelCategoriesList.setFocus();
        else if (this.showMoviesList)
          this.movieCategoriesList.setFocus();
        else if (this.showTvShowsList)
          this.tvShowCategoriesList.setFocus();
        break;
      case 'Right':
        this.backButton.nativeElement.focus();
        break;
      case 'Up':
        this.channelButton.nativeElement.focus();
        break;
      case 'Enter':
        if (this.backButton.nativeElement === document.activeElement)
          this.goBack();
        else if (this.confirmButton.nativeElement === document.activeElement)
          this.confirm();
        break;
      default:
        break;
    }
  }

  onPickerKeydown(e: any, value: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    switch (key.toString()) {
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Left':
        if (this.moviesButton.nativeElement === document.activeElement)
          this.channelButton.nativeElement.focus();
        else if (this.showsButton.nativeElement === document.activeElement)
          this.moviesButton.nativeElement.focus();
        break;
      case 'Right':
        if (this.channelButton.nativeElement === document.activeElement)
          this.moviesButton.nativeElement.focus();
        else if (this.moviesButton.nativeElement === document.activeElement)
          this.showsButton.nativeElement.focus();
        break;
      case 'Down':
        this.focusConfirm();
        break;
      case 'Enter':
        this.majorCategorySelectionChange(value);
        break;
      default:
        break;
    }
  }

  focusConfirm() {
    this.confirmButton.nativeElement.focus();
  }
}
