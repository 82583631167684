import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Theme } from '../../../models/theme.model';
import { ThemeHelper } from '../../../helpers/theme.helper';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { MinorCategoryListComponent } from './minor-category-list/minor-category-list.component';
import { BouquetVisiblePayload, ClientBouquet } from '../../../models/bouquet.model';
import differenceBy from 'lodash/differenceBy';
import { ArraysHelper } from '../../../helpers/arrays.helper';
import { EvelinService } from 'src/app/services/evelin.service';

@Component({
  selector: 'app-category-hiding',
  templateUrl: './category-hiding.component.html',
  styleUrls: ['./category-hiding.component.css']
})
export class CategoryHidingComponent implements OnInit {

  @ViewChild("confirmButton", { read: ElementRef, static: true }) confirmButton: ElementRef;
  @ViewChild("backButton", { read: ElementRef, static: true }) backButton: ElementRef;
  @ViewChild("channelButton", { read: ElementRef, static: true }) channelButton: ElementRef;
  @ViewChild("moviesButton", { read: ElementRef, static: true }) moviesButton: ElementRef;
  @ViewChild("showsButton", { read: ElementRef, static: true }) showsButton: ElementRef;

  @ViewChild("tvChannelCategoriesList") tvChannelCategoriesList: MinorCategoryListComponent;
  @ViewChild("tvShowCategoriesList") tvShowCategoriesList: MinorCategoryListComponent;
  @ViewChild("movieCategoriesList") movieCategoriesList: MinorCategoryListComponent;

  constructor(
    private router: Router,
    private evelinService: EvelinService,
    private translate: TranslateService,
    private appInfo: AppInfoService,
    private appInfoService: AppInfoService,
    private platformService: BasePlatformService,
    private route: ActivatedRoute
  ) { }

  selectedTheme: string;
  public majorCategories: any[];
  public tvChannels = "";
  public movies = "";
  public tvShows = "";
  public selectedMajorCategory: any;

  public showTvChannelsList = true;
  public showTvShowsList = false;
  public showMoviesList = false;

  public tvChannelCategories = new Array<ClientBouquet>();
  public tvShowCategories = new Array<ClientBouquet>();
  public movieCategories = new Array<ClientBouquet>();

  public hiddenTvShowCategories = new Array<ClientBouquet>();
  public hiddenTvChannelCategories = new Array<ClientBouquet>();
  public hiddenMovieCategories = new Array<ClientBouquet>();

  public hiddenTvShowCategoriesBackup = new Array<ClientBouquet>();
  public hiddenTvChannelCategoriesBackup = new Array<ClientBouquet>();
  public hiddenMovieCategoriesBackup = new Array<ClientBouquet>();

  public resourcesLoaded = false;
  backButtonDisabled = true;
  confirmButtonDisabled = false;
  newProfileId = 0;

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params: Params) => {
      this.backButtonDisabled = (params['backButtonDisabled2'] === 'true');
      this.newProfileId = params['profileId'];
      if (!this.newProfileId)
        this.newProfileId = params['newProfileId'];
    });

    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );

    this.translate.get("settings.tv-channels").subscribe(res => {
      this.tvChannels = res;
    });
    this.translate.get("settings.tv-shows").subscribe(res => {
      this.tvShows = res;
    });
    this.translate.get("settings.movies").subscribe(res => {
      this.movies = res;
    });

    await this.initializeComponentData();
    this.confirmButton.nativeElement.focus();
  }

  private async initializeComponentData() {
    this.majorCategories = [{ name: this.tvChannels, id: 0 }, { name: this.movies, id: 1 }, { name: this.tvShows, id: 2 }];
    this.selectedMajorCategory = 0;

    let types = await this.evelinService.getAvailableStreamTypes();
    let clientBouquets = await this.evelinService.getClientBouquets();
    let profileBouquetsOld = await this.evelinService.getProfilesBouquets(this.appInfo.getCurrentProfile().id);
    let profileBouquets = new Array<ClientBouquet>();
    profileBouquetsOld.forEach(x => {
      let cb = new ClientBouquet();
      cb.bouquet_name = x.bouquet_name;
      cb.bouquet_order = x.bouquet_order;
      cb.id = x.id;
      cb.type = x.type;

      profileBouquets.push(cb);
    });

    let liveTypes = types.filter(x => x.indexOf("live") != -1);
    let movieTypes = types.filter(x => x.indexOf("movie") != -1);
    let tvShowTypes = types.filter(x => x.indexOf("series") != -1);

    this.tvChannelCategories = clientBouquets.filter(x => liveTypes.includes(x.type));
    this.tvShowCategories = clientBouquets.filter(x => tvShowTypes.includes(x.type));
    this.movieCategories = clientBouquets.filter(x => movieTypes.includes(x.type));

    let profileTvChannelCategories = profileBouquets.filter(x => liveTypes.includes(x.type));
    let profileTvShowCategories = profileBouquets.filter(x => tvShowTypes.includes(x.type));
    let profileMovieCategories = profileBouquets.filter(x => movieTypes.includes(x.type));

    this.hiddenTvChannelCategories = differenceBy(this.tvChannelCategories, profileTvChannelCategories, 'id');
    this.hiddenTvShowCategories = differenceBy(this.tvShowCategories, profileTvShowCategories, 'id');
    this.hiddenMovieCategories = differenceBy(this.movieCategories, profileMovieCategories, 'id');

    this.hiddenTvChannelCategoriesBackup = this.hiddenTvChannelCategories;
    this.hiddenTvShowCategoriesBackup = this.hiddenTvShowCategories;
    this.hiddenMovieCategoriesBackup = this.hiddenMovieCategories;

    this.resourcesLoaded = true;
  }

  async confirm() {
    this.resourcesLoaded = false;

    let toBeLockedTvChannels = ArraysHelper.existsInFirstArray(this.hiddenTvChannelCategories, this.hiddenTvChannelCategoriesBackup);
    let toBeLockedMovies = ArraysHelper.existsInFirstArray(this.hiddenMovieCategories, this.hiddenMovieCategoriesBackup);
    let toBeLockedTvShows = ArraysHelper.existsInFirstArray(this.hiddenTvShowCategories, this.hiddenTvShowCategoriesBackup);

    let toBeLocked = [...toBeLockedTvChannels.map(x => x.id), ...toBeLockedMovies.map(x => x.id), ...toBeLockedTvShows.map(x => x.id)];

    let toBeUnlockedTvChannels = ArraysHelper.existsInFirstArray(this.hiddenTvChannelCategoriesBackup, this.hiddenTvChannelCategories);
    let toBeUnlockedMovies = ArraysHelper.existsInFirstArray(this.hiddenMovieCategoriesBackup, this.hiddenMovieCategories);
    let toBeUnlockedTvShows = ArraysHelper.existsInFirstArray(this.hiddenTvShowCategoriesBackup, this.hiddenTvShowCategories);

    let toBeUnlocked = [...toBeUnlockedTvChannels.map(x => x.id), ...toBeUnlockedMovies.map(x => x.id), ...toBeUnlockedTvShows.map(x => x.id)];

    for (const id of toBeLocked) {
      let payload = new BouquetVisiblePayload();
      payload.bouquet_id = id;
      await this.evelinService.toggleBouquetVisibility(payload);
    }

    for (const id of toBeUnlocked) {
      let payload = new BouquetVisiblePayload();
      payload.bouquet_id = id;
      await this.evelinService.toggleBouquetVisibility(payload);
    }

    this.resourcesLoaded = true;

    if (this.backButtonDisabled == true) {
      this.router.navigate(['/select-profile', true, this.newProfileId]);
    } else {
      if (toBeLocked.length || toBeUnlocked.length) {
        this.router.navigate(['/caching']);
      } else {
        this.router.navigate(['/settings']);
      }
    }
  }

  goBack() {
    this.router.navigate(['/settings']);
  }

  async majorCategorySelectionChange(e: any) {
    this.selectedMajorCategory = e;

    switch (this.selectedMajorCategory) {
      case 0:
        this.showTvChannelsList = true;
        this.showMoviesList = false;
        this.showTvShowsList = false;
        break;
      case 1:
        this.showTvChannelsList = false;
        this.showMoviesList = true;
        this.showTvShowsList = false;
        break;
      case 2:
        this.showTvChannelsList = false;
        this.showMoviesList = false;
        this.showTvShowsList = true;
        break;

    }
  }

  tvChannelsHiddenCategoriesChanged(e: ClientBouquet[]) {
    this.hiddenTvChannelCategories = e;
    this.confirmButtonDisabled = this.isConfirmDisabled();

  }

  tvShowsHiddenCategoriesChanged(e: ClientBouquet[]) {
    this.hiddenTvShowCategories = e;
  }

  moviesHiddenCategoriesChanged(e: ClientBouquet[]) {
    this.hiddenMovieCategories = e;
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    switch (key.toString()) {
      case 'BrowserBack':
        if (this.backButtonDisabled == false) {
          this.goBack();
        }
        break;
      case 'Left':
        if (this.backButton.nativeElement === document.activeElement && !this.confirmButtonDisabled)
          this.focusConfirm();
        else if (this.showTvChannelsList)
          this.tvChannelCategoriesList.setFocus();
        else if (this.showMoviesList)
          this.movieCategoriesList.setFocus();
        else if (this.showTvShowsList)
          this.tvShowCategoriesList.setFocus();
        break;
      case 'Right':
        this.backButton.nativeElement.focus();
        break;
      case 'Up':
        this.channelButton.nativeElement.focus();
        break;
      case 'Enter':
        if (this.backButton.nativeElement === document.activeElement)
          this.goBack();
        else if (this.confirmButton.nativeElement === document.activeElement)
          this.confirm();
        break;
      default:
        break;
    }
  }

  onPickerKeydown(e: any, value: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    switch (key.toString()) {
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Left':
        if (this.moviesButton.nativeElement === document.activeElement)
          this.channelButton.nativeElement.focus();
        else if (this.showsButton.nativeElement === document.activeElement)
          this.moviesButton.nativeElement.focus();
        break;
      case 'Right':
        if (this.channelButton.nativeElement === document.activeElement)
          this.moviesButton.nativeElement.focus();
        else if (this.moviesButton.nativeElement === document.activeElement)
          this.showsButton.nativeElement.focus();
        break;
      case 'Down':
        this.focusConfirm();
        break;
      case 'Enter':
        this.majorCategorySelectionChange(value);
        break;
      default:
        break;
    }
  }

  focusConfirm() {
    if (this.confirmButtonDisabled)
      this.backButton.nativeElement.focus();
    else
      this.confirmButton.nativeElement.focus();
  }

  isConfirmDisabled() {
    return this.tvChannelCategories.length === this.hiddenTvChannelCategories.length;
  }
}
