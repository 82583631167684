import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timed-progress',
  templateUrl: './timed-progress.component.html',
  styleUrls: ['./timed-progress.component.css'],
})
export class TimedProgressComponent implements OnInit {
  @Input() duration: number;

  progress: number = 0;
  interval: any;

  constructor() { }

  ngOnInit(): void {
    this.triggerProgress(this.duration);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  triggerProgress(intervalTime: number) {
    this.progress = 1;

    let increase = () => {
      this.progress += 20 / intervalTime;
      if (this.progress >= 100) {
        this.progress = 0;
        clearInterval(this.interval);
      }
    }

    this.interval = setInterval(() => increase(), 200);
  }

}
