export class ShortEpg {
  epg_listings?: ShortEpgListing[];
}

export class ShortEpgListing {
  id: number;
  epg_id: number;
  title: string;
  lang?: string;
  start: string;
  end: string;
  description: string;
  channel_id: string;
  start_timestamp: number;
  stop_timestamp: number;
}


export class CompleteEpg {
  epg_listings?: CompleteEpgListing[];
}

export class CompleteEpgListing {
  id: number;
  epg_id: number;
  title: string;
  lang?: string;
  start: string;
  end: string;
  description: string;
  channel_id: string;
  start_timestamp: number;
  end_timestamp: number;
  now_playing: number;
  has_archive: number;
}

export interface WeirdBackendEpgModel {
  [key: string]: CompleteEpgListing[];
}