declare const webapis: any;
declare const tizen: any;

export const getDeviceTime = (): Promise<Date> =>
  new Promise<Date>((resolve) => {
    const _nowTizen = tizen.time.getCurrentDateTime();
    const _now = new Date(
      _nowTizen.getFullYear(),
      _nowTizen.getMonth(),
      _nowTizen.getDate(),
      _nowTizen.getHours(),
      _nowTizen.getMinutes(),
      _nowTizen.getSeconds()
    );
    resolve(_now);
  });

export const getDeviceInfo = (): Promise<any> => {
  const mac = deviceMacAddress();
  const ip = deviceIpAddress();
  const device = deviceModelName();
  const software = deviceAppVersion();

  return Promise.resolve({
    mac: mac,
    ip: ip,
    software: software,
    modelName: device,
    modelId: 20,
  });
};

export const deviceMacAddress = (): string | null => {
  if (!webapis) {
    return "123.123.123.123";
  }

  try {
    return webapis.productinfo.getDuid();
  } catch (e) {
    return null;
  }
};

export const deviceIpAddress = (): string | null => {
  if (!webapis) {
    return "127.0.0.1";
  }
  try {
    return webapis.network.getIp();
  } catch (e) {
    return null;
  }
};

export const deviceModelName = (): string | null => {
  if (!webapis) {
    return "WEB CHROME";
  }
  return "Samsung " + webapis.productinfo.getModel();
};

export const deviceAppVersion = (): string | null => {
  return "4.0.1";
  // if (!window.webapis) {
  //   return "4.0.1";
  // }
  // let appInfo = tizen.application.getAppInfo();
  // return appInfo.version;
};

export const deviceSwVersion = (): string | null => {
  if (!webapis) {
    return "1.0.0 (Firmware: 1.0.0)";
  }

  return (
    webapis.productinfo.getVersion() +
    "(Firmware: " +
    webapis.productinfo.getFirmware() +
    ")"
  );
};

export const registerKeys = (): void => {
  var usedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Menu",
    "Info",
    "Guide",
    "ChannelUp",
    "ChannelDown",
    "Exit",
    "ColorF0Red",
    "ColorF1Green",
    "ColorF2Yellow",
    "ColorF3Blue",
    "MediaPlayPause",
    "MediaRewind",
    "MediaFastForward",
    "MediaPlay",
    "MediaTrackPrevious",
    "MediaTrackNext",
    "MediaStop",
  ];

  if (tizen === undefined || !tizen) {
    return;
  }
  usedKeys.forEach(tizen.tvinputdevice.registerKey);
};

export const exitApplication = () => {
  localStorage.removeItem("fern-last-home");
  tizen.application.getCurrentApplication().exit();
}

export function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}