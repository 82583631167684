import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SpeedTestService } from 'ng-speed-test';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { IpInfo } from 'src/app/models/ipinfo.model';
import { Theme } from 'src/app/models/theme.model';
import { AppInfoService } from 'src/app/services/app-info.service';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { Location } from '@angular/common';

@Component({
  selector: 'app-speed-test',
  templateUrl: './speed-test.component.html',
  styleUrls: ['./speed-test.component.css']
})
export class SpeedTestComponent implements OnInit {

  @ViewChild("startButton", { read: ElementRef, static: true }) startButton: ElementRef;
  @ViewChild("backButton", { read: ElementRef, static: true }) backButton: ElementRef;

  constructor(
    private speedTestService: SpeedTestService,
    private http: HttpClient,
    private appInfoService: AppInfoService,
    private platformService: BasePlatformService,
    private location: Location
  ) { }

  public speed = "Calculating...";
  public ipInfo = new IpInfo();
  selectedTheme: string;
  public testRunning = false;
  public resultsReady = false;

  ngOnInit(): void {
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );
    this.getIpInfo();
    this.startButton.nativeElement.focus();
  }

  /*
  https://nightfight.xyz/st/20MB.zip
  https://nightfight.xyz/st/50MB.zip
  https://nightfight.xyz/st/100MB.zip
  https://nightfight.xyz/st/200MB.zip */

  getIpInfo() {
    this.http.get("http://ipinfo.io/?token=389aa3e780fd82").subscribe((res: any) => {
      this.ipInfo = res as IpInfo;
    })
  }

  initiateSpeedTest() {
    this.testRunning = true;
    this.speedTestService.getMbps(
      {
        iterations: 10,
        file: {
          path: 'https://nightfight.xyz/st/5mb.jpg',
          size: 4952221,
          shouldBustCache: true
        },
        retryDelay: 1500,
      }
    ).subscribe(
      (speed) => {
        console.log('Your speed is ' + speed);
        this.speed = speed.toFixed(2);
        this.testRunning = false;
        this.resultsReady = true;
      }
    );


    /*
      this.speedTestService.isOnline().subscribe(
      (isOnline) => {
        if (isOnline === false) {
          console.log('Network unavailable.');
        }
      }
    );
    */
  }

  onKeydown(e: any, b: number) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    console.log(key);
    switch (key.toString()) {
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Left':
        if (b === 0)
          this.startButton.nativeElement.focus();
        break;
      case 'Right':
        if (b === 1)
          this.backButton.nativeElement.focus();
        break;
      case 'Enter':
        if (b === 1)
          this.initiateSpeedTest();
        else
          this.goBack();
        break;
      default:
        break;
    }
  }

  goBack() {
    this.location.back();
  }

}
