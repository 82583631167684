import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAppConfig } from "./models/app-config.model";
import * as Constants from './helpers/constants.helper';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    constructor(private http: HttpClient) { }
    load() {
        const jsonFile = `assets/appConfig-10.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: any) => {
                AppConfig.settings = <IAppConfig>response;
                if (AppConfig.settings.dns1 && AppConfig.settings.dns1 != "") {
                    localStorage.setItem(Constants.localStorageDns1, AppConfig.settings.dns1);
                }

                if (AppConfig.settings.dns2 && AppConfig.settings.dns2 != "") {
                    localStorage.setItem(Constants.localStorageDns2, AppConfig.settings.dns2);
                }

                if (AppConfig.settings.dnsFallback && AppConfig.settings.dnsFallback != "") {
                    localStorage.setItem(Constants.localStorageDnsFallback, AppConfig.settings.dnsFallback);
                }

                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}