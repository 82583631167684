import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { StreamOptionType } from '../../../models/category.model';
import { Location } from '@angular/common';
import { TVShowStream } from '../../../models/stream.model';
import { PlayerComponent } from '../../player/player.component';
import { OceanBlueGridComponent } from '../ocean-blue-grid/ocean-blue-grid.component';
import { OceanBlueHeaderComponent } from '../ocean-blue-header/ocean-blue-header.component';

@Component({
  selector: 'app-movies-ocean-blue.component',
  templateUrl: './movies-ocean-blue.component.html',
  styleUrls: ['./movies-ocean-blue.component.css'],
})

export class MoviesOceanBlueComponent implements OnInit {

  @ViewChild(OceanBlueGridComponent) itemsGrid: OceanBlueGridComponent;
  @ViewChild(PlayerComponent) player: PlayerComponent;
  @ViewChild(OceanBlueHeaderComponent) header: OceanBlueHeaderComponent;

  items: any[];
  title: string = "Categories";
  currentCategory: any;
  selectedStream: TVShowStream;
  type = 'description';

  constructor(
    public router: Router,
    public appInfoService: AppInfoService,
    public translate: TranslateService,
    public matDialog: MatDialog,
    private location: Location
  ) { }

  async ngOnInit(): Promise<void> {
    this.loadCategories();
  }

  loadCategories() {
    this.items = this.appInfoService.getCategoriesFromCache(StreamOptionType.Movies);
    this.items.forEach(item => {
      item.items = this.appInfoService.getCachedMoviesByCategory(item.id);
    });
    console.log(this.items);
    this.title = "Categories";
    this.type = 'description';
    setTimeout(() => this.itemsGrid.focusCard(0), 100);
  }

  onCardClick(card) {
    console.log(card);
    // this.currentCardIndex = card;
    //this.navigate();
    if (card === -1) {
      this.goBack();
    } else if (card === -2) {
      this.header.focus(0);
    } else if (this.type == 'description') {
      console.log(card);
      this.currentCategory = this.items[card];
      this.type = 'image';
      this.items = this.currentCategory.items;
      this.title = this.currentCategory.name;
      console.log(this.items);
      setTimeout(() => this.itemsGrid.focusCard(0), 100);
    } else {
      this.router.navigate([
        'stb-extreme-movie-view',
        this.items[card].id,
      ]);
    }
  }

  closeFullSreen() {
    setTimeout(() => this.itemsGrid.focusCard(0), 100);
  }

  goBack() {
    if (this.type == 'description')
      this.location.back();
    else
      this.loadCategories();
  }

  onHeaderClick(ev) {
    if (ev === -1)
      this.goBack();
    else  
      this.itemsGrid.focusCard(ev);
  }
}