import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { SortDialogComponent } from '../../sort-dialog/sort-dialog.component';

@Component({
  selector: 'app-ocean-blue-header',
  templateUrl: './ocean-blue-header.component.html',
  styleUrls: ['./ocean-blue-header.component.css'],
})

export class OceanBlueHeaderComponent {

  @Input() title: string = "";
  @Output() onBack = new EventEmitter<void>();
  @Output() onFocusLoss = new EventEmitter<void>();
  @Output() onSearchChanged = new EventEmitter<string>();
  @Output() onSortChanged = new EventEmitter<string>();
  @ViewChild('channelsListSearchInput') channelsListSearchInput: ElementRef;
  @ViewChild('buttonBack') buttonBack: ElementRef;
  @ViewChild('buttonSearch') buttonSearch: ElementRef;
  @ViewChild('buttonSort') buttonSort: ElementRef;

  sortVisible = false;
  sortingOption = 'default';
  searchValue = '';
  searchBoxVisible = false;
  selectedTheme = 'stb-extreme';

  currentCardIndex: number = 0;

  constructor(
    public router: Router,
    public appInfo: AppInfoService,
    public translate: TranslateService,
    private platformService: BasePlatformService,
    public dialog: MatDialog
  ) { }

  public focus(index) {
    this.buttonBack.nativeElement.focus();
  }

  onKeydown(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);

    switch (key.toString()) {
      case 'Down':
        this.onFocusLoss.emit();
        break;
      case 'Left':
        if (document.activeElement === this.buttonSearch.nativeElement)
          this.buttonBack.nativeElement.focus();
        else if (document.activeElement === this.buttonSort.nativeElement)
          this.buttonSearch.nativeElement.focus();
        break;
      case 'Right':
        if (document.activeElement === this.buttonBack.nativeElement)
          this.buttonSearch.nativeElement.focus();
        else if (document.activeElement === this.buttonSearch.nativeElement)
          this.buttonSort.nativeElement.focus();
        break;
      case 'Enter':
        e.srcElement.click();
        break;
      case 'BrowserBack':
        this.onBackClicked();
        break;
      default:
        break;
    }
  }

  toggleSortDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '900px';
    dialogConfig.height = '400px';
    dialogConfig.data = { sortingOption: this.sortingOption };
    const dialogRef = this.dialog.open(SortDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .subscribe(sort => {
        console.log(sort);
        this.sortingOption = sort;
        this.onSortChanged.emit(sort)
      });
  }


  toggleSearchBox() {
    this.searchBoxVisible = !this.searchBoxVisible;
    setTimeout(() => this.channelsListSearchInput.nativeElement.focus(), 50);
  }

  onKeydownInput(e: any) {
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
      case 'Enter':
        this.searchBoxVisible = false;
        this.onFocusLoss.emit();
        break;
      case 'Right':
      case 'BrowserBack':
        this.hideSearchBox();
        break;
      default:
        break;
    }
  }

  onBackClicked() {
    this.onBack.emit();
  }

  hideSearchBox() {
    this.buttonSearch.nativeElement.focus();
    this.searchBoxVisible = !this.searchBoxVisible;
  }

  searchValueChanged(e: any) {
    this.searchValue = e.target.value;
    console.log(this.searchValue);
    this.onSearchChanged.emit(e.target.value.toLowerCase())
  }

  setFocus(index: number) {
    if (index === 0)
      this.buttonBack.nativeElement.focus();
    else if (index === 1)
      this.buttonSearch.nativeElement.focus();
  }
}