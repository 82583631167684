import { Component, OnInit, ViewChild } from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryStream, StreamOptionType } from '../../../models/category.model';
import { OceanBlueListComponent } from '../ocean-blue-list/ocean-blue-list.component';
import { Location } from '@angular/common';
import { LiveStream, TVShowStream } from '../../../models/stream.model';
import { PlayerComponent } from '../../player/player.component';
import { CompleteEpgListing } from 'src/app/models/epg.model';

@Component({
  selector: 'app-tv-channels-ocean-blue.component',
  templateUrl: './tv-channels-ocean-blue.component.html',
  styleUrls: ['./tv-channels-ocean-blue.component.css'],
})

export class TVChannelsOceanBlueComponent implements OnInit {

  @ViewChild(OceanBlueListComponent) itemsList: OceanBlueListComponent;
  @ViewChild(PlayerComponent) player: PlayerComponent;

  allCategories: any[];
  items: any[];
  title: string = "Categories";
  categoriesView = true;
  currentCategory: CategoryStream;
  selectedStream: TVShowStream;

  constructor(
    public router: Router,
    public appInfoService: AppInfoService,
    public translate: TranslateService,
    public matDialog: MatDialog,
    private location: Location
  ) { }

  async ngOnInit(): Promise<void> {
    this.allCategories = this.appInfoService.getCategoriesFromCache(StreamOptionType.TVChannels);
    this.loadCategories();
  }

  loadCategories() {
    this.items = this.allCategories;
    this.title = "Categories";
    this.categoriesView = true;
    setTimeout(() => this.itemsList.setFocus(0), 100);
  }

  onCardClick(card) {
    console.log(card);

    if (card === -1) {
      this.goBack();
    } else if (this.categoriesView) {
      this.currentCategory = this.items[card];
      this.items = this.appInfoService.getCachedTvChannelsByCategory(this.currentCategory.id);
      console.log(this.items);
      this.title = this.appInfoService
        .getCategoriesFromCache(StreamOptionType.TVChannels)
        .find((x) => x.id == this.currentCategory.id)
        ?.category_name;
      this.categoriesView = false;
      setTimeout(() => this.itemsList.setFocus(0), 100);
    } else if (this.selectedStream && this.selectedStream.id === this.items[card].id) {
      this.setFullScreen();
    } else {
      this.selectedStream = this.items[card];
      console.log(this.selectedStream);
    }
  }

  setFullScreen() {
    let stream = this.appInfoService.getCachedStreamByStreamId(this.selectedStream.id, StreamOptionType.TVChannels) as LiveStream;
    let programmes = [];

    if (stream.epg_name != null) {
      programmes = this.appInfoService.getShortEpgByChannelName(stream.epg_name);
    }

    let streamInfo = [];
    if (programmes.length)
      streamInfo = programmes
        .slice(0, 3)
        .map((program: CompleteEpgListing) => {
          return {
            time: program.start,
            title: program.title
          }
        });

    console.log(this.selectedStream);
    this.player.setFullScreen(streamInfo, this.selectedStream.icon);
  }

  closeFullSreen() {
    setTimeout(() => this.itemsList.setFocus(), 100);
  }

  goBack() {
    if (this.categoriesView)
      this.location.back();
    else
      this.loadCategories();
  }

}