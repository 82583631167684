<div [class]="selectedTheme">
  <div class="row background">
    <div class="col-md-3">
      <div class="channel-list-header">
        <button #channelsListButtonBack type="button" class="btn btn-secondary buttons-headers" (click)="goBack()"
          (keydown)="onKeydownHeaderButtons($event)">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
          </svg>
        </button>
        <label class="channel-list-header-title">{{ epgInfo?.title | formatTitle }}</label>
      </div>
      <div class="channel-list-box">
        <div #seasonsList>
          <div *ngFor="let item of sortedItems ; index as i" (click)="focusItem(item)"
            (keydown)="onKeydown($event, item, i)">
            <div class="channel-list-playing-card" [tabIndex]="1" (focus)="onFocus(item)" [ngClass]="{ 'selected-season': currentSeason === i }">
              Sezona {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div #episodesList class="channel-list-box episodes-container">
        <div *ngFor="let item of currentEpisodes; index as i" (click)="focusItem(item)"
          (keydown)="onEpisodeKeydown($event, item, i)">
          <div class="channel-list-playing-card" [tabIndex]="1" (focus)="onFocus(item)">
            Epizoda {{ i + 1 }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <app-stb-details class="row" *ngIf="epgInfo" [details]="epgInfo"></app-stb-details>
    </div>
  </div>
</div>
<div [ngClass]="{'player-hidden':isPlayerHidden}" class="row video-position">
  <app-player [url]="streamUrl" [type]="2"
    (fullscreenChanged)="closeFullSreen()" (onVideoChanged)="nextEpisode()"></app-player>
</div>