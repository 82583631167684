import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { StreamOptionType } from '../../../models/category.model';
import { AppInfoService } from '../../../services/app-info.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SortDialogComponent } from '../../sort-dialog/sort-dialog.component';
import { SortingHelper } from '../../../helpers/sorting.helper';
import { ThemeHelper } from 'src/app/helpers/theme.helper';
import { Theme } from 'src/app/models/theme.model';
import { TvShowInfo } from '../../../models/serieinfo.model';
import { BasePlatformService } from '../../../platforms/iplatform.interface';
import { PlayerComponent } from '../../player/player.component';
import { TranslateService } from '@ngx-translate/core';
import {
  LiveStream,
  MovieStream,
  RadioStream,
  Stream,
  TVShowStream,
} from '../../../models/stream.model';
import { GenericService } from 'src/app/services/generic.service';
import { EvelinService } from 'src/app/services/evelin.service';
import { OceanBlueHeaderComponent } from '../../ocean-blue/ocean-blue-header/ocean-blue-header.component';
import { CompleteEpgListing } from 'src/app/models/epg.model';

@Component({
  selector: 'app-stb-extreme-items-list',
  templateUrl: './stb-extreme-items-list.component.html',
  styleUrls: ['./stb-extreme-items-list.component.css'],
})
export class StbExtremeItemsListComponent implements OnInit, OnDestroy {
  @ViewChildren('channelsListPlaying') channelsListPlaying: QueryList<any>;
  @ViewChild('channelsListButtonBack') channelsListButtonBack: ElementRef;

  @ViewChild('channelsListButtonSearch') channelsListButtonSearch: ElementRef;

  @ViewChild('channelsListButtonSort') channelsListButtonSort: ElementRef;
  @ViewChild('channelsListSearchInput') channelsListSearchInput: ElementRef;
  @ViewChild(PlayerComponent) player: PlayerComponent;
  @ViewChild(OceanBlueHeaderComponent) header: OceanBlueHeaderComponent;

  numberButtonsStep: number;
  currentCategory: number;
  currentType: StreamOptionType;
  currentTitle: string;
  watchItems: Stream[] | MovieStream[] | TVShowStream[] | RadioStream[];
  allItems: Stream[];
  allItemsPointer: number = 0;
  watchItemsBackup: Stream[];
  selectedStreamIdEpg: number;
  showEpg = false;
  public selectedTheme: string;
  selectedMovieInfo: any;
  selectedStream: Stream = new Stream();

  channelsListHeaderButtons: any;
  screenClass: string = 'min-screen';

  private continuePlaying = false;
  continueStreamId: number;
  extension = '';

  tvShowInfo: TvShowInfo = new TvShowInfo();
  isPlayerFullScreen = false;
  isPlayerHidden = true;
  canPlay = false;
  streamInfo: Array<any> | null = null;

  pressedKeys: string = '';

  public browserVersion = '';
  public loadingVisible = false;
  private epgReloadInterval;

  pagingPointer = 2;

  constructor(
    private route: ActivatedRoute,
    private appInfoService: AppInfoService,
    private evelinService: EvelinService,
    private genericService: GenericService,
    private router: Router,
    private platformService: BasePlatformService,
    public dialog: MatDialog,
    private location: Location,
    public translate: TranslateService
  ) { }

  ngOnDestroy(): void {
    clearInterval(this.epgReloadInterval);
  }

  ngOnInit(): void {
    this.browserVersion = this.genericService.getBrowserVersion();
    let selectedTheme = this.appInfoService.getSelectedTheme();
    this.selectedTheme = ThemeHelper.transformThemeToString(
      selectedTheme as Theme
    );

    this.route.params.subscribe((params: Params) => {
      if (params['category'] && params['type']) {
        this.currentCategory = params['category'];
        this.currentType = parseInt(params['type']);
      }

      if (params['continue']) {
        this.continuePlaying = true;
        this.continueStreamId = params['streamId'];
      }
    });

    console.log('regen');
    this.regenerateComponent();

    if (this.currentType == StreamOptionType.TVChannels) {
      this.epgReloadInterval = setInterval(async () => {
        await this.evelinService.getShortEpgByCategoryId(this.currentCategory);
      }, 300000);
    }
  }

  ngAfterViewInit() {
    this.channelsListHeaderButtons = [
      this.channelsListButtonBack,
      this.channelsListButtonSearch,
      this.channelsListButtonSort,
    ];

    const temp = localStorage.getItem('temp_channel_id');
    let index = this.watchItems.findIndex(
      (item: any) => item.epg_channel_id === temp
    );
    if (index) {
      localStorage.removeItem('temp_channel_id');
      this.numberButtonsStep = index;
      this.changeFocusChannelsButtons(1);
    }
  }

  fillWatchItems() {
    this.watchItems = this.allItems
      ? this.allItems.slice(
        this.allItemsPointer * 30,
        this.allItemsPointer * 30 + 30
      )
      : this.allItems;

      if (this.currentType == StreamOptionType.Movies) {
        this.evelinService.getPaginatedMoviesFromCategory(this.currentCategory, this.pagingPointer, 30)
          .then(result => {
            this.allItems.concat(result.data);
            this.pagingPointer++;
          });
      } else if (this.currentType == StreamOptionType.TVShows) {
        this.evelinService.getPaginatedTvShowsFromCategory(this.currentCategory, this.pagingPointer, 30)
          .then(result => {
            this.allItems.concat(result.data);
            this.pagingPointer++;
          })
      }
  }

  private async prepareComponentForTvChannels() {
    this.loadingVisible = true;
    this.allItems = this.appInfoService.getCachedTvChannelsByCategory(this.currentCategory);
    this.allItems.push({
      id: -1,
      name: 'go-to-top',
      category_id: -1,
      icon: "",
      url: ""
    });
    this.fillWatchItems();

    this.currentTitle = this.appInfoService
      .getCategoriesFromCache(StreamOptionType.TVChannels)
      .find((x) => x.id == this.currentCategory)
      ?.category_name;
    this.showEpg = await this.evelinService.getShortEpgByCategoryId(this.currentCategory) ? true : false;
  }

  private async prepareComponentForMovies() {
    this.loadingVisible = true;
    this.allItems = (await this.evelinService.getAllMoviesFromCategory(this.currentCategory)).data as Stream[];
    this.fillWatchItems();

    this.currentTitle = this.appInfoService
      .getCategoriesFromCache(StreamOptionType.Movies)
      .find((x) => x.id == this.currentCategory)
      ?.category_name as string;
  }

  private async prepareComponentForTvShows() {
    this.loadingVisible = true;
    this.allItems = (await this.evelinService.getAllTvShowsFromCategory(this.currentCategory)).data as Stream[];
    this.fillWatchItems();
    
    this.currentTitle = this.appInfoService
      .getCategoriesFromCache(StreamOptionType.TVShows)
      .find((x) => x.id == this.currentCategory)
      ?.category_name;
  }

  async regenerateComponent() {
    if (this.currentType == StreamOptionType.TVChannels) {
      await this.prepareComponentForTvChannels();
    }

    if (this.currentType == StreamOptionType.TVShows) {
      await this.prepareComponentForTvShows();
    }

    if (this.currentType == StreamOptionType.Movies) {
      await this.prepareComponentForMovies();
    }

    this.watchItemsBackup = this.watchItems;

    if (this.continuePlaying) {
      let channel = this.watchItems.find((x) => x.id == this.continueStreamId);
      if (channel) 
        setTimeout(() => this.channelClicked(channel), 100);      
    } else {
      setTimeout(() => this.changeFocusChannelsButtons(0), 100);
    }

    this.loadingVisible = false;
  }

  goToTop() {
    this.allItemsPointer = 0;
    this.fillWatchItems();
    this.numberButtonsStep = 0;
    setTimeout(() => this.focusItem(this.numberButtonsStep), 20);
  }

  idForShortEpgInChannelList(id: any) {
    if (!id) return;
    return parseInt(id);
  }

  loadTVchannelInfo() { }

  setFullScreen() {
    if (
      this.currentType === StreamOptionType.TVChannels &&
      this.selectedStream.id
    ) {
      let stream = this.appInfoService.getCachedStreamByStreamId(
        this.selectedStream.id,
        StreamOptionType.TVChannels
      ) as LiveStream;
      let programmes = [];

      if (stream.epg_name != null) {
        programmes = this.appInfoService.getShortEpgByChannelName(
          stream.epg_name
        );
      }

      if (programmes.length)
        this.streamInfo = programmes
          .slice(0, 3)
          .map((program: CompleteEpgListing) => {
            return {
              time: program.start,
              title: program.title,
            };
          });
      else this.streamInfo = [];
    }
    this.isPlayerFullScreen = true;
    console.log(this.selectedStream);
    this.player.setFullScreen(this.streamInfo, this.selectedStream.icon);
  }

  closeFullSreen() {
    this.streamInfo = null;
    this.screenClass = 'min-screen';
    this.isPlayerFullScreen = false;

    setTimeout(() => {
      this.channelsListPlaying
        .toArray()[0]
        .nativeElement.children[this.numberButtonsStep].children[0].focus();
    }, 100);

    if (this.currentType !== StreamOptionType.TVChannels)
      this.isPlayerHidden = true;
  }

  async channelClicked(channel: Stream) {
    console.log('click', channel);
    if (
      this.currentType === StreamOptionType.TVChannels &&
      this.selectedStream &&
      this.selectedStream.name == channel.name
    ) {
      this.setFullScreen();
      return;
    }
    switch (this.currentType) {
      case StreamOptionType.TVChannels:
        this.selectedStream = channel;
        // this.selectedStreamIdEpg = (channel as StreamV2).id as number;
        break;
      case StreamOptionType.TVShows:
        this.router.navigate(['stb-extreme-tv-show-seasons-list', channel.id]);
        break;
      case StreamOptionType.Movies:
        this.router.navigate(['stb-extreme-movie-view', channel.id]);
        //this.selectedStream = channel;
        //this.isPlayerHidden = false;
        //this.player.setFullScreen(this.selectedMovieInfo);
        break;
    }
  }

  channelFocusTimeout: any;
  channelFocused(channel: Stream) {
    console.log(channel);
    if (this.channelFocusTimeout) clearTimeout(this.channelFocusTimeout);
    this.channelFocusTimeout = setTimeout(() => {
      if (this.currentType == StreamOptionType.TVChannels)
        this.selectedStreamIdEpg = channel.id as number;

      if (this.currentType == StreamOptionType.TVShows) this.loadShowData();

      if (this.currentType == StreamOptionType.Movies) this.loadMovieData();
    }, 1000);
  }

  showStreamInfo() {
    this.router.navigate([
      'stb-extreme-stream-info',
      this.currentType,
      this.selectedStream.id,
    ]);
  }

  sortItems(sort: string) {
    console.log(sort);
    console.log(this.allItems);
    if (sort == 'default') {
      this.regenerateComponent();
    } else if (sort == 'asc') {
      this.allItems = this.allItems.sort(SortingHelper.dynamicSort('name'));
    } else if (sort == 'desc') {
      this.allItems = this.allItems.sort(SortingHelper.dynamicSort('-name'));
    } else if (sort == 'date') {
      this.allItems = this.allItems.sort((a: any, b: any) =>
        parseInt(a.added) > parseInt(b.added) ? -1 : 1
      );
    }

    this.allItemsPointer = 0;
    this.numberButtonsStep = 0;
    this.fillWatchItems();
  }

  searchValueChanged(text: string) {
    this.watchItems = this.watchItemsBackup.filter((x) =>
      x.name.toLowerCase().includes(text)
    );
  }

  goBack() {
    this.location.back();
  }

  changeFocusChannelsButtons(step: number) {
    if (this.watchItems.length - 1 == this.numberButtonsStep && step > 0) {
      //set range for movement
      if (this.watchItems.length === 30) {
        this.allItemsPointer++;
        this.fillWatchItems();
        this.numberButtonsStep = 0;
        setTimeout(() => this.focusItem(this.numberButtonsStep), 20);
      }
      return;
    }

    if (this.numberButtonsStep == 0 && step < 0) {
      if (this.allItemsPointer > 0) {
        this.allItemsPointer--;
        this.fillWatchItems();
        this.numberButtonsStep = 29;
        setTimeout(() => this.focusItem(this.numberButtonsStep), 50);
      } else this.header.setFocus(0); // set focus on first button on header
      return;
    }

    if (step > 0) {
      this.numberButtonsStep++;
    } else if (step < 0) {
      this.numberButtonsStep--;
    } else if (step == 0) {
      this.numberButtonsStep = 0;
    }

    this.focusItem(this.numberButtonsStep);

    if (this.currentType == StreamOptionType.Movies) this.loadMovieData();

    if (this.currentType == StreamOptionType.TVShows) this.loadShowData();
  }

  focusItem = (index: number): void => {
    this.channelsListPlaying
      .toArray()[0]
      .nativeElement.children[index].children[0].focus();
  };

  onHeaderFocusLost() {
    if (this.watchItems.length) this.focusItem(0);
    else this.header.setFocus(1);
  }

  loadMovieData = async () => {
    let info = await this.evelinService.getMovieInfoById(
      (this.watchItems[this.numberButtonsStep] as any).id
    );
    this.selectedMovieInfo = info.info;
    console.log(this.selectedMovieInfo);
  };

  loadShowData = async () => {
    this.selectedMovieInfo = this.watchItems[this.numberButtonsStep];
    console.log(this.selectedMovieInfo);
  };

  changeChannel(step: number, isFullScreen: boolean = false) {
    this.pressedKeys += ' ' + step;

    if (step > 0) {
      this.numberButtonsStep++;
    } else if (step < 0) {
      this.numberButtonsStep--;
    } else if (step == 0) {
      this.numberButtonsStep = 0;
    }

    this.channelsListPlaying
      .toArray()[0]
      .nativeElement.children[this.numberButtonsStep].children[0].click();

    if (isFullScreen) this.setFullScreen();
  }

  onKeydown(e: any, channel: Stream) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Up':
        this.changeFocusChannelsButtons(-1);
        break;
      case 'Down':
        this.changeFocusChannelsButtons(1);
        break;
      case 'Left':
        this.goBack();
        break;
      // case 'Right':
      //   if (this.currentType == StreamOptionType.TVChannels) {
      //     localStorage.setItem('temp_channel_id', channel.id.toString() || '');
      //     this.router.navigate([
      //       '/multi-epg/' + channel.category_id + '/' + channel.id,
      //     ]);
      //   }
      //   break;
      case 'Enter':
        if (channel.id == -1)
          this.goToTop();
        else
          this.channelClicked(channel);
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      case 'Red':
      case 'ColorF0Red':
        if (this.currentType === StreamOptionType.TVChannels)
          this.setFavorite(channel);
        break;
      default:
        break;
    }
  }

  onFocus(channel: Stream) {
    this.channelFocused(channel);
  }

  isFavorite(item) {
    return item.favourite;
  }

  setFavorite(item) {
    item.favourite = !item.favourite;
    let favsCategory = this.evelinService.generateFavuoritesCategory();
    this.appInfoService.appendFavouritesCategory(favsCategory);
    this.evelinService.toggleFavourite(item.id);
  }

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    if (
      this.currentType == StreamOptionType.TVChannels &&
      this.screenClass == 'full-screen'
    ) {
      this.appInfoService.setPlayingComponentState(
        this.currentCategory.toString(),
        this.selectedStream.id
      );
      event.returnValue = false;
    }
  }
}
