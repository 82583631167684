import { Component, OnInit, ViewChild } from '@angular/core';
import { AppInfoService } from '../../../services/app-info.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryStream, StreamOptionType } from '../../../models/category.model';
import { OceanBlueListComponent } from '../ocean-blue-list/ocean-blue-list.component';
import { Location } from '@angular/common';
import { RadioStream } from '../../../models/stream.model';
import { PlayerComponent } from '../../player/player.component';

@Component({
  selector: 'app-radio-ocean-ocean-blue.component',
  templateUrl: './radio-ocean-blue.component.html',
  styleUrls: ['./radio-ocean-blue.component.css'],
})

export class RadioOceanBlueComponent implements OnInit {

  @ViewChild(OceanBlueListComponent) itemsList: OceanBlueListComponent;
  @ViewChild(PlayerComponent) player: PlayerComponent;

  allCategories: any[];
  items: any[];
  title: string = "Categories";
  categoriesView = true;
  currentCategory: CategoryStream;
  selectedStream: RadioStream;

  constructor(
    public router: Router,
    public appInfoService: AppInfoService,
    public translate: TranslateService,
    public matDialog: MatDialog,
    private location: Location
  ) { }

  async ngOnInit(): Promise<void> {
    this.allCategories = this.appInfoService.getCategoriesFromCache(StreamOptionType.Radio);
    this.loadCategories();
  }

  loadCategories() {
    this.items = this.allCategories;
    this.title = "Categories";
    this.categoriesView = true;
    setTimeout(() => this.itemsList.setFocus(), 100);
  }

  onCardClick(card) {
    console.log(card);

    if (card === -1) {
      this.goBack();
    } else if (this.categoriesView) {
      this.currentCategory = this.items[card];
      this.items = this.appInfoService.getCachedTvChannelsByCategory(this.currentCategory.id);
      console.log(this.items);
      this.title = this.appInfoService
        .getCategoriesFromCache(StreamOptionType.TVChannels)
        .find((x) => x.id == this.currentCategory.id)
        ?.category_name;
      this.categoriesView = false;
      setTimeout(() => this.itemsList.setFocus(), 100);
    } else {
      this.selectedStream = this.items[card];
    }
  }

  closeFullSreen() {
    setTimeout(() => this.itemsList.setFocus(), 100);
  }

  goBack() {
    if (this.categoriesView)
      this.location.back();
    else
      this.loadCategories();
  }

}