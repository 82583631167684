<div [class]="selectedTheme">
    <div class="background">
        <div #options class="content">
            <div class="row" [tabIndex]="1" (keydown)="onKeydown($event)">
                <div class="col-md-10">
                    {{ "settings.buffering-time" | translate }}
                </div>
                <div class="col-md-2">
                    {{settings.bufferingTime}}s
                </div>
            </div>
            <div class="row" [tabIndex]="1" (keydown)="onKeydown($event)">
                <div class="col-md-10">
                    {{ "settings.autoplay" | translate }}
                </div>
                <div class="col-md-2">
                    {{settings.episodeAutoplay | boolstring }}
                </div>
            </div>
            <div class="row" [tabIndex]="1" (keydown)="onKeydown($event)">
                <div class="col-md-10">
                    {{ "settings.autoplay-delay" | translate }}
                </div>
                <div class="col-md-2">
                    {{settings.episodeAutoplayDelay}}s
                </div>
            </div>
            <button #buttonSave mat-button class="button picker" (click)="confirm()" (keydown)="onKeydown($event)"
                class="button mat-button mat-dialog-close cdkFocusInitial">
                {{ "settings.save" | translate }}
            </button>
        </div>

    </div>
</div>