import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CategoryStream, StreamOptionType } from '../../models/category.model';
import { AppInfoService } from '../../services/app-info.service';
import { CompleteEpgListing } from '../../models/epg.model';
import { of } from 'rxjs';
import { groupBy, mergeMap, reduce, map, toArray } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { BasePlatformService } from '../../platforms/iplatform.interface';
import { Stream } from '../../models/stream.model';
import { ThemeHelper } from '../../helpers/theme.helper';
import { EvelinService } from 'src/app/services/evelin.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multi-epg',
  templateUrl: './multi-epg.component.html',
  styleUrls: ['./multi-epg.component.css'],
})
export class MultiEpgComponent implements OnInit {
  @ViewChild('epgButtonBack') epgButtonBack: ElementRef;
  @ViewChildren('categories') categoryElements: QueryList<any>;
  @ViewChildren('channels') channelElements: QueryList<any>;
  @ViewChildren('dates') dateElements: QueryList<any>;
  @ViewChildren('epgItems') epgItemsElements: QueryList<any>;

  public selectedCategory = 1;
  public selectedChannel = 1;
  public selectedChannelId = "";
  public currentTitle = '';
  public categories = new Array<CategoryStream>();
  public channels = new Array<Stream>();
  public epg = new Array<CompleteEpgListing>();
  dates: any = [];
  selectedEpgItems = new Array<CompleteEpgListing>();
  selectedDate: any = {};
  selectedEpgInfo = new CompleteEpgListing();
  theme: string;
  noDatesMessage = false;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private appInfoService: AppInfoService,
    private evelinService: EvelinService,
    private location: Location,
    private platformService: BasePlatformService
  ) {
    this.theme = ThemeHelper.transformThemeToString(this.appInfoService.getSelectedTheme());
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.selectedCategory = params['category'];
      this.selectedChannelId = params['channelId'];
    });

    this.categories = this.appInfoService.getCategoriesFromCache(
      StreamOptionType.TVChannels
    ).filter(x => x.id != 0);

    this.generateCategoriesData();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.selectedCategory && this.selectedChannelId) {
        this.selectedCategoryChanged(this.categories.find(c => c.id == this.selectedCategory));

        let index = this.channels.findIndex(c => c.id === parseInt(this.selectedChannelId));
        this.selectedChannelChanged(this.channels[index]);

        setTimeout(() => {
          const today = new Date().toISOString().split('T')[0];
          const dateToSet = this.dates.find((d: any) => d.date === today);

          if (dateToSet) {
            this.selectedDateChanged(dateToSet);
            index = this.dates.findIndex((d: any) => d.date === today);

            setTimeout(() => {
              this.dateElements.toArray()[index].nativeElement.children[0].focus();
            }, 200);
          } else {
            this.channelElements.toArray()[index].nativeElement.children[0].focus();
          }
        }, 1000);
      } else {
        this.epgButtonBack.nativeElement.focus();
      }
    }, 1000);
  }

  private generateCategoriesData() {
    this.channels = this.appInfoService.getCachedTvChannelsByCategory(
      this.selectedCategory
    ).filter(x => x.name.indexOf("▲▼") == -1);

    this.currentTitle = this.appInfoService
      .getCategoriesFromCache(StreamOptionType.TVChannels)
      .find((x) => x.id == this.selectedCategory)
      ?.category_name;
  }

  private async generateCompleteEpg() {
    this.epg = await this.evelinService.getEpgByStreamId(
      this.selectedChannel
    );

    let nDaysAgo = 3;
    let nDaysAgoDate = new Date();
    //nDaysAgoDate.setHours(0, 0, 0, 0);
    nDaysAgoDate.setDate(nDaysAgoDate.getDate() - nDaysAgo);
    this.epg = this.epg.filter(
      (x) => new Date(x.start as string) > nDaysAgoDate
    );

    of(...(this.epg as CompleteEpgListing[]))
      .pipe(
        groupBy((p: any) => p.start.split(' ')[0]),
        mergeMap((group$) =>
          group$.pipe(reduce((acc, cur) => [...acc, cur], [`${group$.key}`]))
        ),
        map((arr) => ({ date: arr[0], streams: arr.slice(1) })),
        toArray()
      )
      .subscribe((p) => {
        this.dates = p;
        this.selectedDate = this.dates.filter(
          (x: any) => x.date == new Date().toISOString().split('T')[0]
        )[0];
      });

      this.noDatesMessage = this.dates.length < 1;
  }

  selectedCategoryChanged(e: CategoryStream) {
    this.selectedCategory = e.id;
    this.selectedChannel = 1;
    this.dates = [];
    this.selectedEpgItems = new Array<CompleteEpgListing>();
    this.generateCategoriesData();
  }

  async selectedChannelChanged(e: Stream) {
    this.selectedChannel = e.id as number;
    this.dates = [];
    this.selectedEpgItems = new Array<CompleteEpgListing>();
    await this.generateCompleteEpg();
  }

  selectedDateChanged(e: any) {
    this.selectedDate = e.date;
    this.selectedEpgItems = e.streams;
  }

  selectedEpgItemClicked(e: any) {
    // if we ever need a full epg info component... here it is
    // this.appInfoService.removeCachedEpgInfo();
    // this.appInfoService.setCachedEpgInfo(e);
    // this.router.navigate(['/epg-info', e.id]);
    this.selectedEpgInfo = e;
  }

  generateEpgInfo(epgItem: CompleteEpgListing) {
    let date = new Date(epgItem.start_timestamp * 1000);
    let formatedTime = String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0');
    let title = epgItem.title;

    return formatedTime + ' ' + title;
  }

  calculateDuration() {
    return (this.selectedEpgInfo.end_timestamp - this.selectedEpgInfo.start_timestamp) / 60;
  }

  channelClicked(e: any) { }

  onCategoryKeydown(e: any, index: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        index++;
        this.categoryElements.toArray()[index].nativeElement.children[0].focus();
        this.selectedCategoryChanged(this.categories[index]);
        break;
      case 'Up':
        if (index > 0) {
          index--;
          this.categoryElements.toArray()[index].nativeElement.children[0].focus();
          this.selectedCategoryChanged(this.categories[index]);
        } else {
          this.epgButtonBack.nativeElement.focus();
        }
        break;
      case 'Enter':
        this.channelElements.toArray()[0].nativeElement.children[0].focus();
        this.selectedChannelChanged(this.channels[0]);
        break;
      case 'Left':
        this.epgButtonBack.nativeElement.focus();
        break;
      case 'Right':
        this.channelElements.toArray()[0].nativeElement.children[0].focus();
        this.selectedChannelChanged(this.channels[0]);
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  onChannelKeydown(e: any, index: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        index++;
        this.channelElements.toArray()[index].nativeElement.children[0].focus();
        this.selectedChannelChanged(this.channels[index]);
        break;
      case 'Up':
        index--;
        this.channelElements.toArray()[index].nativeElement.children[0].focus();
        this.selectedChannelChanged(this.channels[index]);
        break;
      case 'Enter':
        this.dateElements.toArray()[0].nativeElement.children[0].focus();
        this.selectedDateChanged(this.dates[0]);
        break;
      case 'Left':
        let categoryIndex = this.categories.findIndex(category => category.id == this.selectedCategory);
        this.categoryElements.toArray()[categoryIndex].nativeElement.children[0].focus();
        this.selectedCategoryChanged(this.categories[categoryIndex]);
        this.noDatesMessage = false;
        break;
      case 'Right':
        this.dateElements.toArray()[0].nativeElement.children[0].focus();
        this.selectedDateChanged(this.dates[0]);
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  onDateKeydown(e: any, index: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        index++;
        this.dateElements.toArray()[index].nativeElement.children[0].focus();
        this.selectedDateChanged(this.dates[index]);
        break;
      case 'Up':
        index--;
        this.dateElements.toArray()[index].nativeElement.children[0].focus();
        this.selectedDateChanged(this.dates[index]);
        break;
      case 'Enter':
        this.epgItemsElements.toArray()[0].nativeElement.children[0].focus();
        this.selectedEpgItemClicked(this.selectedEpgItems[0]);
        break;
      case 'Left':
        let chanellIndex = this.channels.findIndex(channel => channel.id == this.selectedChannel);
        this.channelElements.toArray()[chanellIndex].nativeElement.children[0].focus();
        this.selectedChannelChanged(this.channels[chanellIndex]);
        break;
      case 'Right':
        this.epgItemsElements.toArray()[0].nativeElement.children[0].focus();
        this.selectedEpgItemClicked(this.selectedEpgItems[0]);
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  onItemKeydown(e: any, index: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        index++;
        this.epgItemsElements.toArray()[index].nativeElement.children[0].focus();
        this.selectedEpgItemClicked(this.selectedEpgItems[index]);
        break;
      case 'Up':
        index--;
        this.epgItemsElements.toArray()[index].nativeElement.children[0].focus();
        this.selectedEpgItemClicked(this.selectedEpgItems[index]);
        break;
      case 'Enter':
        break;
      case 'Left':
        let dateIndex = this.dates.findIndex(date => date.date == this.selectedDate);
        this.dateElements.toArray()[dateIndex].nativeElement.children[0].focus();
        this.selectedDateChanged(this.dates[dateIndex]);
        break;
      case 'Right':
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  onKeydown(e: any, index: any, type: number) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        break;
      case 'Enter':
        this.goBack();
        break;
      case 'Left':
        break;
      case 'Right':
        break;
      case 'BrowserBack':
        this.goBack();
        break;

      default:
        break;
    }
  }

  onKeydownHeaderButtons(e: any) {
    e.preventDefault();
    let key = this.platformService.keymapper(e);
    switch (key) {
      case 'Down':
        this.categoryElements.toArray()[0].nativeElement.children[0].focus();
        break;
      case 'Enter':
        this.goBack();
        break;
      case 'BrowserBack':
        this.goBack();
        break;
      default:
        break;
    }
  }

  goBack() {
    this.location.back();
  }
  click() {
    this.location.back();
  }
}
